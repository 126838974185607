import { gql } from '@apollo/client';

export default gql`
  query membershipOptions {
    membershipOptions {
      id
      billingFrequency
      pricePerMonth
      nameLong
      nameShort
      fullPrice
      tagline
      featured
      callout
      secondaryCallout
    }
  }
`;
