import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers/rootReducer';
import rootSaga from './sagas/rootSaga';
import Routes from './Routes';
import history from './history';
import ScrollToTop from './components/Common/ScrollToTop';
import SpinnerModal from './modals/SpinnerModal';
import trackers from './trackers';

import './Vendor';
import './styles/bootstrap.scss';
import './styles/app.scss';

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(rootSaga);

class App extends Component {
  constructor(props) {
    super(props);
    let computedStates;
    let signup;
    if (store && store.liftedStore) {
      ({ computedStates } = store.liftedStore.getState());
    }
    if (computedStates && computedStates.length) {
      const lastState = computedStates[computedStates.length - 1];
      if (lastState && lastState.state && lastState.state.signup) {
        ({ signup } = lastState.state);
      }
    }
    trackers.loadTrackingScripts(signup);
  }

  render() {
    return (
      <Provider store={store}>
        <Router history={history} basename="/">
          <ScrollToTop>
            <Routes />
            <SpinnerModal />
          </ScrollToTop>
        </Router>
      </Provider>
    );
  }
}

export default App;
