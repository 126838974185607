import { Roles } from './enums/roles';

// defining the different routes here so that the auth utils can also reference them more easily
export const AuthMenu = {
  dashboard: {
    name: 'Dashboard',
    path: '/dashboard',
    icon: 'icon-grid',
  },
  clients: {
    name: 'Clients',
    icon: 'icon-user',
    // label: { value: 1, color: 'info' },
    roles: [Roles.TEAM_OWNER, Roles.TEAM_ADMIN],
  },
  clientList: {
    name: 'Client Search',
    path: '/clients',
    roles: [Roles.TEAM_OWNER, Roles.TEAM_ADMIN],
  },
  addClients: {
    name: 'Add Clients',
    path: '/addclients',
    roles: [Roles.TEAM_OWNER, Roles.TEAM_ADMIN],
  },
  userDetail: {
    roles: [Roles.TEAM_OWNER, Roles.TEAM_ADMIN, Roles.INTERNAL_ADMIN],
  },
  groups: {
    name: 'Groups',
    path: '/groups',
    roles: [Roles.TEAM_OWNER, Roles.TEAM_ADMIN],
  },
  settings: {
    name: 'Settings',
    icon: 'icon-settings',
  },
  accountSettings: {
    name: 'Account Settings',
    path: '/settings',
  },
  businessSettings: {
    name: 'Business Settings',
    path: '/businesssettings',
    roles: [Roles.TEAM_OWNER, Roles.INTERNAL_ADMIN], // TODO: Remove INTERNAL_ADMIN
  },
  inbox: {
    path: '/inbox',
    roles: [Roles.TEAM_OWNER, Roles.TEAM_ADMIN],
  },
  adminUsers: {
    name: 'Admins',
    path: '/admins',
    roles: [Roles.TEAM_OWNER],
  },
  billing: {
    name: 'Billing',
    path: '/billing',
    roles: [Roles.TEAM_OWNER],
  },
  utilities: {
    name: 'Utilities',
    icon: 'icon-wrench',
    roles: [Roles.INTERNAL_ADMIN, Roles.TEAM_OWNER, Roles.TEAM_ADMIN],
  },
  alcoholCalculator: {
    name: 'Alcohol Calculator',
    path: '/alcohol',
    roles: [Roles.INTERNAL_ADMIN, Roles.TEAM_OWNER, Roles.TEAM_ADMIN],
  },
  colors: {
    name: 'Design System',
    path: '/colors',
    roles: [Roles.INTERNAL_ADMIN],
  },
  csvUpload: {
    name: 'CSV Upload Test',
    path: '/csv',
    roles: [Roles.INTERNAL_ADMIN],
  },
  food: {
    name: 'Food',
    icon: 'ms-icon- icon-Knife_-Fork_-Restaurant_-Food',
    roles: [Roles.INTERNAL_ADMIN, Roles.INTERNAL_RECIPE_ADMIN],
  },
  recipes: {
    name: 'Recipes',
    path: '/recipes',
    roles: [Roles.INTERNAL_ADMIN, Roles.INTERNAL_RECIPE_ADMIN],
  },
  foods: {
    name: 'Foods',
    path: '/foods',
    roles: [Roles.INTERNAL_ADMIN, Roles.INTERNAL_RECIPE_ADMIN],
  },
  team: {
    name: 'Macrostax Team',
    icon: 'ms-icon- icon-dumbbell',
    roles: [Roles.INTERNAL_ADMIN],
  },
  teamSearch: {
    name: 'Team Search',
    path: '/teams',
    roles: [Roles.INTERNAL_ADMIN],
  },
  users: {
    name: 'Users',
    icon: 'icon-user',
    roles: [Roles.INTERNAL_ADMIN],
  },
  userSearch: {
    name: 'User Search',
    path: '/users',
    roles: [Roles.INTERNAL_ADMIN],
  },
};

// this is what populates the sidebar menu
const Menu = [
  /*
  {
    ...AuthMenu.dashboard,
  },  */
  {
    ...AuthMenu.clients,
    submenu: [AuthMenu.clientList, AuthMenu.addClients],
    // submenu: [AuthMenu.clientList, AuthMenu.groups],
  },

  {
    ...AuthMenu.users,
    submenu: [AuthMenu.userSearch],
  },
  /*
  {
    ...AuthMenu.food,
    submenu: [AuthMenu.recipes, AuthMenu.foods],
  },
  */
  {
    ...AuthMenu.team,
    submenu: [AuthMenu.teamSearch],
  },
  {
    ...AuthMenu.settings,
    submenu: [
      AuthMenu.accountSettings,
      AuthMenu.businessSettings,
      AuthMenu.adminUsers,
      AuthMenu.billing,
    ],
  },
  {
    ...AuthMenu.utilities,
    submenu: [AuthMenu.alcoholCalculator, AuthMenu.colors, AuthMenu.csvUpload],
  },
];

export default Menu;
