import ENV_VARS from '../utils/envVars';
import createUserSettingsDocument from '../graphql/mutations/createUserSettings';
import setInitialPhaseForUserDocument from '../graphql/mutations/setInitialPhaseForUser';
import activityLevelSuggestionDocument from '../graphql/queries/activityLevelSuggestion';
import setInitialActivityLevelForUserDocument from '../graphql/mutations/setInitialActivityLevelForUser';
import userProfileStateDocument from '../graphql/queries/userProfileState';
import testimonialDocument from '../graphql/queries/testimonial';
import membershipOptionsDocument from '../graphql/queries/membershipOptions';
import validateCouponDocument from '../graphql/queries/validateCoupon';
import submitNewMembershipPaymentDocument from '../graphql/mutations/submitNewMembershipPayment';
import subscriptionPurchasedDocument from '../graphql/mutations/subscriptionPurchased';
import trackPageViewDocument from '../graphql/mutations/trackFacebookEvent';

export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';
export const CREATE_ACCOUNT_FAILURE = 'CREATE_ACCOUNT_FAILURE';
export const CREATE_USER_SETTINGS_SUCCESS = 'CREATE_USER_SETTINGS_SUCCESS';
export const CREATE_USER_SETTINGS_FAILURE = 'CREATE_USER_SETTINGS_FAILURE';
export const SET_INITIAL_PHASE_FOR_USER_SUCCESS = 'SET_INITIAL_PHASE_FOR_USER_SUCCESS';
export const SET_INITIAL_PHASE_FOR_USER_FAILURE = 'SET_INITIAL_PHASE_FOR_USER_FAILURE';
export const ACTIVITY_LEVEL_SUGGESTION_SUCCESS = 'ACTIVITY_LEVEL_SUGGESTION_SUCCESS';
export const ACTIVITY_LEVEL_SUGGESTION_FAILURE = 'ACTIVITY_LEVEL_SUGGESTION_FAILURE';
export const SET_INITIAL_ACTIVITY_LEVEL_FOR_USER_SUCCESS =
  'SET_INITIAL_ACTIVITY_LEVEL_FOR_USER_SUCCESS';
export const SET_INITIAL_ACTIVITY_LEVEL_FOR_USER_FAILURE =
  'SET_INITIAL_ACTIVITY_LEVEL_FOR_USER_FAILURE';
export const USER_PROFILE_STATE_SUCCESS = 'USER_PROFILE_STATE_SUCCESS';
export const USER_PROFILE_STATE_FAILURE = 'USER_PROFILE_STATE_FAILURE';
export const TESTIMONIAL_SUCCESS = 'TESTIMONIAL_SUCCESS';
export const TESTIMONIAL_FAILURE = 'TESTIMONIAL_FAILURE';
export const MEMBERSHIP_OPTIONS_SUCCESS = 'MEMBERSHIP_OPTIONS_SUCCESS';
export const MEMBERSHIP_OPTIONS_FAILURE = 'MEMBERSHIP_OPTIONS_FAILURE';
export const VALIDATE_COUPON_SUCCESS = 'VALIDATE_COUPON_SUCCESS';
export const VALIDATE_COUPON_FAILURE = 'VALIDATE_COUPON_FAILURE';
export const SUBMIT_NEW_MEMBERSHIP_PAYMENT_SUCCESS = 'SUBMIT_NEW_MEMBERSHIP_PAYMENT_SUCCESS';
export const SUBMIT_NEW_MEMBERSHIP_PAYMENT_FAILURE = 'SUBMIT_NEW_MEMBERSHIP_PAYMENT_FAILURE';
export const SEND_SMS_SUCCESS = 'SEND_SMS_SUCCESS';
export const SEND_SMS_FAILURE = 'SEND_SMS_FAILURE';
export const SUBSCRIPTION_PURCHASED_SUCCESS = 'SUBSCRIPTION_PURCHASED_SUCCESS';
export const SUBSCRIPTION_PURCHASED_FAILURE = 'SUBSCRIPTION_PURCHASED_FAILURE';
export const TRACK_FACEBOOK_EVENT_RPC_SUCCESS = 'TRACK_FACEBOOK_EVENT_RPC_SUCCESS';
export const TRACK_FACEBOOK_EVENT_RPC_FAILURE = 'TRACK_FACEBOOK_EVENT_RPC_FAILURE';
export const TRACK_FACEBOOK_EVENT_GQL_SUCCESS = 'TRACK_FACEBOOK_EVENT_GQL_SUCCESS';
export const TRACK_FACEBOOK_EVENT_GQL_FAILURE = 'TRACK_FACEBOOK_EVENT_GQL_FAILURE';

export function trackFacebookEventRPC(eventName, path) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/track-facebook-event',
    method: 'POST',
    data: {
      event_name: eventName,
      path,
    },
  };
  return {
    type: 'API_CALL',
    config,
    auth: false,
    spinner: false,
    successAction: TRACK_FACEBOOK_EVENT_RPC_SUCCESS,
    failureAction: TRACK_FACEBOOK_EVENT_RPC_FAILURE,
  };
}

export function trackFacebookEventGQL(variables) {
  const config = {
    type: 'mutation',
    document: trackPageViewDocument,
    variables,
  };
  return {
    type: 'GQL_CALL',
    config,
    auth: true,
    spinner: false,
    successAction: TRACK_FACEBOOK_EVENT_GQL_SUCCESS,
    failureAction: TRACK_FACEBOOK_EVENT_GQL_FAILURE,
  };
}

export function createAccount(values) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/create-account',
    method: 'POST',
    data: {
      ...values,
    },
  };
  return {
    type: 'API_CALL',
    config,
    auth: false,
    spinner: true,
    successAction: CREATE_ACCOUNT_SUCCESS,
    failureAction: CREATE_ACCOUNT_FAILURE,
  };
}

export function createUserSettings(variables) {
  const config = {
    type: 'mutation',
    document: createUserSettingsDocument,
    variables,
  };
  return {
    type: 'GQL_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: CREATE_USER_SETTINGS_SUCCESS,
    failureAction: CREATE_USER_SETTINGS_FAILURE,
  };
}

export function setInitialPhaseForUser(variables) {
  const config = {
    type: 'mutation',
    document: setInitialPhaseForUserDocument,
    variables,
  };
  return {
    type: 'GQL_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: SET_INITIAL_PHASE_FOR_USER_SUCCESS,
    failureAction: SET_INITIAL_PHASE_FOR_USER_FAILURE,
  };
}

export function activityLevelSuggestion(variables) {
  const config = {
    type: 'query',
    document: activityLevelSuggestionDocument,
    variables,
  };
  return {
    type: 'GQL_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: ACTIVITY_LEVEL_SUGGESTION_SUCCESS,
    failureAction: ACTIVITY_LEVEL_SUGGESTION_FAILURE,
  };
}

export function setInitialActivityLevelForUser(variables) {
  const config = {
    type: 'mutation',
    document: setInitialActivityLevelForUserDocument,
    variables,
  };
  return {
    type: 'GQL_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: SET_INITIAL_ACTIVITY_LEVEL_FOR_USER_SUCCESS,
    failureAction: SET_INITIAL_ACTIVITY_LEVEL_FOR_USER_FAILURE,
  };
}

export function userProfileState() {
  const config = {
    type: 'query',
    document: userProfileStateDocument,
  };
  return {
    type: 'GQL_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: USER_PROFILE_STATE_SUCCESS,
    failureAction: USER_PROFILE_STATE_FAILURE,
  };
}

export function testimonial(variables) {
  const config = {
    type: 'query',
    document: testimonialDocument,
    variables,
  };
  return {
    type: 'GQL_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: TESTIMONIAL_SUCCESS,
    failureAction: TESTIMONIAL_FAILURE,
  };
}

export function membershipOptions() {
  const config = {
    type: 'query',
    document: membershipOptionsDocument,
  };
  return {
    type: 'GQL_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: MEMBERSHIP_OPTIONS_SUCCESS,
    failureAction: MEMBERSHIP_OPTIONS_FAILURE,
  };
}

export function validateCoupon(variables) {
  const config = {
    type: 'query',
    document: validateCouponDocument,
    variables,
  };
  return {
    type: 'GQL_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: VALIDATE_COUPON_SUCCESS,
    failureAction: VALIDATE_COUPON_FAILURE,
  };
}

export function submitNewMembershipPayment(variables) {
  const config = {
    type: 'mutation',
    document: submitNewMembershipPaymentDocument,
    variables,
  };
  return {
    type: 'GQL_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: SUBMIT_NEW_MEMBERSHIP_PAYMENT_SUCCESS,
    failureAction: SUBMIT_NEW_MEMBERSHIP_PAYMENT_FAILURE,
  };
}

export function sendSms(phone) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/send-download-link',
    method: 'POST',
    data: {
      phone,
    },
  };
  return {
    type: 'API_CALL',
    config,
    auth: false,
    spinner: true,
    successAction: SEND_SMS_SUCCESS,
    failureAction: SEND_SMS_FAILURE,
  };
}

export function subscriptionPurchased(variables) {
  const config = {
    type: 'mutation',
    document: subscriptionPurchasedDocument,
    variables,
  };
  return {
    type: 'GQL_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: SUBSCRIPTION_PURCHASED_SUCCESS,
    failureAction: SUBSCRIPTION_PURCHASED_FAILURE,
  };
}
