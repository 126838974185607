import { gql } from '@apollo/client';

export default gql`
  query activityLevelSuggestion($quizAnswer: String!, $workoutHours: String!) {
    activityLevelSuggestion(quizAnswer: $quizAnswer, workoutHours: $workoutHours) {
      uuid
      label
      description
      order
    }
  }
`;
