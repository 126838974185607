import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Slider from 'rc-slider';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import HelperTextIcon from '../../components/Common/HelperTextIcon';
import { submissionError } from '../../actions/formActions';
import { signUpWorkouts, setSignUpStep } from '../../actions/signupActions';
import browserHistory from '../../history';

import 'rc-slider/assets/index.css';

class SignUpWorkouts extends React.Component {
  constructor(props) {
    super(props);
    const { signup } = props;
    this.state = {
      hours: signup.hours || 4,
    };
  }

  componentDidMount() {
    const { signup, doSetSignupStep } = this.props;
    if (!signup || !signup.first_name) {
      browserHistory.push('/signup');
    } else {
      doSetSignupStep(5);
    }
  }

  changeSlider = (v) => {
    this.setState({
      hours: v,
    });
  };

  render() {
    const { doSignUpWorkouts, signup } = this.props;
    let email;
    if (signup) {
      ({ email } = signup);
    }
    const { hours } = this.state;
    const marks = { 12: '12+' };
    for (let i = 0; i < 12; i += 1) {
      marks[i] = i;
    }

    return (
      <ContentWrapper>
        <Row>
          <Col
            md={12}
            className="d-flex flex-column justify-content-center align-items-center mb-5-md text-center"
          >
            <div className="d-flex">
              <h1>Your Workouts</h1>
              <HelperTextIcon helperText="WORKOUTS" />
            </div>
            <h3>
              On average, how many <strong>total hours per week</strong> do you work out?
            </h3>
          </Col>
        </Row>
        <Row>
          <Col md={3} />
          <Col md={6} className="d-flex flex-column align-items-center mb-3">
            <div className="signup-form-wrapper">
              <Row>
                <Col>
                  <label>Drag the slider below:</label>
                  <Slider
                    onChange={this.changeSlider}
                    onAfterChange={this.onAfterChange}
                    defaultValue={hours}
                    min={0}
                    max={12}
                    dots={true}
                    marks={marks}
                    className="slider"
                  />
                </Col>
              </Row>
              <Row className="margin-top-80">
                <Col className="d-flex">
                  <Button
                    color="primary"
                    size="lg"
                    type="submit"
                    onClick={() => doSignUpWorkouts(hours, email)}
                    block
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </ContentWrapper>
    );
  }
}

SignUpWorkouts.propTypes = {
  showSubmissionError: PropTypes.func,
  doSignUpWorkouts: PropTypes.func,
  doLogout: PropTypes.func,
  signup: PropTypes.instanceOf(Object),
  doSetSignupStep: PropTypes.func,
};
const mapStateToProps = (state) => ({
  signup: state.signup,
});
const mapDispatchToProps = (dispatch) => ({
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
  doSignUpWorkouts: (hours, email) => dispatch(signUpWorkouts(hours, email)),
  doSetSignupStep: (step) => dispatch(setSignUpStep(step)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpWorkouts);
