import React from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import SignUpInputsForm from '../../forms/SignUpInputsForm';
import { submissionError } from '../../actions/formActions';
import { signUpInputs, setSignUpStep } from '../../actions/signupActions';
import browserHistory from '../../history';

class SignUpInputs extends React.Component {
  componentDidMount() {
    const { signup, doSetSignupStep } = this.props;
    if (!signup || !signup.first_name) {
      browserHistory.push('/signup');
    } else {
      doSetSignupStep(2);
    }
  }

  render() {
    const { showSubmissionError, doSignUpInputs, signup, device } = this.props;
    const { first_name } = signup;
    const { systemName } = device;
    return (
      <ContentWrapper>
        <Row>
          <Col
            md={12}
            className="d-flex flex-column justify-content-center align-items-center mb-5-md text-center"
          >
            <h1>Welcome, {first_name}!</h1>
            <h3>
            We’re so excited to have you here! We’d love to learn more about you so we can create your perfect plan!
            </h3>
          </Col>
        </Row>
        <Row>
          <Col md={3} />
          <Col md={6} className="d-flex flex-column align-items-center margin-bottom-80">
            <div className="signup-form-wrapper">
              <SignUpInputsForm
                showSubmissionError={showSubmissionError}
                onSubmit={(values) => doSignUpInputs(values)}
                systemName={systemName}
                initialValues={{
                  birthdate: '',
                  gender: 'female',
                  unitsOfMeasure: 'imperial',
                  heightFt: '',
                  heightIn: '',
                  heightCm: '',
                  weight: '',
                  goal_weight: '',
                  ...signup,
                }}
              />
            </div>
          </Col>
        </Row>
      </ContentWrapper>
    );
  }
}

SignUpInputs.propTypes = {
  showSubmissionError: PropTypes.func,
  doSignUpInputs: PropTypes.func,
  signup: PropTypes.instanceOf(Object),
  doSetSignupStep: PropTypes.func,
  device: PropTypes.instanceOf(Object),
};
const mapStateToProps = (state) => ({
  signup: state.signup,
  device: state.device,
});
const mapDispatchToProps = (dispatch) => ({
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
  doSignUpInputs: (values) => dispatch(signUpInputs(values)),
  doSetSignupStep: (step) => dispatch(setSignUpStep(step)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpInputs);
