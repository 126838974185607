import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import TeamLearnMoreModal from '../../modals/TeamLearnMoreModal';
import { submissionError } from '../../actions/formActions';
import { signUpValue, setSignUpStep, signUpTeam } from '../../actions/signupActions';
import browserHistory from '../../history';

class SignUpValue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      learnMoreModalOpen: false,
    };
  }

  componentDidMount() {
    const { signup, doSetSignupStep } = this.props;
    if (!signup || !signup.first_name) {
      browserHistory.push('/signup');
    } else {
      doSetSignupStep(9);
    }
  }

  toggleLearnMoreModal = () => {
    const { learnMoreModalOpen } = this.state;
    this.setState({
      learnMoreModalOpen: !learnMoreModalOpen,
    });
  };

  render() {
    const { doSignUpValue, signup, doSignUpTeam } = this.props;
    const {
      is_team_user = false,
      team_name = '',
      first_name,
      userProfileState,
    } = signup;
    const { learnMoreModalOpen } = this.state;

    let label;
    if (userProfileState) {
      label = userProfileState.goal.label;
    }
    const planLabel = label === 'muscle gain' ? 'Gain muscle' : 'Get lean';
    const steps = [
      { number: 1, label: is_team_user ? 'Fuel your workouts' : 'Eat what you love' },
      { number: 2, label: planLabel },
      { number: 3, label: is_team_user ? 'Eat what you love' : 'Feel energized' },
    ];

    return (
      <ContentWrapper>
        <Row>
          <Col md={3} />
          <Col md={6} className="d-flex flex-column align-items-center mb-3">
            <div className="signup-form-wrapper">
              <Row className="border-bottom">
                <Col md={8} className="d-flex flex-column mb-3">
                  <h1>
                    {is_team_user ? (
                      <>Join {team_name} on Macrostax</>
                    ) : (
                      <>150k+ people trust Macrostax</>
                    )}
                  </h1>
                  <div className="signup-steps benefits mt-2 pl-3">
                    {steps.map((s) => (
                      <div className="signup-step benefit d-flex align-items-center" key={s.number}>
                        <div className="signup-step-thumb mr-3">
                          <div className="inner">
                            <img src="/img/signup/green-thumb.svg" className="green-thumb" />
                          </div>
                        </div>
                        <div className="signup-step-label">{s.label}</div>
                      </div>
                    ))}
                  </div>
                </Col>
                <Col className="align-items-center flex-column d-none d-md-flex mb-5">
                  <img src="/img/signup/max.svg" className="max" />
                </Col>
              </Row>
              <Row className="mt-5">
                <Col className="fake-h3">
                  {is_team_user ? (
                    <>
                      You're almost there, {first_name}! Join your {team_name} community on Macrostax to crush your goals in and out of the gym.
                      <div className="legal mt-4">
                        *By continuing, you agree to the <a href="https://www.macrostax.com/terms-conditions/" target="_blank">Terms &amp; Conditions</a> and acknowledge that your coaches will be able to see some of your data.
                        {' '}
                        <span className="span-link" onClick={this.toggleLearnMoreModal}>Learn more</span>.
                      </div>
                    </>
                  ) : (
                    <>
                      <strong>90% of Macrostaxers</strong> have a <strong>yearly membership</strong>
                      , set yourself up for long-term success. Plus, you'll{' '}
                      <strong>save 50%!</strong>
                    </>
                  )}
                </Col>
              </Row>
              <Row className="margin-top-40">
                <Col className="d-flex">
                  <Button color="primary" size="lg" onClick={() => is_team_user ? doSignUpTeam() : doSignUpValue(true)} block>
                    {is_team_user ? <>Join Now</> : <>Yes, Sign Me Up for a Year</>}
                  </Button>
                </Col>
              </Row>
              {!is_team_user && (
                <Row className="mt-3">
                  <Col className="d-flex justify-content-center cursor-pointer">
                    <div onClick={() => doSignUpValue(false)}>
                      Continue to Pricing
                      <img src="/img/arrow-right.svg" className="ml-2 arrow" />
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          </Col>
        </Row>
        <TeamLearnMoreModal
          isOpen={learnMoreModalOpen}
          toggle={this.toggleLearnMoreModal}
        />
      </ContentWrapper>
    );
  }
}

SignUpValue.propTypes = {
  showSubmissionError: PropTypes.func,
  doSignUpValue: PropTypes.func,
  doSignUpTeam: PropTypes.func,
  doLogout: PropTypes.func,
  signup: PropTypes.instanceOf(Object),
  doSetSignupStep: PropTypes.func,
};
const mapStateToProps = (state) => ({
  signup: state.signup,
});
const mapDispatchToProps = (dispatch) => ({
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
  doSignUpValue: (skip) => dispatch(signUpValue(skip)),
  doSetSignupStep: (step) => dispatch(setSignUpStep(step)),
  doSignUpTeam: () => dispatch(signUpTeam()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpValue);
