import { combineReducers } from 'redux';
import authReducer from './authReducer';
import deviceReducer from './deviceReducer';
import spinnerReducer from './spinnerReducer';
import layoutReducer from './layoutReducer';
import userReducer from './userReducer';
import signupReducer from './signupReducer';
import contextReducer from './contextReducer';

export default combineReducers({
  auth: authReducer,
  spinner: spinnerReducer,
  device: deviceReducer,
  layout: layoutReducer,
  user: userReducer,
  signup: signupReducer,
  context: contextReducer,
});
