import { call, put, take, race, all, delay } from 'redux-saga/effects';
import { showAlert } from '../actions/globalActions';
import {
  SUBMIT_USER_SEARCH_REQUEST,
  UPDATE_TARGETS,
  RESET_TARGETS,
  GET_USER_PROFILE,
  UPDATE_USER,
  getUser,
  GET_MACRO_TARGETS,
  ADD_WEIGH_IN,
  UPDATE_WEIGH_IN,
  DELETE_WEIGH_IN,
  ADD_MEASUREMENTS,
  UPDATE_MEASUREMENTS,
  DELETE_MEASUREMENTS,
} from '../actions/userActions';
import {
  getUserProfile,
  updateUserProfile,
  getMacroTargets,
  users,
  userSearch,
  addWeighInApi,
  updateWeighInApi,
  deleteWeighInApi,
  addMeasurementsApi,
  updateMeasurementsApi,
  deleteMeasurementsApi,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_FAILURE,
  GET_TARGETS_SUCCESS,
  GET_TARGETS_FAILURE,
  USER_SEARCH_SUCCESS,
  USER_SEARCH_FAILURE,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  WEIGH_INS_SUCCESS,
  WEIGH_INS_FAILURE,
  MEASUREMENTS_SUCCESS,
  MEASUREMENTS_FAILURE,
} from '../apiActions/userApiActions';
import { spinnerDecrement } from '../actions/spinnerActions';
import { buildErrorMessage } from '../utils/apiCaller';

export default function* userSagas() {
  yield all([
    getUserSaga(),
    updateUserSaga(),
    submitUserSearchSaga(),
    getTargetsSaga(),
    updateTargetsSaga(),
    resetTargetsSaga(),
    addWeighInSaga(),
    editWeighInSaga(),
    deleteWeighInSaga(),
    addMeasurementsSaga(),
    editMeasurementsSaga(),
    deleteMeasurementsSaga(),
  ]);
}

function* getUserSaga() {
  while (true) {
    const { user_id } = yield take(GET_USER_PROFILE);
    yield put(getUserProfile(user_id));
    const { success, failure } = yield race({
      success: take(USER_PROFILE_SUCCESS),
      failure: take(USER_PROFILE_FAILURE),
    });
    if (failure) {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure)));
    }
  }
}

function* updateUserSaga() {
  while (true) {
    const { id, payload, auth_user } = yield take(UPDATE_USER);
    yield put(updateUserProfile({ user_id: id, ...payload }));
    const { success, failure } = yield race({
      success: take(UPDATE_USER_SUCCESS),
      failure: take(UPDATE_USER_FAILURE),
    });
    if (success) {
      if (auth_user) {
        yield put(getUserProfile(id, true));
      } else {
        yield put(getUser(id));
      }
      yield put(
        showAlert(
          'Done!',
          `${payload.first_name} ${payload.last_name}'${
            payload.last_name.charAt(payload.last_name.length - 1) !== 's' ? 's' : ''
          } data was sucessfully updated.`,
          'success'
        )
      );
    } else {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure)));
    }
  }
}

function* submitUserSearchSaga() {
  while (true) {
    const { values } = yield take(SUBMIT_USER_SEARCH_REQUEST);
    if (!values || Object.keys(values).length === 0) {
      // dispatch api call action for all users
      yield put(users());
    } else {
      // dispatch api call action for user search
      yield put(userSearch(values));
    }
    const { success, failure } = yield race({
      success: take(USER_SEARCH_SUCCESS),
      failure: take(USER_SEARCH_FAILURE),
    });
    if (failure) {
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* getTargetsSaga() {
  while (true) {
    const { user_id } = yield take(GET_MACRO_TARGETS);
    yield put(getMacroTargets(user_id));
    const { success, failure } = yield race({
      success: take(GET_TARGETS_SUCCESS),
      failure: take(GET_TARGETS_FAILURE),
    });
    if (failure) {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure)));
    }
  }
}

function* updateTargetsSaga() {
  while (true) {
    const { id, firstname, lastname, payload } = yield take(UPDATE_TARGETS);
    yield put(
      showAlert(
        'Done!',
        `${firstname} ${lastname}'${
          lastname.charAt(lastname.length - 1) !== 's' ? 's' : ''
        } custom macros have been saved! They will see their macro targets update in their app automatically.`,
        'success'
      )
    );
  }
}

function* resetTargetsSaga() {
  while (true) {
    const { id, firstname, lastname } = yield take(RESET_TARGETS);
    yield put(
      showAlert(
        'Done!',
        `${firstname} ${lastname}'${
          lastname.charAt(lastname.length - 1) !== 's' ? 's' : ''
        } macro targets have been set to use the Macrostax formula. They will see their macro targets update in their app automatically.`,
        'success'
      )
    );
  }
}

function* addWeighInSaga() {
  while (true) {
    const { id, values } = yield take(ADD_WEIGH_IN);
    yield put(addWeighInApi(id, values));
    const { success, failure } = yield race({
      success: take(WEIGH_INS_SUCCESS),
      failure: take(WEIGH_INS_FAILURE),
    });
    if (success) {
      yield put(showAlert('Done!', `Weigh-ins were successfully updated.`, 'success'));
    } else {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure)));
    }
  }
}

function* editWeighInSaga() {
  while (true) {
    const { id, values } = yield take(UPDATE_WEIGH_IN);
    yield put(updateWeighInApi(id, values));
    const { success, failure } = yield race({
      success: take(WEIGH_INS_SUCCESS),
      failure: take(WEIGH_INS_FAILURE),
    });
    if (success) {
      yield put(showAlert('Done!', `Weigh-ins were successfully updated.`, 'success'));
    } else {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure)));
    }
  }
}

function* deleteWeighInSaga() {
  while (true) {
    const { id, date } = yield take(DELETE_WEIGH_IN);
    yield put(deleteWeighInApi(id, date));
    const { success, failure } = yield race({
      success: take(WEIGH_INS_SUCCESS),
      failure: take(WEIGH_INS_FAILURE),
    });
    if (success) {
      yield put(showAlert('Done!', `Weigh-ins were successfully updated.`, 'success'));
    } else {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure)));
    }
  }
}

function* addMeasurementsSaga() {
  while (true) {
    const { id, values } = yield take(ADD_MEASUREMENTS);
    yield put(addMeasurementsApi(id, values));
    const { success, failure } = yield race({
      success: take(MEASUREMENTS_SUCCESS),
      failure: take(MEASUREMENTS_FAILURE),
    });
    if (success) {
      yield put(showAlert('Done!', `Measurements were successfully updated.`, 'success'));
    } else {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure)));
    }
  }
}

function* editMeasurementsSaga() {
  while (true) {
    const { id, values } = yield take(UPDATE_MEASUREMENTS);
    yield put(updateMeasurementsApi(id, values));
    const { success, failure } = yield race({
      success: take(MEASUREMENTS_SUCCESS),
      failure: take(MEASUREMENTS_FAILURE),
    });
    if (success) {
      yield put(showAlert('Done!', `Measurements were successfully updated.`, 'success'));
    } else {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure)));
    }
  }
}

function* deleteMeasurementsSaga() {
  while (true) {
    const { id, date } = yield take(DELETE_MEASUREMENTS);
    yield put(deleteMeasurementsApi(id, date));
    const { success, failure } = yield race({
      success: take(MEASUREMENTS_SUCCESS),
      failure: take(MEASUREMENTS_FAILURE),
    });
    if (success) {
      yield put(showAlert('Done!', `Measurements were successfully updated.`, 'success'));
    } else {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure)));
    }
  }
}
