import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, ModalBody, Row, Col, Button } from 'reactstrap';

class HelperTextModal extends Component {
  render() {
    const { toggle, isOpen, buttonText = 'Got It', helperText } = this.props;
    const { title, description } = helperText;
    
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className="modal-medium modal-centered helper-text-modal"
        centered
        backdrop="static"
      >
        <ModalBody>
          <Row>
            <Col xs={12}>
              <h3>{title}</h3>
              <div className="d-flex align-items-center justify-content-center mt-4">
                <div dangerouslySetInnerHTML={{ __html: description }} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="mt-4 d-flex justify-content-end">
              <Button color="primary" size="lg" onClick={toggle} className="fixed-width-md">
                {buttonText}
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

HelperTextModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(HelperTextModal);
