import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import numeral from 'numeral';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import moment from 'moment-timezone';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import Loading from '../../components/Common/Loading';
import { submissionError } from '../../actions/formActions';
import SmsForm from '../../forms/SmsForm';
import { signUpSms, setSignUpStep } from '../../actions/signupActions';
import { formatCentsAsCurrency } from '../../utils/numberUtils';
import { formatUppercase } from '../../utils/stringUtils';
import browserHistory from '../../history';

class SignUpComplete extends React.Component {
  componentDidMount() {
    const { signup, doSetSignupStep } = this.props;
    if (!signup || !signup.first_name || !signup.orderConfirmation) {
      browserHistory.push('/signup');
    } else {
      doSetSignupStep(12);
    }
  }

  render() {
    const { doSignUpSms, signup, showSubmissionError, device } = this.props;
    let membership;
    let first_name;
    let orderConfirmation;
    let payment;
    let startDate;
    let coupon;
    let newTotal;
    let confirmationCoupon;
    let is_team_user;
    let team_name;

    if (signup && signup.first_name) {
      ({
        membership,
        first_name,
        orderConfirmation: { startDate, newTotal, coupon: confirmationCoupon },
        payment: { coupon },
        is_team_user = false,
        team_name,
      } = signup);
    }
    const isMobile = device && (device.systemName === 'iOS' || device.systemName === 'Android');
    const isIOS = device && device.systemName === 'iOS';
    const isAndroid = device && device.systemName === 'Android';

    if (!coupon && confirmationCoupon) {
      // user applied a coupon without clicking "apply" so do the math
      coupon = {
        name: confirmationCoupon,
        savings: membership.fullPrice - newTotal,
      };
    }

    return (
      <ContentWrapper>
        <Row>
          <Col md={3} />
          <Col md={6} className="signup-complete-container">
            <div>
              {!membership ? (
                <Loading />
              ) : (
                <>
                  {is_team_user ? (
                    <>
                      <h1>You're all set!</h1>
                      <h3 className="mt-3 mb-4">
                        Thanks for joining {team_name} on Macrostax, {first_name}! We can't wait to
                        help you reach your goals!
                      </h3>
                      <Row>
                        <Col>
                          <strong>Macrostax Team Subscription</strong>
                        </Col>
                      </Row>
                      <Row>
                        <Col>Starts Today, {moment(startDate).format('MMMM D, YYYY')}</Col>
                      </Row>
                    </>
                  ) : (
                    <>
                      <h1>Order Complete!</h1>
                      <h3 className="mt-3 mb-4">
                        Thanks for joining Macrostax, {first_name}! We can't wait to help you reach
                        your goals!
                      </h3>
                      <Row>
                        <Col>
                          <strong>{membership.nameLong} Membership</strong>
                        </Col>
                      </Row>
                      <Row className={`${coupon ? '' : 'border-bottom'}`}>
                        <Col xs={8}>Starts {moment(startDate).format('MMMM D, YYYY')}</Col>
                        <Col className="text-right">
                          {numeral(membership.fullPrice).format('$0.00')}
                        </Col>
                      </Row>
                      {coupon && (
                        <Row className="border-bottom">
                          <Col xs={8}>Coupon ({formatUppercase(coupon.name)})</Col>
                          <Col className="text-right red">
                            {numeral(coupon.savings * -1).format('$0.00')}
                          </Col>
                        </Row>
                      )}

                      <Row>
                        <Col xs={8}>
                          <strong>Total Paid Today</strong>
                        </Col>
                        <Col className="text-right">
                          <strong>{numeral(newTotal).format('$0.00')}</strong>
                        </Col>
                      </Row>
                    </>
                  )}

                  <Row>
                    <Col className="margin-top-40 margin-bottom-20">
                      {/* <Button color="primary" size="lg" block onClick={doSignUpComplete}>
                        Go to Macrostax
                      </Button> */}
                      Download the Macrostax app to take the next step on your macro journey!
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </Col>
        </Row>
        {membership && (
          <Row className="signup-complete-container">
            <Col md={3} />
            <Col md={3}>
              {!isMobile && (
                <>
                  Scan this QR code:
                  <div className="mt-3">
                    <img src="/img/qr-code.svg" className="qr-code" />
                  </div>
                </>
              )}
              {isIOS && (
                <>
                  Download on the App Store:
                  <div className="mt-3">
                    <a href="https://apps.apple.com/us/app/macrostax-diet-meal-planner/id1568593622">
                      <img src="/img/appstore.png" className="app-store-badge" />
                    </a>
                  </div>
                </>
              )}
              {isAndroid && (
                <>
                  Download on the Google Play Store:
                  <div className="mt-3">
                    <a href="https://play.google.com/store/apps/details?id=com.macrostax.app">
                      <img src="/img/google-play-badge.png" className="app-store-badge" />
                    </a>
                  </div>
                </>
              )}
            </Col>
            <Col md={3} className="second-column">
              Text a link to your phone:
              <div className="mt-3">
                <SmsForm
                  showSubmissionError={showSubmissionError}
                  onSubmit={(values) => doSignUpSms(values)}
                  initialValues={{
                    phone: '',
                  }}
                />
              </div>
            </Col>
          </Row>
        )}
      </ContentWrapper>
    );
  }
}

SignUpComplete.propTypes = {
  doSignUpComplete: PropTypes.func,
  signup: PropTypes.instanceOf(Object),
  doSetSignupStep: PropTypes.func,
  showSubmissionError: PropTypes.func,
  device: PropTypes.instanceOf(Object),
};
const mapStateToProps = (state) => ({
  signup: state.signup,
  device: state.device,
});
const mapDispatchToProps = (dispatch) => ({
  doSignUpSms: (values) => {
    dispatch(signUpSms(values));
  },
  doSetSignupStep: (step) => dispatch(setSignUpStep(step)),
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpComplete);
