// Note: these IDs are actually phase_ids, because that's what the BE expects.
// They are the phase 1 ID for each of the goals
export const goals = [
  {
    title: 'Lose',
    value: 'e3a1a81a-8be8-4db4-9a9c-f070a45a66b2',
    description: 'I want to lose weight and learn to keep it off the right way for long-term success.',
  },
  {
    title: 'Gain',
    value: 'd4d7cbac-60c6-4fda-b4ea-71b27b751b13',
    description: 'I would like to get stronger and gain lean weight in a healthy way.',
  },
  {
    title: 'Maintain',
    value: '99aad250-f16b-4c1b-a02d-a5abfb6080d9',
    description: 'I would like to keep my weight where it is, but change my body composition.',
  },
];

export const reverseDieting = '9e3c5fe1-d931-43f2-8aaf-eecf6b1faf17';