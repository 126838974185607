export const JWT_TOKEN_KEY = 'jwtToken';
export const JWT_REFRESH_KEY = 'jwtRefresh';
export const INIT_EMAIL_KEY = 'email';
export const USER_ID_KEY = 'userId';

const PREFIX = 'macrostax-team:';

export const getLocalStoreValue = (key) => {
  const newKey = PREFIX + key;
  const result = localStorage.getItem(newKey);
  if (result !== undefined) {
    return JSON.parse(result);
  }
  return undefined;
};

export const setLocalStoreValue = (key, value) => {
  const newKey = PREFIX + key;
  localStorage.setItem(newKey, JSON.stringify(value));
  return true;
};

export const removeLocalStoreValues = () => {
  localStorage.removeItem(`${PREFIX}${JWT_TOKEN_KEY}`);
  localStorage.removeItem(`${PREFIX}${JWT_REFRESH_KEY}`);
  // localStorage.removeItem(`${PREFIX}${INIT_EMAIL_KEY}`);
  localStorage.removeItem(`${PREFIX}${USER_ID_KEY}`);
};
