import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import Loading from '../../components/Common/Loading';
import InterstitialModal from '../../modals/InterstitialModal';
import { submissionError } from '../../actions/formActions';
import { signUpActivityLevel, setSignUpStep } from '../../actions/signupActions';
import browserHistory from '../../history';

class SignUpActivityLevel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      interstitialModalOpen: false,
    };
  }

  componentDidMount() {
    const { signup, doSetSignupStep } = this.props;
    if (!signup || !signup.first_name) {
      browserHistory.push('/signup');
    } else {
      doSetSignupStep(7);
    }
  }

  componentWillUnmount() {
    this.setState({
      interstitialModalOpen: false,
    });
  }

  toggleInterstitialModal = () => {
    const { interstitialModalOpen } = this.state;
    this.setState({
      interstitialModalOpen: !interstitialModalOpen,
    });
  };

  render() {
    const { signup } = this.props;
    const { interstitialModalOpen } = this.state;
    let activityLevel;
    if (signup && signup.activityLevel) {
      ({ activityLevel } = signup);
    }

    return (
      <ContentWrapper>
        {activityLevel ? (
          <>
            <Row>
              <Col
                md={12}
                className="d-flex flex-column justify-content-center align-items-center mb-5-md text-center"
              >
                <h1>Your Activity Level</h1>
                <h3>
                  Based upon the answers you selected, we have calculated your activity level for
                  you.
                </h3>
              </Col>
            </Row>
            <Row>
              <Col md={3} />
              <Col md={6} className="d-flex flex-column align-items-center mb-3">
                <div className="signup-form-wrapper">
                  <Row>
                    <Col className="d-flex align-items-center flex-column">
                      <div className="activity-level">
                        <div className="number">{activityLevel.label.replace('level ', '')}</div>
                        level
                      </div>
                      <div className="activity-level-caption">Your Activity Level</div>
                      <div className="mt-5">
                        We use this number to calculate your carb, protein, and fat targets. You can
                        change your Activity Level at any time in the app.
                      </div>
                    </Col>
                  </Row>
                  <Row className="margin-top-60">
                    <Col className="d-flex">
                      <Button
                        color="primary"
                        size="lg"
                        onClick={this.toggleInterstitialModal}
                        block
                      >
                        Next
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <InterstitialModal
              isOpen={interstitialModalOpen}
              toggle={this.toggleInterstitialModal}
              activityLevelId={activityLevel.uuid}
            />
          </>
        ) : (
          <Loading />
        )}
      </ContentWrapper>
    );
  }
}

SignUpActivityLevel.propTypes = {
  showSubmissionError: PropTypes.func,
  doSignUpActivityLevel: PropTypes.func,
  doLogout: PropTypes.func,
  signup: PropTypes.instanceOf(Object),
  doSetSignupStep: PropTypes.func,
};
const mapStateToProps = (state) => ({
  signup: state.signup,
});
const mapDispatchToProps = (dispatch) => ({
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
  doSignUpActivityLevel: (level) => dispatch(signUpActivityLevel(level)),
  doSetSignupStep: (step) => dispatch(setSignUpStep(step)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpActivityLevel);
