import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import CreditCardFormPartial from '../../forms/partials/CreditCardFormPartial';

class CreditCardFormWrapper extends Component {
  render() {
    const {
      toggle,
      showSubmissionError,
      showCancel,
      buttonText,
      buttonColClass,
      onSubmit,
      showName,
      showCoupon,
      signup,
      onCoupon,
      onClearCoupon,
    } = this.props;
    const coupon = signup && signup.validatedCoupon ? signup.validatedCoupon.name : ''
    return (
      <ElementsConsumer>
        {({ stripe, elements }) => (
          <CreditCardFormPartial
            onCancel={toggle}
            stripe={stripe}
            elements={elements}
            showSubmissionError={showSubmissionError}
            showCancel={showCancel}
            buttonText={buttonText}
            buttonColClass={buttonColClass}
            onSubmit={onSubmit}
            initialValues={{ nameOnCard: '', coupon, membershipOptionId: signup.membership.id }}
            showName={showName}
            showCoupon={showCoupon}
            signup={signup}
            onCoupon={onCoupon}
            onClearCoupon={onClearCoupon}
          />
        )}
      </ElementsConsumer>
    );
  }
}

CreditCardFormWrapper.propTypes = {
  toggle: PropTypes.func,
  showSubmissionError: PropTypes.func,
  showCancel: PropTypes.bool,
  showName: PropTypes.bool,
  showCoupon: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonColClass: PropTypes.string,
  onSubmit: PropTypes.func,
  signup: PropTypes.instanceOf(Object),
  onCoupon: PropTypes.func,
  onClearCoupon: PropTypes.func,
};

export default CreditCardFormWrapper;
