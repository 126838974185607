import swal from 'sweetalert';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import { osName, osVersion, browserName, fullBrowserVersion, getUA } from 'react-device-detect';

export const defaultProfilePhoto =
  'https://t4.ftcdn.net/jpg/03/46/93/61/360_F_346936114_RaxE6OQogebgAWTalE1myseY1Hbb5qPM.jpg';

export const displayConfirm = (text, title, icon = 'info') =>
  new Promise((resolve, reject) => {
    swal({
      title,
      text,
      icon,
      buttons: {
        cancel: true,
        confirm: {
          text: 'Yes',
        },
      },
    }).then((value) => {
      if (value) {
        resolve(true);
      } else {
        reject(new Error('rejected'));
      }
    });
  });

export const getDeviceInfo = () => ({
  deviceId: `${browserName} ${fullBrowserVersion}`,
  systemName: osName,
  systemVersion: osVersion,
  appVersion: 'n/a',
  timezone: moment.tz.guess(),
  userAgent: getUA,
  client: 'web',
});

export const copyToClipboard = (val, label) => {
  navigator.clipboard.writeText(val);
  toast(`${label} Copied to Clipboard!`, {
    type: 'info',
    position: 'top-center',
    autoClose: 3000,
  });
};
