export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const UPDATE_USER = 'UPDATE_USER';
export const GET_MACRO_TARGETS = 'GET_MACRO_TARGETS';
export const UPDATE_TARGETS = 'UPDATE_TARGETS';
export const RESET_TARGETS = 'RESET_TARGETS';
export const ADD_USER = 'ADD_USER';
export const SUBMIT_USER_SEARCH_REQUEST = 'SUBMIT_USER_SEARCH_REQUEST';
export const CLEAR_USER_SEARCH_FORM = 'CLEAR_USER_SEARCH_FORM';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const ADD_WEIGH_IN = 'ADD_WEIGH_IN';
export const UPDATE_WEIGH_IN = 'UPDATE_WEIGH_IN';
export const DELETE_WEIGH_IN = 'DELETE_WEIGH_IN';
export const ADD_MEASUREMENTS = 'ADD_MEASUREMENTS';
export const UPDATE_MEASUREMENTS = 'UPDATE_MEASUREMENTS';
export const DELETE_MEASUREMENTS = 'DELETE_MEASUREMENTS';

export function getUser(user_id) {
  return {
    type: GET_USER_PROFILE,
    user_id,
  };
}

export function updateUser(id, payload, auth_user = false) {
  return {
    type: UPDATE_USER,
    id,
    payload,
    auth_user,
  };
}

export function getTargets(user_id) {
  return {
    type: GET_MACRO_TARGETS,
    user_id,
  };
}

export function updateTargets(id, firstname, lastname, payload) {
  return {
    type: UPDATE_TARGETS,
    id,
    firstname,
    lastname,
    payload,
  };
}

export function resetTargets(id, firstname, lastname) {
  return {
    type: RESET_TARGETS,
    id,
    firstname,
    lastname,
    isCustomMacros: false,
  };
}

export function addUser(values) {
  return {
    type: ADD_USER,
    values,
  };
}

export function submitUserSearchRequest(values) {
  return {
    type: SUBMIT_USER_SEARCH_REQUEST,
    values,
  };
}

export function clearUserSearchForm() {
  return {
    type: CLEAR_USER_SEARCH_FORM,
  };
}

export function resetPassword(email) {
  return {
    type: RESET_PASSWORD_REQUEST,
    email,
  };
}

export function addWeighIn(id, values) {
  return {
    type: ADD_WEIGH_IN,
    id,
    values,
  };
}

export function updateWeighIn(id, values) {
  return {
    type: UPDATE_WEIGH_IN,
    id,
    values,
  };
}

export function deleteWeighIn(id, date) {
  return {
    type: DELETE_WEIGH_IN,
    id,
    date,
  };
}

export function addMeasurements(id, values) {
  return {
    type: ADD_MEASUREMENTS,
    id,
    values,
  };
}

export function updateMeasurements(id, values) {
  return {
    type: UPDATE_MEASUREMENTS,
    id,
    values,
  };
}

export function deleteMeasurements(id, date) {
  return {
    type: DELETE_MEASUREMENTS,
    id,
    date,
  };
}
