import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import renderInputForField from '../helpers/renderInputForField';
import { capitalizeFirst } from '../../utils/stringUtils';

class LastNameField extends Component {
  render() {
    const {
      fieldName = 'last_name',
      label = 'Last Name',
      inputCaption,
      showRequired = false,
      showLabel = true,
    } = this.props;

    const displayLabel = showLabel ? label : '';

    return (
      <Field
        name={fieldName}
        component={renderInputForField}
        label={`${displayLabel}${showRequired ? '*' : ''}`}
        format={capitalizeFirst}
        inputCaption={inputCaption}
        stackedlabel
        {...{
          inputProps: {
            type: 'text',
            id: fieldName,
          },
          itemProps: {},
        }}
      />
    );
  }
}

LastNameField.propTypes = {
  fieldName: PropTypes.string,
  label: PropTypes.string,
  inputCaption: PropTypes.string,
  showRequired: PropTypes.bool,
};

export default LastNameField;
