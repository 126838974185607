import React, { Component, forwardRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { FormGroup, Input } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// Note that the date format tokens are different than moment. The tokens can be found here:
// https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
export class RenderDatepickerForFieldComponent extends Component {
  handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  render() {
    const {
      input,
      label,
      stackedlabel,
      meta: { error },
      itemProps,
      systemName,
      inputProps,
    } = this.props;

    const { minDate, maxDate } = inputProps;
    let newInputProps;

    let labelClassName = '';
    const inputClassName = 'form-control date-picker';
    let inputColClassName = '';
    let formGroupClassName = itemProps.row ? 'd-flex flex-row centered' : 'stacked';
    formGroupClassName += ' datetime-container';

    if (itemProps.row) {
      labelClassName = 'col-xl-3 col-form-label';
      inputColClassName += ' col-xl-9';
    }

    const showLabel = () => {
      if (label && label.length) {
        return <label className={labelClassName}>{label}</label>;
      }
      return null;
    };

    let format = inputProps && inputProps.dateFormat ? inputProps.dateFormat : 'MMMM d, yyyy';
    format = format.replace('YYYY', 'yyyy');

    const ReadOnlyInput = forwardRef(({ value, onClick }, ref) => (
      <Input onClick={onClick} ref={ref} value={value} readOnly={true} />
    ));

    if (systemName === 'iOS' || systemName === 'Android') {
      newInputProps = {
        min: moment(minDate).format('YYYY-MM-DD'),
        max: moment(maxDate).format('YYYY-MM-DD'),
      };
    }

    return (
      <FormGroup {...itemProps} className={formGroupClassName}>
        {showLabel()}
        <div className={inputColClassName}>
          {/* 1 === 2 check here to disable input type=date for the moment, keeping for posterity in case we want to revisit. */}
          {(systemName === 'iOS' || systemName === 'Android') && 1 === 2 ? (
            <Input type="date" {...input} {...newInputProps} />
          ) : (
            <DatePicker
              {...inputProps}
              selected={input.value}
              onChange={input.onChange}
              onMonthChange={input.onChange}
              onYearChange={input.onChange}
              className={inputClassName}
              dateFormat={format}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              disabledKeyboardNavigation
              onChangeRaw={this.handleDateChangeRaw}
              customInput={<ReadOnlyInput />}
            />
          )}
        </div>
      </FormGroup>
    );
  }
}

RenderDatepickerForFieldComponent.propTypes = {
  input: PropTypes.shape({
    checked: PropTypes.bool,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.any,
    onSubmitEditing: PropTypes.func,
  }),
  label: PropTypes.string,
  stackedlabel: PropTypes.bool,
  iconName: PropTypes.string,
  iconType: PropTypes.string,
  systemName: PropTypes.string,
  meta: PropTypes.shape({
    active: PropTypes.bool,
    autofilled: PropTypes.bool,
    asyncValidating: PropTypes.bool,
    dirty: PropTypes.bool,
    dispatch: PropTypes.func,
    error: PropTypes.string,
    form: PropTypes.string,
    initial: PropTypes.any,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    submitFailed: PropTypes.bool,
    touched: PropTypes.bool,
    valid: PropTypes.bool,
    visited: PropTypes.bool,
    warning: PropTypes.string,
  }),
  children: PropTypes.instanceOf(Array),
  itemProps: PropTypes.shape({
    floatingLabel: PropTypes.bool,
    row: PropTypes.bool,
    last: PropTypes.bool,
  }),
  inputProps: PropTypes.shape({
    closeOnSelect: PropTypes.bool,
    dateFormat: PropTypes.string,
  }),
};

export default forwardRef((props, ref) => (
  <RenderDatepickerForFieldComponent innerRef={ref} {...props} />
));
