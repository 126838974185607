import React from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import HelperTextIcon from '../../components/Common/HelperTextIcon';
import SignUpLifestyleForm from '../../forms/SignUpLifestyleForm';
import { submissionError } from '../../actions/formActions';
import { signUpLifestyle, setSignUpStep } from '../../actions/signupActions';
import browserHistory from '../../history';

class SignUpLifestyle extends React.Component {
  componentDidMount() {
    const { signup, doSetSignupStep } = this.props;
    if (!signup || !signup.first_name) {
      browserHistory.push('/signup');
    } else {
      doSetSignupStep(6);
    }
  }

  render() {
    const { showSubmissionError, doSignUpLifestyle, signup } = this.props;
    const steps = [
      { number: 1, label: 'Lose weight' },
      { number: 2, label: 'Or gain weight' },
      { number: 3, label: 'Or do some body re-composition' },
      { number: 4, label: 'You do you!' },
    ];
    return (
      <ContentWrapper>
        <Row>
          <Col
            md={12}
            className="d-flex flex-column justify-content-center align-items-center mb-5-md text-center"
          >
            <div className="d-flex">
              <h1>Your Lifestyle</h1>
              <HelperTextIcon helperText="LIFESTYLE" />
            </div>
            <h3>
              Select the option that best describes how you spend your time outside of workouts.
            </h3>
          </Col>
        </Row>
        <Row>
          <Col md={3} />
          <Col md={6} className="d-flex flex-column align-items-center mb-3">
            <div className="signup-form-wrapper">
              <SignUpLifestyleForm
                showSubmissionError={showSubmissionError}
                onSubmit={(values) => doSignUpLifestyle(values)}
                initialValues={{ lifestyle: '', ...signup }}
              />
            </div>
          </Col>
          {/* <Col className="d-flex flex-column align-items-center">
            <div className="signup-form-wrapper">
              <div className="icon-headline-container d-flex align-items-center border-bottom pb-4">
                <img src="/img/signup/icon-step-2.svg" className="mr-4" />
                <h2>What would you like to put here?</h2>
              </div>

              <div className="signup-steps benefits mt-4 pl-3">
                {steps.map((s) => (
                  <div className="signup-step benefit d-flex" key={s.number}>
                    <div className="signup-step-check mr-3">
                      <em className="fas fa-check" />
                    </div>
                    <div className="signup-step-label">{s.label}</div>
                  </div>
                ))}
              </div>
            </div>
          </Col> */}
        </Row>
      </ContentWrapper>
    );
  }
}

SignUpLifestyle.propTypes = {
  showSubmissionError: PropTypes.func,
  doSignUpLifestyle: PropTypes.func,
  doLogout: PropTypes.func,
  signup: PropTypes.instanceOf(Object),
  doSetSignupStep: PropTypes.func,
};
const mapStateToProps = (state) => ({
  signup: state.signup,
});
const mapDispatchToProps = (dispatch) => ({
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
  doSignUpLifestyle: (values) => dispatch(signUpLifestyle(values)),
  doSetSignupStep: (step) => dispatch(setSignUpStep(step)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpLifestyle);
