import axios from 'axios';
import { ApolloClient, InMemoryCache, ApolloProvider, useQuery, gql } from '@apollo/client';
import ENV_VARS from './envVars';

export function gqlRequest(config, token, device) {
  const { type, document, variables = {} } = config;
  const callConfig = {
    uri: `${ENV_VARS.BASE_URL}/graphql`,
    cache: new InMemoryCache(),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };

  if (device && device.systemName) {
    callConfig.headers = {
      ...callConfig.headers,
      'x-device': JSON.stringify(device),
    };
  }

  // inject token if passed
  if (token) {
    callConfig.headers = { ...callConfig.headers, Authorization: `Bearer ${token}` };
  }

  const gqlClient = new ApolloClient(callConfig);

  switch (type) {
    case 'query': {
      return new Promise(function(resolve, reject) {
        gqlClient.query({
          query: document,
          variables,
        })
        .then((result) => {
          return resolve(result);
        })
        .catch((err) => {
          console.log('ERROR in query', err);
          return reject(err);
        });
      });
    }
    case 'mutation': {
      return new Promise(function(resolve, reject) {
        gqlClient.mutate({
          mutation: document,
          variables,
        })
        .then((result) => {
          return resolve(result);
        })
        .catch((err) => {
          console.log('ERROR in mutation', err);
          return reject(err);
        });
      });
      break;
    }
  }
}

export function apiRequest(config, token, device) {
  const callConfig = { ...config };

  // if no override baseURL passed, put in default (from env)
  if (!config.baseURL) {
    callConfig.baseURL = ENV_VARS.BASE_URL;
  }

  // if no method specified make it a 'get'
  if (!config.method) {
    callConfig.method = 'GET';
  }

  // set the content type and accept headers
  callConfig.headers = {
    ...callConfig.headers,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  if (device && device.systemName) {
    callConfig.headers = {
      ...callConfig.headers,
      'x-device': JSON.stringify(device),
    };
  }

  // inject token if passed
  if (token) {
    callConfig.headers = { ...callConfig.headers, Authorization: `Bearer ${token}` };
  }

  return axios(callConfig);
}

export function getStatusFromError(httpError) {
  const { error } = httpError;
  console.error(error);
  if (error && error.response && error.response.status) {
    return error.response.status;
  }
  return null;
}

export function URLEncode(param) {
  let output = '';
  let x = 0;
  const clearString = param.toString();
  const regex = /(^[a-zA-Z0-9_.]*)/;
  while (x < clearString.length) {
    const match = regex.exec(clearString.substr(x));
    if (match != null && match.length > 1 && match[1] !== '') {
      output += match[1];
      x += match[1].length;
    } else {
      if (clearString[x] === ' ') output += '+';
      else {
        const charCode = clearString.charCodeAt(x);
        const hexVal = charCode.toString(16);
        output += `%${hexVal.length < 2 ? '0' : ''}${hexVal.toUpperCase()}`;
      }
      x += 1;
    }
  }
  return output;
}

export function buildIdsQueryString(ids) {
  if (!ids || !ids.length || ids.length <= 0) {
    return [];
  }
  let amp = '';
  const queryString = ids.reduce((result, userId) => {
    // add &ids=... to end of current result (amp empty first time)
    const newStr = `${result}${amp}ids=${userId}`;
    amp = '&';
    return newStr;
  }, '?');
  return queryString;
}

export function buildQueryStringFromObject(obj) {
  if (!obj) {
    return '';
  }
  const queryString = Object.keys(obj)
    .map((key) => `${key}=${URLEncode(obj[key])}`)
    .join('&');
  return queryString;
}

export function buildErrorMessage(failure) {
  let err = 'There was an error';
  console.error(failure);
  if (failure && failure.error && failure.error.response && failure.error.response.data) {
    if (failure.error.response.data.message) {
      err += `: ${failure.error.response.data.message}`;
    } else if (failure.error.response.data === 'Unauthorized') {
      err += ': That username/password could not be found. Please try again.';
    }
  } else if (
    failure &&
    failure.error &&
    failure.error.message &&
    failure.error.message === 'Network Error'
  ) {
    err += ': Unable to connect to the server. Please try again.';
  } else if (
    failure &&
    failure.error &&
    failure.error.message
  ) {
    err += `: ${failure.error.message}`;
  } else if (failure && failure.errors && failure.errors[0] && failure.errors[0].message) {
    err += `: ${failure.errors[0].message}`
  }
  return err;
}

export function mapStringToArray(str) {
  return str !== undefined ? str.split(',').map((item) => item.trim()) : str;
}
export function mapArrayToString(arr) {
  return arr !== undefined ? arr.join(',') : arr;
}
export function enforceArray(item) {
  if (item !== undefined && Array.isArray(item)) {
    return item;
  }
  return mapStringToArray(item);
}

export function removeEmptyValues(values) {
  const scrubbed = {};
  const keys = Object.keys(values);
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    const value = values[key];
    if (values[key] !== '') {
      scrubbed[key] = values[key];
    }
  }
  return scrubbed;
}
