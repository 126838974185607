import React, { Component } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BasePreAuth from './components/Layout/BasePreAuth';

import SignUpScreen from './screens/PreAuth/SignUp';
import SignUpInputsScreen from './screens/PreAuth/SignUpInputs';
import SignUpGoalScreen from './screens/PreAuth/SignUpGoal';
import SignUpDietScreen from './screens/PreAuth/SignUpDiet';
import SignUpWorkoutsScreen from './screens/PreAuth/SignUpWorkouts';
import SignUpLifestyleScreen from './screens/PreAuth/SignUpLifestyle';
import SignUpActivityLevelScreen from './screens/PreAuth/SignUpActivityLevel';
import SignUpPlanScreen from './screens/PreAuth/SignUpPlan';
import SignUpValueScreen from './screens/PreAuth/SignUpValue';
import SignUpMembershipsScreen from './screens/PreAuth/SignUpMemberships';
import SignUpBillingScreen from './screens/PreAuth/SignUpBilling';
import SignUpCompleteScreen from './screens/PreAuth/SignUpComplete';
import SignUpAppScreen from './screens/PreAuth/SignUpApp';

class Routes extends Component {
  render() {
    const { location } = this.props;
    const animationName = 'rag-fadeIn';
    const preAuthClassName = 'preauth';

    document.body.classList.add(preAuthClassName);

    return (
      <BasePreAuth>
        <TransitionGroup>
          <CSSTransition key={location.key} timeout={500} classNames={animationName}>
            <Switch location={location}>
              <Route path="/signup/inputs" exact>
                <SignUpInputsScreen />
              </Route>
              <Route path="/signup/goal" exact>
                <SignUpGoalScreen />
              </Route>
              <Route path="/signup/diet" exact>
                <SignUpDietScreen />
              </Route>
              <Route path="/signup/workouts" exact>
                <SignUpWorkoutsScreen />
              </Route>
              <Route path="/signup/lifestyle" exact>
                <SignUpLifestyleScreen />
              </Route>
              <Route path="/signup/activitylevel" exact>
                <SignUpActivityLevelScreen />
              </Route>
              <Route path="/signup/plan" exact>
                <SignUpPlanScreen />
              </Route>
              <Route path="/signup/yearly" exact>
                <SignUpValueScreen />
              </Route>
              <Route path="/signup/memberships" exact>
                <SignUpMembershipsScreen />
              </Route>
              <Route path="/signup/billing" exact>
                <SignUpBillingScreen />
              </Route>
              <Route path="/signup/complete" exact>
                <SignUpCompleteScreen />
              </Route>
              <Route path="/signup/app" exact>
                <SignUpAppScreen />
              </Route>
              <Route path="/signup" exact>
                <SignUpScreen />
              </Route>
              {location.pathname !== '/signup' ? <Redirect to="/signup" /> : null}
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      </BasePreAuth>
    );
  }
}

Routes.propTypes = {
  location: PropTypes.instanceOf(Object),
};

const mapStateToProps = (state) => ({
});

export default withRouter(connect(mapStateToProps, null)(Routes));
