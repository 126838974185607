import React, { Component, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input } from 'reactstrap';
import HelperTextIcon from '../../components/Common/HelperTextIcon';

class RenderInputForFieldComponent extends Component {
  render() {
    const {
      input,
      label,
      iconName,
      iconType,
      caption,
      inputCaption,
      stackedlabel = true,
      meta: { error, submitError, submitFailed },
      meta,
      itemProps,
      inputProps,
      required,
      helperText,
      postInputCaption,
    } = this.props;

    let { inputClassName = '', labelClassName = '' } = this.props;

    inputClassName += ' width-100-percent';
    let formGroupClassName = itemProps.row ? 'flex-row' : stackedlabel ? 'stacked' : 'not-stacked';

    if (itemProps.row) {
      labelClassName += ' col-xl-3 col-form-label';
      inputClassName = 'col-xl-9';
    }

    if (inputCaption === undefined || input.type === 'textarea') {
      formGroupClassName += ' centered';
    }

    const showLabel = () => {
      if (label && label.length) {
        return (
          <>
            <div className="d-flex flex-row align-items-center label-container">
              <label className={labelClassName}>
                {required && <span className="required-field">*</span>}
                {label}
              </label>
              {helperText && <HelperTextIcon helperText={helperText} />}
            </div>
            {caption !== undefined && caption.length ? (
              <div className="caption">{caption}</div>
            ) : null}
          </>
        );
      }
      return null;
    };
    const showError = () => null;
    const showIcon = () =>
      iconName !== undefined && iconName.length ? (
        <em className={iconName} type={iconType} />
      ) : null;

    if (submitError) {
      console.log(submitError);
    }
    return stackedlabel ? (
      <FormGroup {...itemProps} className={formGroupClassName}>
        {showLabel()}
        {showIcon()}
        <div className={postInputCaption !== undefined ? 'flex-row align-items-center' : ''}>
          <Input
            {...input}
            {...inputProps}
            className={`${inputClassName} ${error && submitFailed ? 'error' : ''}`}
          />
          {postInputCaption !== undefined ? (
            <div className="post-input-caption">{postInputCaption}</div>
          ) : null}
        </div>
        {inputCaption !== undefined ? (
          <div className="input-caption caption">{inputCaption}</div>
        ) : null}
        {showError()}
      </FormGroup>
    ) : (
      <FormGroup {...itemProps} className={formGroupClassName}>
        {showLabel()}
        <div className={inputClassName}>
          {showIcon()}
          <Input {...input} {...inputProps} className={`${error && submitFailed ? 'error' : ''}`} />
          {inputCaption !== undefined ? (
            <div className="input-caption caption">{inputCaption}</div>
          ) : null}
        </div>
        {showError()}
      </FormGroup>
    );
  }
}

RenderInputForFieldComponent.propTypes = {
  input: PropTypes.shape({
    checked: PropTypes.bool,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.any,
    type: PropTypes.string,
  }),
  label: PropTypes.string,
  postInputCaption: PropTypes.string,
  stackedlabel: PropTypes.bool,
  iconName: PropTypes.string,
  caption: PropTypes.string,
  inputCaption: PropTypes.string,
  iconType: PropTypes.string,
  refField: PropTypes.string,
  required: PropTypes.bool,
  meta: PropTypes.shape({
    active: PropTypes.bool,
    autofilled: PropTypes.bool,
    asyncValidating: PropTypes.bool,
    dirty: PropTypes.bool,
    dispatch: PropTypes.func,
    error: PropTypes.string,
    form: PropTypes.string,
    initial: PropTypes.any,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    submitFailed: PropTypes.bool,
    touched: PropTypes.bool,
    valid: PropTypes.bool,
    visited: PropTypes.bool,
    warning: PropTypes.string,
  }),
  children: PropTypes.instanceOf(Array),
  itemProps: PropTypes.shape({
    floatingLabel: PropTypes.bool,
    row: PropTypes.bool,
    last: PropTypes.bool,
  }),
  inputProps: PropTypes.shape({
    multiline: PropTypes.bool,
    rowSpan: PropTypes.number,
  }),
};

export default forwardRef((props, ref) => (
  <RenderInputForFieldComponent innerRef={ref} {...props} />
));
