import Qs from 'qs';

import ENV_VARS from '../utils/envVars';
import { removeEmptyValues } from '../utils/apiCaller';

export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS';
export const USER_PROFILE_FAILURE = 'USER_PROFILE_FAILURE';
export const AUTH_USER_PROFILE_SUCCESS = 'AUTH_USER_PROFILE_SUCCESS';
export const AUTH_USER_PROFILE_FAILURE = 'AUTH_USER_PROFILE_FAILURE';
export const GET_TARGETS_SUCCESS = 'GET_TARGETS_SUCCESS';
export const GET_TARGETS_FAILURE = 'GET_TARGETS_FAILURE';
export const USER_SEARCH_SUCCESS = 'USER_SEARCH_SUCCESS';
export const USER_SEARCH_FAILURE = 'USER_SEARCH_FAILURE';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const WEIGH_INS_SUCCESS = 'WEIGH_INS_SUCCESS';
export const WEIGH_INS_FAILURE = 'WEIGH_INS_FAILURE';
export const MEASUREMENTS_SUCCESS = 'MEASUREMENTS_SUCCESS';
export const MEASUREMENTS_FAILURE = 'MEASUREMENTS_FAILURE';
export const PENDING_CLIENT_SEARCH_SUCCESS = 'PENDING_CLIENT_SEARCH_SUCCESS';
export const PENDING_CLIENT_SEARCH_FAILURE = 'PENDING_CLIENT_SEARCH_FAILURE';

export function getUserProfile(user_id, fromAuth = false) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/profile',
    method: 'GET',
    params: {
      user_id,
    },
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: fromAuth ? AUTH_USER_PROFILE_SUCCESS : USER_PROFILE_SUCCESS,
    failureAction: fromAuth ? AUTH_USER_PROFILE_FAILURE : USER_PROFILE_FAILURE,
  };
}

export function updateUserProfile(user_profile) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/edit-client',
    method: 'PUT',
    data: user_profile,
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: UPDATE_USER_SUCCESS,
    failureAction: UPDATE_USER_FAILURE,
  };
}

export function getMacroTargets(user_id) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/macros',
    method: 'GET',
    params: {
      user_id,
    },
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: GET_TARGETS_SUCCESS,
    failureAction: GET_TARGETS_FAILURE,
  };
}

export function users() {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/clients',
    method: 'GET',
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: USER_SEARCH_SUCCESS,
    failureAction: USER_SEARCH_FAILURE,
  };
}

export function userSearch(values, type = 'user') {
  const scrubbedValues = removeEmptyValues(values);
  if (Object.keys(scrubbedValues).length === 0) {
    return users();
  }
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/client-search',
    method: 'GET',
    params: scrubbedValues,
    paramsSerializer: function (params) {
      return Qs.stringify(params, { arrayFormat: 'comma', encode: false });
    },
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: type === 'user' ? USER_SEARCH_SUCCESS : PENDING_CLIENT_SEARCH_SUCCESS,
    failureAction: type === 'user' ? USER_SEARCH_FAILURE : PENDING_CLIENT_SEARCH_FAILURE,
  };
}

export function addWeighInApi(user_id, values) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/add-weigh-in',
    method: 'PUT',
    data: { user_id, ...values },
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: WEIGH_INS_SUCCESS,
    failureAction: WEIGH_INS_FAILURE,
  };
}

export function updateWeighInApi(user_id, values) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/edit-weigh-in',
    method: 'PUT',
    data: { user_id, ...values },
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: WEIGH_INS_SUCCESS,
    failureAction: WEIGH_INS_FAILURE,
  };
}

export function deleteWeighInApi(user_id, date) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/delete-weigh-in',
    method: 'PUT',
    data: { user_id, date },
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: WEIGH_INS_SUCCESS,
    failureAction: WEIGH_INS_FAILURE,
  };
}

export function addMeasurementsApi(user_id, values) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/add-measurements',
    method: 'PUT',
    data: { user_id, ...values },
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: MEASUREMENTS_SUCCESS,
    failureAction: MEASUREMENTS_FAILURE,
  };
}

export function updateMeasurementsApi(user_id, values) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/edit-measurements',
    method: 'PUT',
    data: { user_id, ...values },
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: MEASUREMENTS_SUCCESS,
    failureAction: MEASUREMENTS_FAILURE,
  };
}

export function deleteMeasurementsApi(user_id, date) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/delete-measurements',
    method: 'PUT',
    data: { user_id, date },
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: MEASUREMENTS_SUCCESS,
    failureAction: MEASUREMENTS_FAILURE,
  };
}
