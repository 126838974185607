import ENV_VARS from '../utils/envVars';

const init = (e, t, n) => {
  if (e.snaptr) return;

  var a = (e.snaptr = function () {
    a.handleRequest ? a.handleRequest.apply(a, arguments) : a.queue.push(arguments);
  });

  a.queue = [];
  var s = 'script';
  var r = t.createElement(s);
  r.async = !0;
  r.src = n;
  var u = t.getElementsByTagName(s)[0];
  u.parentNode.insertBefore(r, u);

  window.snaptr = snaptr;

  window.snaptr('init', `${ENV_VARS.SNAPCHAT_APP_ID}`, {
    user_email: '',
  });

  window.snaptr('track', 'PAGE_VIEW');
};

const checkout = (signup) => {
  let price = signup.orderConfirmation.newTotal || '0.00';
  price = parseFloat(price);

  const snapParams = {
    'currency': 'USD', 
    'price': price, 
    'transaction_id': signup.orderConfirmation.membershipId,
    'user_email': signup.email.toLowerCase()
  };

  window.snaptr('track', 'PURCHASE', snapParams);
}

export default {
  init,
  checkout,
};
