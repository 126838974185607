import { all, take, put, call } from 'redux-saga/effects';
import swal from 'sweetalert';
import { SHOW_ALERT, showAlert } from '../actions/globalActions';
import { SUBMISSION_ERROR } from '../actions/formActions';
import { hashmapToArray } from '../utils/hashMapUtils';
import browserHistory from '../history';
import ENV_VARS from '../utils/envVars';

export default function* globalSagas() {
  yield all([showAlertSaga(), submissionError()]);
}

function displayAlert(text, title, icon = 'error', nextState, scroll = true, content) {
  return swal({
    title,
    text,
    icon,
    content,
  }).then(() => {
    if (browserHistory !== undefined && nextState !== undefined) {
      browserHistory.push(nextState);
    } else if (scroll) {
      window.scrollTo(0, 0);
    }
  });
}

function* showAlertSaga() {
  while (true) {
    let { text, title, icon, nextState, nextAction, scroll, content } = yield take(SHOW_ALERT);
    if (text === 'There was an error: Failed to fetch') {
      const d = document.createElement('div');
      d.innerHTML = `Something went wrong. If this problem persists, please reach out to Macrostax support at <a href="mailto:support@macrostax.com">support@macrostax.com</a> or chat directly with our team via the <a href="${ENV_VARS.STAXCHAT_DEEP_LINK}" target="_blank">StaxChat messenger</a> on our website.`;
      content = d;
      text = '';
    }
    yield call(displayAlert, text, title, icon, nextState, scroll, content);
    if (nextAction) {
      yield put(nextAction);
    }
  }
}

function* submissionError() {
  while (true) {
    const { errors, scroll } = yield take(SUBMISSION_ERROR);
    const errs = hashmapToArray(errors);
    yield put(
      showAlert('Oops!', errs.join('\n'), 'warning', undefined, undefined, undefined, scroll)
    );
  }
}
