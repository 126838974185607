import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import renderInputForField from '../helpers/renderInputForField';
import { formatPhone } from '../../utils/stringUtils';

class PhoneField extends Component {
  render() {
    const { fieldName = 'phone', label = 'Phone', inputCaption } = this.props;
    return (
      <Field
        name={fieldName}
        component={renderInputForField}
        label={label}
        format={formatPhone}
        inputCaption={inputCaption}
        stackedlabel
        {...{
          inputProps: {
            type: 'tel',
            id: fieldName,
          },
          itemProps: {},
        }}
      />
    );
  }
}

PhoneField.propTypes = {
  fieldName: PropTypes.string,
  label: PropTypes.string,
  inputCaption: PropTypes.string,
};

export default PhoneField;
