export const Roles = {
  TEAM_OWNER: 'team_owner',
  TEAM_ADMIN: 'team_admin',
  INTERNAL_ADMIN: 'internal_admin',
  INTERNAL_RECIPE_ADMIN: 'internal_recipe_admin',
};

export const adminRoleOptions = [
  {
    label: 'Admin',
    value: 'team_admin',
  },
  {
    label: 'Owner',
    value: 'team_owner',
  },
];

export const internalAdminRoleOptions = [
  {
    label: 'Admin',
    value: 'internal_admin',
  },
  {
    label: 'Recipe Admin',
    value: 'internal_recipe_admin',
  },
];
