import { gql } from '@apollo/client';

export default gql`
  query userProfileState {
    userProfileState {
      goal {
        uuid
        label
        description
        moreInfo
        order
      }
    }
  }
`;
