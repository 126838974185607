export const SUBMIT_FEEDBACK = 'SUBMIT_FEEDBACK';
// internal react-final-form
export const SUBMISSION_ERROR = 'SUBMISSION_ERROR';

export function submissionError(errors, scroll) {
  return {
    type: SUBMISSION_ERROR,
    errors,
    scroll,
  };
}

export function submitFeedback(values) {
  return {
    type: SUBMIT_FEEDBACK,
    values,
  };
}
