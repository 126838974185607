import { INIT_ROUTE_REQUEST } from '../actions/initActions';
import { AUTH_LOGOUT, AUTH_LOGIN_FAILURE, USER_IS_LOGGED_IN } from '../actions/authActions';
import { SUBMIT_USER_SEARCH_REQUEST, CLEAR_USER_SEARCH_FORM } from '../actions/userActions';
import { SUBMIT_TEAM_SEARCH_REQUEST, CLEAR_TEAM_SEARCH_FORM } from '../actions/teamActions';

const initialContextState = {
  appState: 'initializing',
};

export default (state = initialContextState, action) => {
  switch (action.type) {
    case AUTH_LOGIN_FAILURE: {
      return {
        ...initialContextState,
        initRoute: state.initRoute,
        appState: 'loggedOut',
      };
    }
    case USER_IS_LOGGED_IN: {
      return {
        ...state,
        appState: 'loggedIn',
      };
    }
    case INIT_ROUTE_REQUEST: {
      return {
        ...state,
        initRoute: state.initRoute || action.route,
      };
    }
    case SUBMIT_USER_SEARCH_REQUEST: {
      return {
        ...state,
        userSearchForm: action.values,
      };
    }
    case CLEAR_USER_SEARCH_FORM: {
      return {
        ...state,
        userSearchForm: null,
      };
    }
    case SUBMIT_TEAM_SEARCH_REQUEST: {
      return {
        ...state,
        teamSearchForm: action.values,
      };
    }
    case CLEAR_TEAM_SEARCH_FORM: {
      return {
        ...state,
        teamSearchForm: null,
      };
    }
    case AUTH_LOGOUT: {
      return {
        ...initialContextState,
        appState: 'loggedOut',
      };
    }
    default: {
      return state;
    }
  }
};
