import { gql } from '@apollo/client';

export default gql`
  query validateCoupon($enteredCoupon: String!, $membershipId: String!) {
    validateCoupon(enteredCoupon: $enteredCoupon, membershipId: $membershipId) {
      valid
      finalCost
      duration
      savings
      name
      id
    }
  }
`;
