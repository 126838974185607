import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import Loading from '../../components/Common/Loading';
import Testimonial from '../../components/Signup/Testimonial';
import { submissionError } from '../../actions/formActions';
import { signUpPlan, setSignUpStep } from '../../actions/signupActions';
import { userProfileState } from '../../apiActions/signupApiActions';
import { lowerCaseToCapitalizeEachWord } from '../../utils/stringUtils';
import browserHistory from '../../history';

class SignUpPlan extends React.Component {
  componentDidMount() {
    const { signup, doSetSignupStep, doUserProfileState } = this.props;
    if (!signup || !signup.first_name) {
      browserHistory.push('/signup');
    } else {
      if (!signup.userProfileState) {
        doUserProfileState();
      }
      doSetSignupStep(8);
    }
  }

  render() {
    const { doSignUpPlan, signup } = this.props;
    let userProfileState;
    let goal;
    let testimonial;
    if (signup && signup.userProfileState) {
      ({
        testimonial,
        userProfileState: { goal },
      } = signup);
    }

    const descriptions = {
      'fat loss':
        'We recommend the Fat Loss plan to help you reach your goals! This plan features progressive phases to help you lose fat and achieve a leaner look.',
      'muscle gain':
        'We recommend the Muscle Gain plan to help you reach your goals! This plan features progressive phases to help you increase lean mass sustainably over time.',
      maintenance:
        'We recommend the Maintenance plan to help you reach your goals! This plan will help you maintain your weight while slowly altering body composition.',
      'reverse dieting':
        'We recommend the Reverse Dieting plan to help you reach your goals! This plan will help you successfully transition from a more restrictive diet to Macrostax macros.',
    };

    return (
      <ContentWrapper>
        <Row>
          <Col
            md={12}
            className="d-flex flex-column justify-content-center align-items-center mb-5-md text-center"
          >
            <h3>Your Plan</h3>
            {goal ? <h1>{lowerCaseToCapitalizeEachWord(goal.label)}</h1> : <Loading />}
          </Col>
        </Row>
        {goal && (
          <Row>
            <Col xs={12} md={6} className="d-flex flex-column align-items-center mb-3">
              <div className="signup-form-wrapper">
                <Row>
                  <Col className="d-flex align-items-center flex-column">
                    <h3>{descriptions[goal.label]}</h3>
                    <div className="d-block d-md-none mt-3">
                      {testimonial && <Testimonial testimonial={testimonial} />}
                    </div>
                  </Col>
                </Row>
                <Row className="margin-top-40">
                  <Col className="d-flex">
                    <Button color="primary" size="lg" onClick={() => doSignUpPlan()} block>
                      Next
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6} className="d-flex flex-column align-items-center">
              <div className=" d-none d-md-block">
                {testimonial && <Testimonial testimonial={testimonial} />}
              </div>
            </Col>
          </Row>
        )}
      </ContentWrapper>
    );
  }
}

SignUpPlan.propTypes = {
  showSubmissionError: PropTypes.func,
  doSignUpPlan: PropTypes.func,
  doLogout: PropTypes.func,
  signup: PropTypes.instanceOf(Object),
  doSetSignupStep: PropTypes.func,
  doUserProfileState: PropTypes.func,
};
const mapStateToProps = (state) => ({
  signup: state.signup,
});
const mapDispatchToProps = (dispatch) => ({
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
  doSignUpPlan: () => dispatch(signUpPlan()),
  doSetSignupStep: (step) => dispatch(setSignUpStep(step)),
  doUserProfileState: () => dispatch(userProfileState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpPlan);
