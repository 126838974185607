import React, { Component, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import HelperTextIcon from '../../components/Common/HelperTextIcon';

export class RenderToggleButtonsForFieldComponent extends Component {
  render() {
    const {
      input,
      label,
      options,
      meta: { error },
      itemProps,
      required,
      helperText,
    } = this.props;

    const { name, onChange, onBlur, onFocus } = input;

    let inputClassName = 'col-form-label';

    if (itemProps.row) {
      inputClassName += ' col-xl-9';
    }

    const showLabel = () => {
      if (label && label.length) {
        return (
          <div className="d-flex flex-row align-items-center label-container">
            <label>
              {required && <span className="required-field">*</span>}
              {label}
            </label>
            {helperText && <HelperTextIcon helperText={helperText} />}
          </div>
        );
      }
      return null;
    };

    const inputValue = input.value;
    const checkboxes = options.map((option) => {
      const handleChange = (e) => {
        onBlur(e.target.value);
        return onChange(e.target.value);
      };
      const checked = inputValue === option.value;

      return (
        <label key={option.value} className={`btn btn-secondary ${checked ? 'active' : ''}`}>
          <Input
            type="radio"
            name={name}
            autoComplete="off"
            value={option.value}
            defaultChecked={checked}
            onChange={handleChange}
          />{' '}
          {option.title}
        </label>
      );
    });

    return (
      <div
        {...itemProps}
        className="stacked centered form-group d-flex flex-column justify-content-center  plan-selection-header"
      >
        {showLabel()}
        <div className="btn-group btn-group-toggle">{checkboxes}</div>
      </div>
    );
  }
}

RenderToggleButtonsForFieldComponent.propTypes = {
  input: PropTypes.shape({
    checked: PropTypes.bool,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.any,
    onSubmitEditing: PropTypes.func,
  }),
  label: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  inputCaption: PropTypes.string,
  iconName: PropTypes.string,
  iconType: PropTypes.string,
  refField: PropTypes.string,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  meta: PropTypes.shape({
    active: PropTypes.bool,
    autofilled: PropTypes.bool,
    asyncValidating: PropTypes.bool,
    dirty: PropTypes.bool,
    dispatch: PropTypes.func,
    error: PropTypes.string,
    form: PropTypes.string,
    initial: PropTypes.any,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    submitFailed: PropTypes.bool,
    touched: PropTypes.bool,
    valid: PropTypes.bool,
    visited: PropTypes.bool,
    warning: PropTypes.string,
  }),
  children: PropTypes.instanceOf(Array),
  itemProps: PropTypes.shape({
    floatingLabel: PropTypes.bool,
    stackedLabel: PropTypes.bool,
    row: PropTypes.bool,
    cols: PropTypes.number,
    last: PropTypes.bool,
  }),
  inputProps: PropTypes.shape({
    options: PropTypes.instanceOf(Array),
    multi: PropTypes.bool,
    simpleValue: PropTypes.bool,
  }),
};

export default forwardRef((props, ref) => (
  <RenderToggleButtonsForFieldComponent innerRef={ref} {...props} />
));
