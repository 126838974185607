import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, ModalBody, Row, Col, Button } from 'reactstrap';

class TeamLearnMoreModal extends Component {
  render() {
    const { toggle, isOpen } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className="modal-medium modal-centered helper-text-modal"
        centered
        backdrop="static"
      >
        <ModalBody>
          <Row>
            <Col xs={12}>
              <h3>What information can my coach see?</h3>
              <div className="mt-4">
                <p>
                  Once you join Macrostax Team, your coach will have visibility into some of your
                  data in order to best support you on your macro counting journey. A list of data
                  they will be able to see includes:
                </p>

                <ul>
                  <li>Your contact information</li>
                  <li>Your profile data (height, weight, age, goal weight, gender, etc)</li>
                  <li>Your macro settings (goal, phase, activity level) and macro targets</li>
                  <li>Your food preferences</li>
                  <li>Your planner, including the food that you log</li>
                  <li>Your progress data (weights and measurements)</li>
                  <li>When you last logged into the app</li>
                </ul>

                <p>The data your coach will NOT be able to see includes:</p>

                <ul>
                  <li>Your custom foods and recipes</li>
                  <li>Your favorite foods and recipes</li>
                  <li>Any conversations you may have with StaxChat</li>
                  <li>Your app login information</li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="mt-4 d-flex justify-content-end">
              <Button color="primary" size="lg" onClick={toggle} className="fixed-width-md">
                Got It
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

TeamLearnMoreModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TeamLearnMoreModal);
