import React from 'react';
import { Row, Col, Button, Card, CardHeader, Collapse, CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import numeral from 'numeral';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import Loading from '../../components/Common/Loading';
import { submissionError } from '../../actions/formActions';
import { formatCentsAsCurrency } from '../../utils/numberUtils';
import { capitalizeFirst } from '../../utils/stringUtils';
import { signUpMembership, setSignUpStep } from '../../actions/signupActions';
import { membershipOptions } from '../../apiActions/signupApiActions';
import browserHistory from '../../history';
import faqs from '../../data/faq';

class SignUpMemberships extends React.Component {
  constructor(props) {
    super(props);
    const { signup } = this.props;
    const selectedMembership =
      signup && signup.membership
        ? signup.membership
        : signup && signup.membershipOptions
        ? signup.membershipOptions.find((s) => s.featured === 'true')
        : undefined;
    this.state = {
      accordionState: faqs.map(() => false),
      selectedMembership,
    };
  }

  componentDidMount() {
    const { signup, doSetSignupStep, doMembershipOptions } = this.props;
    if (!signup || !signup.first_name) {
      browserHistory.push('/signup');
    } else {
      if (!membershipOptions) {
        doMembershipOptions();
      }
      doSetSignupStep(10);
    }
  }

  setSelectedMembership = (membership) => {
    this.setState({
      selectedMembership: membership,
    });
  };

  /* id is the index in the accordionState array */
  toggleAccordion = (id) => {
    let accordionState = this.state.accordionState.map((val, i) => {
      return id === i ? !val : false;
    });
    this.setState({
      accordionState,
    });
  };

  render() {
    const { doSignUpMembership, signup } = this.props;
    let { selectedMembership } = this.state;
    let membershipOptions;

    if (signup && signup.membershipOptions) {
      ({ membershipOptions } = signup);
      if (!selectedMembership) {
        selectedMembership = membershipOptions.find((s) => s.featured === 'true');
      }
    }

    return (
      <ContentWrapper>
        <Row>
          <Col md={3} />
          <Col
            md={6}
            className="d-flex flex-column justify-content-center align-items-center mb-5-md text-center plan-selection-header"
          >
            <h1>Select a Membership</h1>
          </Col>
        </Row>
        {membershipOptions ? (
          <>
            <Row>
              <Col lg={1} />
              <Col md={6}>
                {membershipOptions.map((s, idx) => (
                  <div
                    key={s.id}
                    className={`subscription-container signup ${
                      s.featured === 'true' && 'featured'
                    } ${selectedMembership === s && 'selected'}`}
                    onClick={() => this.setSelectedMembership(s)}
                  >
                    {s.featured === 'true' && <div className="featured-callout">{s.callout}</div>}
                    <div className="ml-2 mr-2">
                      <div className="d-flex flex-row">
                        <h3>
                          {s.nameShort} - {numeral(s.fullPrice).format('$0.00')}
                        </h3>
                        <span className="interval ml-1">/{s.billingFrequency === 'month' ? 'Mo' : 'Yr'}</span>
                      </div>
                      <div className="tagline">{capitalizeFirst(s.tagline)}</div>
                      {s.secondaryCallout && s.secondaryCallout.length && (
                        <div className="secondaryCallout">{s.secondaryCallout}</div>
                      )}
                    </div>
                  </div>
                ))}
                <div className="caption text-center">
                  By continuing, you agree to the Macrostax{' '}
                  <a href="https://www.macrostax.com/terms" target="_blank" className="text-nowrap">
                    terms and conditions
                  </a>
                  .<br />
                  You can cancel at any time!
                </div>
                <Button
                  className="mt-4"
                  color="primary"
                  size="lg"
                  onClick={() => doSignUpMembership(selectedMembership)}
                  block
                >
                  Select {selectedMembership.nameShort}
                </Button>
              </Col>
              <Col className="text-center cta-container second-column" md={5}>
                <div className="d-flex align-items-center justify-content-center flex-column pricing-value-container">
                  <h2 className="">Stop feeling hungry<br />without seeing results.</h2>
                  <img src="/img/recipes-screenshot-with-max.png" className="screenshot" />
                </div>
                {/* <div className=" d-flex flex-column faq-container">
                  {faqs.map((f, idx) => (
                    <Card
                      className="card-default mb-2 card-faq"
                      key={f.id}
                      onClick={() => this.toggleAccordion(idx)}
                    >
                      <CardHeader>
                        <h4 className="mb-0">
                          <a className="text-inherit">{f.question}</a>
                        </h4>
                        <em
                          className={`icon-arrow-${
                            this.state.accordionState[idx] ? 'down' : 'right'
                          } mr-2`}
                        />
                      </CardHeader>
                      <Collapse isOpen={this.state.accordionState[idx]}>
                        <CardBody>{f.answer}</CardBody>
                      </Collapse>
                    </Card>
                  ))}
                </div> */}
              </Col>
            </Row>
            {/* <Row className="mt-2 margin-bottom-60">
              <Col md={3} />
              <Col md={6}>
                <div className="caption text-center">
                  By continuing, you agree to the Macrostax{' '}
                  <a href="https://www.macrostax.com/terms" target="_blank" className="text-nowrap">
                    terms and conditions
                  </a>
                  .<br />
                  You can cancel at any time!
                </div>
                <Button
                  className="mt-4"
                  color="primary"
                  size="lg"
                  onClick={() => doSignUpMembership(selectedMembership)}
                  block
                >
                  Select {selectedMembership.nameShort}
                </Button>
              </Col> */}
          </>
        ) : (
          <Loading />
        )}
      </ContentWrapper>
    );
  }
}

SignUpMemberships.propTypes = {
  doSignUpMembership: PropTypes.func,
  signup: PropTypes.instanceOf(Object),
  doSetSignupStep: PropTypes.func,
};
const mapStateToProps = (state) => ({
  signup: state.signup,
});
const mapDispatchToProps = (dispatch) => ({
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
  doSignUpMembership: (membership) => {
    dispatch(signUpMembership(membership));
  },
  doSetSignupStep: (step) => dispatch(setSignUpStep(step)),
  doMembershipOptions: () => dispatch(membershipOptions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpMemberships);
