import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import moment from 'moment-timezone';
import { Row, Col, Button } from 'reactstrap';
import renderInputForField from './helpers/renderInputForField';
import renderDatepickerForField from './helpers/renderDatepickerForField';
import renderToggleButtonsForField from './helpers/renderToggleButtonsForField';
import {
  roundNumber,
  kgsToLbs,
  lbsToKgs,
  cmToIn,
  inToCm,
  oneDecimal,
  calculateInches,
} from '../utils/numberUtils';
import { calculateAge } from '../utils/dateUtils';
import ENV_VARS from '../utils/envVars';

const maxDate = ENV_VARS.ENVIRONMENT === 'development' ? moment().add(-18, 'years').toDate() : new Date();

const validate = (values) => {
  const errors = {};
  if (!values.birthdate) {
    errors.birthdate = 'Date of birth is required.';
  } else {
    const age = calculateAge(values.birthdate);
    if (age < 18 || age > 80) {
      errors.birthdate = 'Sorry, your age is outside of our supported range.';
    }
  }
  if (values.unitsOfMeasure === 'imperial') {
    if (!values.heightFt) {
      errors.heightFt = 'Height is required.';
    } else {
      const { heightFt, heightIn = '0' } = values;
      const inches = calculateInches(heightFt, heightIn);
      if (inches < 48 || inches > 96) {
        errors.heightFt = 'The height you entered is outside of our supported range.';
      }
    }
    // we an allow inches to be empty
  } else {
    if (!values.heightCm) {
      errors.heightCm = 'Height is required.';
    } else {
      const inches = cmToIn(values.heightCm);
      if (inches < 48 || inches > 96) {
        errors.heightFt = 'The height you entered is outside of our supported range.';
      }
    }
  }
  if (!values.weight) {
    errors.weight = 'Current weight is required.';
  } else {
    if (values.unitsOfMeasure === 'imperial') {
      if (values.weight < 90 || values.weight > 400) {
        errors.weight = 'The weight you entered is outside of our supported range.';
      }
    } else {
      if (kgsToLbs(values.weight) < 90 || kgsToLbs(values.weight) > 400) {
        errors.weight = 'The weight you entered is outside of our supported range.';
      }
    }
  }
  if (!values.goal_weight) {
    errors.goal_weight = 'Goal weight is required.';
  } else {
    if (values.unitsOfMeasure === 'imperial') {
      if (values.goal_weight < 90 || values.goal_weight > 400) {
        errors.goal_weight = 'The goal weight you entered is outside of our supported range.';
      }
    } else {
      if (kgsToLbs(values.goal_weight) < 90 || kgsToLbs(values.goal_weight) > 400) {
        errors.goal_weight = 'The goal weight you entered is outside of our supported range.';
      }
    }
  }
  return errors;
};

class SignUpInputsForm extends Component {
  constructor(props) {
    super(props);
    const {
      initialValues: { unitsOfMeasure },
    } = props;
    this.state = {
      unitsOfMeasure,
    };
  }

  handleChangeUnits = (unitsOfMeasure, change, values) => {
    if (unitsOfMeasure === 'imperial') {
      if (values.weight) {
        change('weight', kgsToLbs(values.weight));
      }
      if (values.goal_weight) {
        change('goal_weight', kgsToLbs(values.goal_weight));
      }
      if (values.heightCm) {
        const inches = cmToIn(values.heightCm);
        const feet = Math.floor(inches / 12);
        const inchesRemain = inches - feet * 12;
        change('heightFt', feet);
        change('heightIn', inchesRemain);
      }
    } else {
      if (values.weight) {
        change('weight', lbsToKgs(values.weight));
      }
      if (values.goal_weight) {
        change('goal_weight', lbsToKgs(values.goal_weight));
      }
      if (values.heightFt || values.heightIn) {
        const { heightFt = 0, heightIn = 0 } = values;
        const inches = calculateInches(heightFt, heightIn);
        change('heightCm', inToCm(inches));
      }
    }
    this.setState({
      unitsOfMeasure,
    });
  };

  render() {
    const { initialValues, onSubmit, showSubmissionError, systemName } = this.props;
    const { unitsOfMeasure } = this.state;
    const genderButtons = [
      {
        title: 'Female',
        value: 'female',
      },
      {
        title: 'Male',
        value: 'male',
      },
    ];
    const unitsButtons = [
      {
        title: 'Imperial (Ft, Lb)',
        value: 'imperial',
      },
      {
        title: 'Metric (Cm, Kg)',
        value: 'metric',
      },
    ];
    return (
      <div>
        <Form
          onSubmit={(values) => {
            const errors = validate(values);
            if (Object.keys(errors).length === 0) {
              onSubmit(values);
            } else {
              showSubmissionError(errors);
            }
          }}
          initialValues={initialValues}
          keepDirtyOnReinitialize
          render={({ handleSubmit, form, values }) => (
            <form onSubmit={handleSubmit} className="inline-form">
              <Row>
                <Col>
                  <Field
                    name="gender"
                    id="gender"
                    component={renderToggleButtonsForField}
                    options={genderButtons}
                    label="Gender"
                    helperText="GENDER"
                    {...{
                      itemProps: {},
                      inputProps: {},
                    }}
                  />
                  <Field
                    name="birthdate"
                    id="birthdate"
                    component={renderDatepickerForField}
                    label="Date of Birth"
                    className="text-left"
                    systemName={systemName}
                    {...{
                      itemProps: {},
                      inputProps: {
                        // maxDate: moment().add(-18, 'years').toDate(),
                        maxDate,
                        minDate: moment().add(-80, 'years').toDate(),
                      },
                    }}
                  />
                  <Field
                    name="unitsOfMeasure"
                    id="unitsOfMeasure"
                    component={renderToggleButtonsForField}
                    options={unitsButtons}
                    label="Preferred Units of Measure"
                    {...{
                      itemProps: {},
                      inputProps: {},
                    }}
                  />
                  <OnChange name="unitsOfMeasure">
                    {(value) => {
                      this.handleChangeUnits(value, form.change, values);
                    }}
                  </OnChange>
                </Col>
              </Row>
              {unitsOfMeasure === 'imperial' ? (
                <Row>
                  <Col>
                    <Field
                      name="heightFt"
                      id="heightFt"
                      label="Height"
                      format={roundNumber}
                      component={renderInputForField}
                      postInputCaption="Ft"
                      {...{
                        itemProps: {},
                        inputProps: {
                          type: 'number',
                        },
                      }}
                    />
                  </Col>
                  <Col>
                    <Field
                      name="heightIn"
                      id="heightIn"
                      label="Height"
                      format={roundNumber}
                      labelClassName="transparent"
                      component={renderInputForField}
                      postInputCaption="In"
                      {...{
                        itemProps: {},
                        inputProps: {
                          type: 'number',
                          step: 'any',
                        },
                      }}
                    />
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col xs={6}>
                    <Field
                      name="heightCm"
                      id="heightCm"
                      label="Height"
                      format={oneDecimal}
                      component={renderInputForField}
                      postInputCaption="Cm"
                      {...{
                        itemProps: {},
                        inputProps: {
                          type: 'number',
                          step: 'any',
                        },
                      }}
                    />
                  </Col>
                </Row>
              )}

              <Row>
                <Col>
                  <Field
                    name="weight"
                    id="weight"
                    label="Current Weight"
                    component={renderInputForField}
                    format={oneDecimal}
                    helperText="CURRENT_WEIGHT"
                    postInputCaption={unitsOfMeasure === 'imperial' ? 'Lb' : 'Kg'}
                    inputCaption="We use this to guide your custom macro plan"
                    {...{
                      itemProps: {},
                      inputProps: {
                        type: 'number',
                        step: 'any',
                      },
                    }}
                  />
                </Col>
                <Col>
                  <Field
                    name="goal_weight"
                    id="goal_weight"
                    label="Goal Weight"
                    component={renderInputForField}
                    format={oneDecimal}
                    postInputCaption={unitsOfMeasure === 'imperial' ? 'Lb' : 'Kg'}
                    {...{
                      itemProps: {},
                      inputProps: {
                        type: 'number',
                        step: 'any',
                      },
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col className="d-flex">
                  <Button color="primary" size="lg" type="submit" block>
                    Next
                  </Button>
                </Col>
              </Row>
            </form>
          )}
        />
      </div>
    );
  }
}

SignUpInputsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object),
  showSubmissionError: PropTypes.func,
  systemName: PropTypes.string,
};

export default SignUpInputsForm;
