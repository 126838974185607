import React, { Component } from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { isTeamUser, userHasRole } from '../../utils/authUtils';
import { getTimeUntil } from '../../utils/dateUtils';
import { Roles } from '../../enums/roles';
import browserHistory from '../../history';

class AlertBar extends Component {
  goToBilling = () => {
    browserHistory.push('/billing');
  };

  render() {
    const { team = {}, user = {} } = this.props;
    const { roles } = user;
    const { subscription = {} } = team;
    const { status, current_period_end } = subscription;
    return (
      <>
        {/* {status === 'trialing' && userHasRole(user, Roles.TEAM_OWNER) && (
          <div className="alert-bar gray-100">
            Your free trial of Macrostax Team expires in{' '}
            <strong>{getTimeUntil(current_period_end, 'days')} days</strong>
            <Button className="ml-3" onClick={this.goToBilling} color="primary">
              Activate Now
            </Button>
          </div>
        )} */}
        {status === 'canceling' && userHasRole(user, Roles.TEAM_OWNER) && (
          <div className="alert-bar animated">
            Your Macrostax Team subscription has been canceled and will end on{' '}
            {moment(current_period_end).format('MMMM Do, YYYY')}
            <Button className="ml-3" onClick={this.goToBilling}>
              Re-activate Now
            </Button>
          </div>
        )}
      </>
    );
  }
}

AlertBar.propTypes = {
  team: PropTypes.instanceOf(Object),
  user: PropTypes.instanceOf(Object),
};

export default AlertBar;
