import jwtDecode from 'jwt-decode';

const getTokenData = (token) => {
  try {
    const decoded = jwtDecode(token);
    return decoded;
  } catch (e) {
    return undefined;
  }
};

const getTimeUntilTokenExpired = (token) => {
  if (!token) {
    return 0;
  }
  const decoded = getTokenData(token);
  const exp = decoded.exp * 1000;
  const msLeft = exp - Date.now();
  return msLeft < 0 ? 0 : msLeft;
};

const getTokenFromResponse = (response) => {
  const { token, refresh_token } = response;
  return { token, refresh: refresh_token };
};

export { getTokenData, getTimeUntilTokenExpired, getTokenFromResponse };
