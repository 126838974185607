import { AUTH_TOKEN_STORED, AUTH_USER_ID_RETRIEVED } from '../actions/authActions';
import { SET_DEVICE_INFO } from '../actions/deviceActions';
import {
  SIGN_UP,
  SIGN_UP_INPUTS,
  SIGN_UP_GOAL,
  SIGN_UP_DIET,
  SIGN_UP_WORKOUTS,
  SIGN_UP_LIFESTYLE,
  SIGN_UP_ACTIVITY_LEVEL,
  SIGN_UP_PLAN,
  SIGN_UP_MEMBERSHIP,
  SIGN_UP_BILLING,
  SIGN_UP_TEAM,
  SET_SIGN_UP_STEP,
  CLEAR_USER_PROFILE_STATE,
  CLEAR_ACTIVITY_LEVEL,
  CLEAR_TESTIMONIAL,
  CLEAR_COUPON,
} from '../actions/signupActions';

import {
  USER_PROFILE_STATE_SUCCESS,
  ACTIVITY_LEVEL_SUGGESTION_SUCCESS,
  TESTIMONIAL_SUCCESS,
  MEMBERSHIP_OPTIONS_SUCCESS,
  VALIDATE_COUPON_SUCCESS,
  SUBMIT_NEW_MEMBERSHIP_PAYMENT_SUCCESS,
} from '../apiActions/signupApiActions';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTH_TOKEN_STORED: {
      const { is_team_user, team_name, userId } = action;
      return {
        ...state,
        is_team_user,
        team_name,
        user_id: userId,
      }
    }
    case SIGN_UP:
    case SIGN_UP_INPUTS:
    case SIGN_UP_GOAL:
    case SIGN_UP_DIET:
    case SIGN_UP_LIFESTYLE: {
      const { values } = action;
      return {
        ...state,
        ...values,
      };
    }
    case SIGN_UP_WORKOUTS: {
      const { hours } = action;
      return {
        ...state,
        hours,
      };
    }
    case SIGN_UP_ACTIVITY_LEVEL: {
      const { activityLevelId } = action;
      return {
        ...state,
        activityLevelId,
      };
    }
    case SIGN_UP_PLAN: {
      const { planId } = action;
      return {
        ...state,
        planId,
      };
    }
    case SIGN_UP_MEMBERSHIP: {
      const { membership } = action;
      return {
        ...state,
        membership,
      };
    }
    case SIGN_UP_BILLING: {
      const { values, coupon } = action;
      return {
        ...state,
        payment: {
          ...values,
          coupon,
        },
      };
    }
    // TODO: hard-coded. Need to listen for response from new "activate" endpoint
    case SIGN_UP_TEAM: {
      return {
        ...state,
        membership: {},
        orderConfirmation: { startDate: new Date() },
        payment: {
          coupon: '',
        }
      }
    }
    case SET_SIGN_UP_STEP: {
      const { step } = action;
      return {
        ...state,
        step,
      };
    }
    case ACTIVITY_LEVEL_SUGGESTION_SUCCESS: {
      const {
        data: { activityLevelSuggestion },
      } = action;
      return {
        ...state,
        activityLevel: {
          ...activityLevelSuggestion,
        },
      };
    }
    case USER_PROFILE_STATE_SUCCESS: {
      const {
        data: { userProfileState },
      } = action;
      return {
        ...state,
        userProfileState,
      };
    }
    case TESTIMONIAL_SUCCESS: {
      const {
        data: { testimonial },
      } = action;
      return {
        ...state,
        testimonial,
      };
    }
    case CLEAR_ACTIVITY_LEVEL: {
      return {
        ...state,
        activityLevel: undefined,
      };
    }
    case CLEAR_USER_PROFILE_STATE: {
      return {
        ...state,
        userProfileState: undefined,
      };
    }
    case CLEAR_TESTIMONIAL: {
      return {
        ...state,
        testimonial: undefined,
      };
    }
    case CLEAR_COUPON: {
      return {
        ...state,
        validatedCoupon: undefined,
      };
    }
    case MEMBERSHIP_OPTIONS_SUCCESS: {
      const {
        data: { membershipOptions },
      } = action;
      return {
        ...state,
        membershipOptions,
      };
    }
    case VALIDATE_COUPON_SUCCESS: {
      const {
        data: { validateCoupon },
      } = action;
      return {
        ...state,
        validatedCoupon: validateCoupon,
      };
    }
    case SUBMIT_NEW_MEMBERSHIP_PAYMENT_SUCCESS: {
      const {
        data: { submitNewMembershipPayment },
      } = action;
      return {
        ...state,
        orderConfirmation: {
          ...submitNewMembershipPayment,
          /*
          {
      __typename: 'OrderConfirmation',
      coupon: 'MACROSTAX20',
      membershipOption: {
        __typename: 'MembershipOption',
        billingFrequency: 'year',
        callout: 'Most Popular',
        featured: 'true',
        fullPrice: '119.88',
        id: '78d31d01-4a6b-4eeb-bfe6-0fb066cfe0aa',
        nameLong: 'Macrostax Yearly',
        nameShort: 'Yearly',
        pricePerMonth: '9.99',
        tagline: '*Billed every 12 months = $2 per week'
      },
      newTotal: '95.90',
      startDate: '2022-04-11'
  }
} 
*/
        },
      };
    }
    case SET_DEVICE_INFO: {
      const { deviceInfo } = action;
      return {
        ...state,
        device: deviceInfo,
      }
    }
    default:
      return state;
  }
};
