export const GET_TEAM = 'GET_TEAM';
export const UPDATE_TEAM = 'UPDATE_TEAM';
export const REMOVE_MEMBERSHIPS = 'REMOVE_MEMBERSHIPS';
export const CHANGE_PLAN = 'CHANGE_PLAN';
export const CANCEL_PLAN = 'CANCEL_PLAN';
export const SUBMIT_TEAM_SEARCH_REQUEST = 'SUBMIT_TEAM_SEARCH_REQUEST';
export const CLEAR_TEAM_SEARCH_FORM = 'CLEAR_TEAM_SEARCH_FORM';
export const UPDATE_CREDIT_CARD = 'UPDATE_CREDIT_CARD';
export const ADD_CLIENTS = 'ADD_CLIENTS';
export const SEND_INVITATION = 'SEND_INVITATION';
export const SEND_INVITATIONS = 'SEND_INVITATIONS';
export const GET_PENDING_CLIENTS = 'GET_PENDING_CLIENTS';
export const CLEAR_PENDING_CLIENTS = 'CLEAR_PENDING_CLIENTS';

export function getTeam(team_id) {
  return {
    type: GET_TEAM,
    team_id,
  };
}

export function updateTeam(id, payload) {
  return {
    type: UPDATE_TEAM,
    id,
    payload,
  };
}

export function removeMemberships(users) {
  return {
    type: REMOVE_MEMBERSHIPS,
    users,
  };
}

export function updateCreditCard(id, values) {
  return {
    type: UPDATE_CREDIT_CARD,
    id,
    values,
  };
}

export function changePlan(id, priceId) {
  return {
    type: CHANGE_PLAN,
    id,
    priceId,
  };
}

export function cancelPlan(id) {
  return {
    type: CANCEL_PLAN,
    id,
  };
}

export function submitTeamSearchRequest(values) {
  return {
    type: SUBMIT_TEAM_SEARCH_REQUEST,
    values,
  };
}

export function clearTeamSearchForm() {
  return {
    type: CLEAR_TEAM_SEARCH_FORM,
  };
}

export function addClients(values) {
  return {
    type: ADD_CLIENTS,
    values,
  };
}

export function sendInvitations(values) {
  return {
    type: SEND_INVITATIONS,
    values,
  };
}

export function sendInvitation(user) {
  return {
    type: SEND_INVITATION,
    user,
  };
}

export function getPendingClients() {
  return {
    type: GET_PENDING_CLIENTS,
  };
}

export function clearPendingClients() {
  return {
    type: CLEAR_PENDING_CLIENTS,
  };
}
