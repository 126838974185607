import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Row, Col, Button } from 'reactstrap';
import numeral from 'numeral';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form, Field } from 'react-final-form';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { STRIPE_ELEMENT_STYLE_PROPS } from '../../constants';
import renderInputForField from '../helpers/renderInputForField';
import { capitalizeFirst, formatUppercase } from '../../utils/stringUtils';
import { formatCentsAsCurrency } from '../../utils/numberUtils';

const validate = async (values, stripe, elements) => {
  const errors = {};
  if (!values.nameOnCard) {
    errors.first_name = 'Name on card is required';
  }
  const { error, paymentMethod } = await stripe.createPaymentMethod({
    type: 'card',
    card: elements.getElement(CardNumberElement),
  });
  if (error) {
    errors.card = error.message;
  }

  return {
    errors,
    paymentMethod,
  };
};

class CreditCardFormPartial extends Component {
  constructor(props) {
    super(props);
    const { signup } = props;
    const coupon = signup && signup.validatedCoupon ? signup.validatedCoupon : undefined;
    this.state = {
      coupon,
    };
  }

  // handleSubmit = async (event, values) => {
  //   event.preventDefault();
  //   const { stripe, elements, showSubmissionError, onSubmit } = this.props;
  //   const { error, paymentMethod } = await stripe.createPaymentMethod({
  //     type: 'card',
  //     card: elements.getElement(CardNumberElement),
  //   });
  //   const errors = validate(values);
  //   if (error || Object.keys(errors).length) {
  //     if (error) {
  //       errors.card = error.message;
  //     }
  //     // showSubmissionError([error.message]);
  //     showSubmissionError(errors);
  //     // todo: dispatch
  //   } else {
  //     onSubmit(paymentMethod);
  //   }
  // };

  applyCoupon = (values, change, c) => {
    const { showSubmissionError, signup, onCoupon, onClearCoupon } = this.props;

    if (c) {
      return this.setState(
        {
          coupon: undefined,
        },
        () => {
          change('coupon', '');
          onClearCoupon();
        }
      );
    }
    if (!values.coupon) {
      return showSubmissionError(['Please enter a coupon to apply']);
    }
    onCoupon(values.coupon);
    // simulate valid response
    // this.setState({
    //   coupon: {
    //     name: values.coupon,
    //     price: price * 0.8,
    //     savings: price * 0.2,
    //     interval,
    //   },
    // }, () => toast('Coupon Applied Successfully!', {
    //     type: 'info',
    //     position: 'top-right',
    //     autoClose: 3000,
    //   })
    //   );

    // TODO: call props.onCoupon()
  };

  render() {
    const {
      onCancel,
      stripe,
      elements,
      showCancel,
      buttonColClass = 'd-flex justify-content-end',
      initialValues,
      showName,
      showCoupon,
      showSubmissionError,
      onSubmit,
      signup,
    } = this.props;
    let { buttonText = 'Submit' } = this.props;
    let { coupon } = this.state;
    let couponDuration;

    if (!coupon && signup && signup.validatedCoupon && signup.validatedCoupon.valid) {
      coupon = signup.validatedCoupon;
    }
    if (coupon) {
      buttonText = `Pay ${numeral(coupon.finalCost).format('$0.00')}`;
      couponDuration =
        coupon.duration === 'repeating'
          ? `per ${signup.membership.billingFrequency}`
          : `for one ${signup.membership.billingFrequency}`;
    }

    return (
      <div className="inline-form">
        <Form
          onSubmit={async (values) => {
            const { errors, paymentMethod } = await validate(values, stripe, elements);
            if (Object.keys(errors).length === 0) {
              values['paymentMethod'] = paymentMethod;
              onSubmit(values, coupon);
            } else {
              showSubmissionError(errors);
            }
          }}
          initialValues={initialValues}
          keepDirtyOnReinitialize
          render={({ handleSubmit, form, values }) => (
            <form onSubmit={handleSubmit} className="inline-form position-relative">
              <ToastContainer />
              {showName && (
                <Field
                  name="nameOnCard"
                  id="nameOnCard"
                  label="Name on Card"
                  format={capitalizeFirst}
                  component={renderInputForField}
                  {...{
                    itemProps: {},
                    inputProps: {
                      type: 'text',
                    },
                  }}
                />
              )}
              <FormGroup className="stacked">
                <label>Card Number</label>
                <div className="stripe-element-container">
                  <CardNumberElement
                    options={STRIPE_ELEMENT_STYLE_PROPS}
                    value="4242424242424242"
                  />
                </div>
              </FormGroup>
              <Row>
                <Col xs={8}>
                  <FormGroup className="stacked">
                    <label>Expiration Date</label>
                    <div className="stripe-element-container">
                      <CardExpiryElement options={STRIPE_ELEMENT_STYLE_PROPS} />
                    </div>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="stacked">
                    <label>CVC</label>
                    <div className="stripe-element-container">
                      <CardCvcElement options={STRIPE_ELEMENT_STYLE_PROPS} />
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              {showCoupon && (
                <Row>
                  <Col xs={9} md={10}>
                    <div className="coupon-container">
                      <Field
                        name="coupon"
                        id="coupon"
                        label="Have a Coupon?"
                        format={formatUppercase}
                        component={renderInputForField}
                        inputClassName={coupon ? 'valid-coupon' : ''}
                        {...{
                          itemProps: {},
                          inputProps: {
                            type: 'text',
                            disabled: coupon !== undefined,
                          },
                        }}
                      />
                      {coupon && <img src="/img/check.svg" className="check" />}
                    </div>
                  </Col>
                  <Col
                    className="d-flex flex-column form-group justify-content-end pb-2 span-link"
                    onClick={() => this.applyCoupon(values, form.change, coupon)}
                  >
                    {coupon ? 'Remove' : 'Apply'}
                  </Col>
                </Row>
              )}
              {coupon && (
                <Row>
                  <Col>
                    <div className="bubble">
                      Coupon applied! Your new price will be{' '}
                      <strong>{numeral(coupon.finalCost).format('$0.00')}</strong> {couponDuration}.
                    </div>
                  </Col>
                </Row>
              )}

              <Row className="mt-5">
                <Col className={buttonColClass}>
                  {showCancel && (
                    <Button
                      color="secondary"
                      size="lg"
                      onClick={onCancel}
                      className="mr-3 fixed-width-md"
                    >
                      Cancel
                    </Button>
                  )}
                  <Button
                    color="primary"
                    size="lg"
                    disabled={!stripe}
                    onClick={this.handleSubmit}
                    className={showCancel ? 'fixed-width-md' : ''}
                    block={!showCancel}
                  >
                    {buttonText}
                  </Button>
                </Col>
              </Row>
            </form>
          )}
        />
      </div>
    );
  }
}

CreditCardFormPartial.propTypes = {
  onCancel: PropTypes.func,
  stripe: PropTypes.instanceOf(Object),
  elements: PropTypes.instanceOf(Object),
  showSubmissionError: PropTypes.func,
  buttonText: PropTypes.string,
  showCancel: PropTypes.bool,
  buttonColClass: PropTypes.string,
  onSubmit: PropTypes.func,
  initialValues: PropTypes.instanceOf(Object),
  showCoupon: PropTypes.bool,
  showName: PropTypes.bool,
  signup: PropTypes.instanceOf(Object),
  onCoupon: PropTypes.func,
  onClearCoupon: PropTypes.func,
};

export default CreditCardFormPartial;
