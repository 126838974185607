import React from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import numeral from 'numeral';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import Loading from '../../components/Common/Loading';
import CreditCardForm from '../../forms/CreditCardForm';
import { formatCentsAsCurrency } from '../../utils/numberUtils';
import { submissionError } from '../../actions/formActions';
import { signUpBilling, setSignUpStep, validateCoupon, clearCoupon } from '../../actions/signupActions';
import browserHistory from '../../history';

class SignUpBilling extends React.Component {
  componentDidMount() {
    const { signup, doSetSignupStep } = this.props;
    if (!signup || !signup.first_name || !signup.membership || !signup.membership.id) {
      browserHistory.push('/signup');
    } else {
      doSetSignupStep(11);
    }
  }

  render() {
    const { showSubmissionError, doSignUpBilling, signup, doValidateCoupon, doClearCoupon } = this.props;
    let membership;
    let first_name;
    if (signup) {
      ({ membership, first_name } = signup);
    }
    return (
      <ContentWrapper>
        <Row>
          <Col md={3} />
          <Col md={6} className="d-flex flex-column align-items-center mb-3">
            <div className="signup-form-wrapper">
              {!membership ? (
                <Loading />
              ) : (
                <>
                  <h1>Payment</h1>
                  <h3>You're so close, {first_name}! Please enter your payment information:</h3>
                  <div className="mt-4 mb-4 inline-form">
                    <div className="stacked">
                      <label>Selected Membership</label>
                      <div>
                        <strong>
                          {membership.nameLong} - {numeral(membership.fullPrice).format('$0.00')}/
                          {membership.billingFrequency}
                        </strong>
                      </div>
                    </div>
                  </div>
                  <CreditCardForm
                    showSubmissionError={showSubmissionError}
                    showCancel={false}
                    showName={true}
                    showCoupon={true}
                    buttonText={`Pay ${numeral(membership.fullPrice).format('$0.00')}`}
                    onSubmit={(values, coupon) => doSignUpBilling(values, coupon)}
                    onCoupon={(coupon) => doValidateCoupon(membership, coupon)}
                    onClearCoupon={doClearCoupon}
                    buttonColClass="d-flex"
                    signup={signup}
                  />
                </>
              )}
            </div>
          </Col>
        </Row>
      </ContentWrapper>
    );
  }
}

SignUpBilling.propTypes = {
  showSubmissionError: PropTypes.func,
  doSignUpBilling: PropTypes.func,
  signup: PropTypes.instanceOf(Object),
  doSetSignupStep: PropTypes.func,
  doValidateCoupon: PropTypes.func,
  doClearCoupon: PropTypes.func,
};
const mapStateToProps = (state) => ({
  signup: state.signup,
});
const mapDispatchToProps = (dispatch) => ({
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
  doSignUpBilling: (values, coupon) => {
    dispatch(signUpBilling(values, coupon));
  },
  doSetSignupStep: (step) => dispatch(setSignUpStep(step)),
  doValidateCoupon: (membership, coupon) => dispatch(validateCoupon(membership, coupon)),
  doClearCoupon: () => dispatch(clearCoupon()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpBilling);
