import ENV_VARS from '../utils/envVars';
import gtm from './googleTagManager';
import googleAnalytics from './googleAnalytics';
import everflow from './everflow';
import activeCampaign from './activeCampaign';
import facebook from './facebook';
import snapchat from './snapchat';
import pinterest from './pinterest';
// import northbeam from './northbeam';
import googleAds from './googleAds';
import tiktok from './tiktok';

const loadScript = (url, isAsync, callback) => {
  const head = document.head;
  const script = document.createElement('script');
  script.type = 'text/javascript';
  if (isAsync) {
    script.async = true;
  }
  script.src = url;
  script.onreadystatechange = callback;
  if (callback) {
    script.onload = callback;
  }
  head.appendChild(script);
};

const loadTrackingScripts = (signup) => {
  // Google Tag Manager
  loadScript(`//www.googletagmanager.com/gtag/js?id=${ENV_VARS.GA_TRACKING_ID}`, true, gtm.init);
  // Everflow (Native ads)
  loadScript('//www.bls29trk.com/scripts/sdk/everflow.js', false, everflow.init);
  // TikTok
  tiktok.init(window, document, 'ttq');
  // Facebook
  facebook.init(signup);

  // Production-only scripts
  if (ENV_VARS.ENVIRONMENT === 'production') {
    // ActiveCampaign
    activeCampaign.init();
    // Snapchat
    snapchat.init(window, document, 'https://sc-static.net/scevent.min.js');
    // Pinterest
    pinterest.init();
    // Northbeam
    // loadScript(`//j.northbeam.io/ota-sp/${ENV_VARS.NORTHBEAM_APP_ID}.js`);
  }
};

const updateTracking = (path, signup) => {
  const { is_team_user = false } = signup;
  if (is_team_user) {
    if (path !== 'account') {
      googleAnalytics.update(`${path}_team`);
    }
  } else {
    // northbeam.update(path);
    facebook.pageView(signup);
    if (path !== 'account') {
      googleAnalytics.update(path);
      tiktok.pageView();
    }
    if (path === 'account') {
      tiktok.viewContent();
      facebook.viewContent(signup);
    }
    if (path === 'inputs') {
      // northbeam.emailCapture(signup.email);
      tiktok.identify(signup.email);
      tiktok.submitForm();
      facebook.lead(signup);
    } else if (path !== 'account') {
      // northbeam.identify(signup.email);
      tiktok.identify(signup.email);
    }
    if (path === 'lifestyle') {
      tiktok.addToWishlist();
      facebook.customizeProduct(signup);
    }
    if (path === 'billing') {
      tiktok.addToCart(signup);
      facebook.addToCart(signup);
    }
  }
  
};

const checkout = (signup) => {
  googleAnalytics.checkout(signup);
  googleAds.checkout(signup);
  everflow.checkout();
  tiktok.checkout(signup);
  facebook.checkout(signup);

  if (ENV_VARS.ENVIRONMENT === 'production') {
    // facebook.checkout(signup);
    snapchat.checkout(signup);
    pinterest.checkout(signup);
    // northbeam.checkout(signup);
  }
};

export default {
  loadTrackingScripts,
  updateTracking,
  checkout,
};
