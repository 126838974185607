import { call, put, all, take, select } from 'redux-saga/effects';
import {
  initializeUsername,
  INIT_USERNAME_REQUEST,
  initializeTeamProfile,
} from '../actions/initActions';
import { getTeam } from '../actions/teamActions';
import {
  getTeamProfile,
  GET_TEAM_SUCCESS,
  TEAM_PROFILE_SUCCESS,
} from '../apiActions/teamApiActions';
import {
  AUTH_USER_ID_RETRIEVED,
  AUTH_TOKEN_STORED,
  AUTH_LOGIN_REQUEST,
  beginAuthentication,
} from '../actions/authActions';
import { AUTH_USER_PROFILE_SUCCESS } from '../apiActions/userApiActions';
import { USER_IS_LOGGED_IN } from '../actions/authActions';
import { spinnerDecrement } from '../actions/spinnerActions';
import { setDeviceInfo } from '../actions/deviceActions';
import { getDeviceInfo } from '../utils/global';
import {
  INIT_EMAIL_KEY,
  getLocalStoreValue,
  setLocalStoreValue,
  USER_ID_KEY,
} from '../utils/localStorage';
import { getInitialRouteForUser } from '../utils/authUtils';
import browserHistory from '../history';

export default function* initSagas() {
  yield all([getInitialUsernameSaga(), getUserDataSaga(), moveToMainSaga(), setDeviceSaga()]);
}

function* setDeviceSaga() {
  const deviceInfo = {
    ...getDeviceInfo(),
    appName: 'Macrostax V2 Web Signup Flow',
  };
  yield put(setDeviceInfo(deviceInfo));
}

function* getInitialUsernameSaga() {
  // this gets called immediately on app load. See if we have an email
  // in localstorage and if so, this will kick off the authenticationSaga
  // to log the user in automatically via their jwt
  const username = yield call(getLocalStoreValue, INIT_EMAIL_KEY);
  if (username) {
    yield put(initializeUsername(username));
    yield put(beginAuthentication(username));
  }
}

function* getUserDataSaga() {
  while (true) {
    const { user_id, team_id } = yield take(AUTH_USER_ID_RETRIEVED);
    setLocalStoreValue(USER_ID_KEY, user_id);
    // TODO: if we want to pre-load any additional data this is where we would do it
    // yield put(getTeam(team_id));
    // yield put(getTeamProfile());
  }
}

function* moveToMainSaga() {
  // this waits for all necessary 'loading' actions before
  // moving off interstitial screen
  while (true) {
    yield all([
      // take(AUTH_LOGIN_REQUEST),
      take(AUTH_TOKEN_STORED),
      take(USER_IS_LOGGED_IN),
      take(AUTH_USER_PROFILE_SUCCESS),
      take(GET_TEAM_SUCCESS),
      take(TEAM_PROFILE_SUCCESS),
    ]);
    const deviceInfo = {
      ...getDeviceInfo(),
      appName: 'Macrostax Team Dashboard',
    };
    yield put(setDeviceInfo(deviceInfo));

    const state = yield select();

    if (state.auth && state.auth.passwordUpdateRequired) {
      browserHistory.push('/newPassword');
      yield put(spinnerDecrement());
    } else {
      // const nextRoute = state && state.context && state.context.initRoute ? state.context.initRoute : '/users';
      const nextRoute =
        state && state.context && state.context.initRoute
          ? state.context.initRoute
          : getInitialRouteForUser(state.auth.user);

      browserHistory.push(nextRoute);
    }
  }
}
