export const helperCaptions = {
  GENDER: {
    title: 'Why ask for my gender?',
    description:
      'We realize that gender is not a binary concept, but this information is essential to creating your custom macro plan.',
  },
  DIET: {
    title: 'Why ask about my diet history?',
    description:
      "<p>It’s important for us to know where you’re coming from, so we can get your Macrostax journey started right!</p><p>If you have recently followed a low calorie or restrictive diet we will recommend that you start off in our Reverse Dieting phase, which is designed to help gradually transition you to Macrostax's carb and protein macro targets. We find that Macrostaxers who are coming from a low calorie or restrictive dieting background see the most success with this approach.</p><p>We define “low calorie” as generally around 1,200-1,400 calories or less per day (depending on the person). And by “restrictive diet” we mean diets that cut out multiple food groups or heavily restrict a single macro (protein, carbs, or fat), such as keto.</p>",
  },
  CURRENT_WEIGHT: {
    title: 'What weight range does Macrostax support?',
    description:
      'We have an inclusive nutrition philosophy and our custom macro calculations are designed to support people of all shapes and sizes. However, we also know our limits (we are not doctors)! We recognize that those with a very low or very high starting weights need extra specialized macros and support from a medical professional. Our macro plans are not tailored for anyone who weighs less than 90 lbs or more than 400 lbs.',
  },
  WORKOUTS: {
    title: 'What is considered a workout?',
    description: '<p>Any exercise that requires physical effort and improves your health and fitness can be considered a workout. This may include strength training (such as lifting weights or doing body-weight routines), or endurance activities such as power walking, hiking, running, cycling, swimming laps, dancing, etc. Playing sports such as soccer or tennis, and participating in group fitness classes such as CrossFit, Zumba, power yoga, Barre, or HIIT should also be counted towards your weekly workout hours.</p><p>Your weekly workout hours should include time spent on warmup and cool down activities.</p>',
  },
  LIFESTYLE: {
    title: 'How do I described my "Lifestyle"?',
    description: 'Think of your lifestyle as the activity you engage in during your day-to-day life, outside of dedicated workouts or intentional physical activity. For example: are you always on the go, or do you work a desk job? We ask this question so we can recommend the most accurate Activity Level that reflects your total energy expenditure, to ensure you feel your best 24/7.',
  },
};
