import { SET_DEVICE_INFO } from '../actions/deviceActions';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_DEVICE_INFO: {
      const { deviceInfo } = action;
      return {
        ...state,
        ...deviceInfo,
      };
    }
    default: {
      return state;
    }
  }
};
