import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Row, Col, Button } from 'reactstrap';
import renderPillButtonsForField from './helpers/renderPillButtonsForField';

const validate = (values) => {
  const errors = {};
  if (!values.lifestyle) {
    errors.lifestyle = 'Please answer the question';
  }
  return errors;
};

class SignUpLifestyleForm extends Component {
  render() {
    const { initialValues, onSubmit, showSubmissionError } = this.props;
    const buttons = [
      {
        title: 'Level 1',
        value: '1',
        description: 'You engage in <strong>light activity</strong> throughout the day. You spend most of your time sitting, standing, or walking.',
      },
      {
        title: 'Level 2',
        value: '2',
        description: 'You spend the majority of every day performing <strong>moderate activity</strong>, such as power walking or lifting heavy objects. Your heart rate is almost always raised outside of workouts.',
      },
      {
        title: 'Level 3',
        value: '3',
        description: 'Your day-to-day life requires <strong>strenuous physical activity</strong>. You may perform manual labor, such as working in construction or farming.',
      },
    ];
    return (
      <div>
        <Form
          onSubmit={(values) => {
            const errors = validate(values);
            if (Object.keys(errors).length === 0) {
              onSubmit(values);
            } else {
              showSubmissionError(errors);
            }
          }}
          initialValues={initialValues}
          keepDirtyOnReinitialize
          render={({ handleSubmit, form, values }) => (
            <form onSubmit={handleSubmit} className="inline-form">
              <Row>
                <Col className="d-flex">
                  <Field
                    name="lifestyle"
                    id="lifestyle"
                    component={renderPillButtonsForField}
                    options={buttons}
                    {...{
                      itemProps: {},
                      inputProps: {},
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col className="d-flex">
                  <Button color="primary" size="lg" type="submit" block>
                    Next
                  </Button>
                </Col>
              </Row>
            </form>
          )}
        />
      </div>
    );
  }
}

SignUpLifestyleForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object),
  showSubmissionError: PropTypes.func,
};

export default SignUpLifestyleForm;
