import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import numeral from 'numeral';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import moment from 'moment-timezone';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import Loading from '../../components/Common/Loading';
import { submissionError } from '../../actions/formActions';
import SmsForm from '../../forms/SmsForm';
import { signUpSms, setSignUpStep } from '../../actions/signupActions';
import { formatUppercase } from '../../utils/stringUtils';
import browserHistory from '../../history';

class SignUpApp extends React.Component {
  componentDidMount() {
    const { signup, doSetSignupStep } = this.props;
    if (!signup || !signup.first_name) {
      browserHistory.push('/signup');
    } else {
      doSetSignupStep(12);
    }
  }

  render() {
    const { doSignUpSms, signup, showSubmissionError, device } = this.props;
    let first_name;

    if (signup && signup.first_name) {
      ({ first_name } = signup);
    }
    const isMobile = device && (device.systemName === 'iOS' || device.systemName === 'Android');
    const isIOS = device && device.systemName === 'iOS';
    const isAndroid = device && device.systemName === 'Android';

    return (
      <ContentWrapper>
        {!first_name ? (
          <Loading />
        ) : (
          <>
            <Row>
              <Col
                md={12}
                className="d-flex flex-column justify-content-center align-items-center mb-5-md text-center"
              >
                <h1>Existing Account Found</h1>
                <h3>
                  Hey there, {first_name}! It looks like you already have an account. Would you like
                  to log in instead?
                </h3>
              </Col>
            </Row>
          </>
        )}
        {first_name && (
          <Row className="signup-complete-container">
            <Col md={3} />
            <Col md={3}>
              {!isMobile && (
                <>
                  Scan this QR code:
                  <div className="mt-3">
                    <img src="/img/qr-code.svg" className="qr-code" />
                  </div>
                </>
              )}
              {isIOS && (
                <>
                  Download on the App Store:
                  <div className="mt-3">
                    <a href="https://apps.apple.com/us/app/macrostax-diet-meal-planner/id1568593622">
                      <img src="/img/appstore.png" className="app-store-badge" />
                    </a>
                  </div>
                </>
              )}
              {isAndroid && (
                <>
                  Download on the Google Play Store:
                  <div className="mt-3">
                    <a href="https://play.google.com/store/apps/details?id=com.macrostax.app">
                      <img src="/img/google-play-badge.png" className="app-store-badge" />
                    </a>
                  </div>
                </>
              )}
            </Col>
            <Col md={3} className="second-column">
              Text a link to your phone:
              <div className="mt-3">
                <SmsForm
                  showSubmissionError={showSubmissionError}
                  onSubmit={(values) => doSignUpSms(values)}
                  initialValues={{
                    phone: '',
                  }}
                />
              </div>
            </Col>
          </Row>
        )}
      </ContentWrapper>
    );
  }
}

SignUpApp.propTypes = {
  doSignUpApp: PropTypes.func,
  signup: PropTypes.instanceOf(Object),
  doSetSignupStep: PropTypes.func,
  showSubmissionError: PropTypes.func,
  device: PropTypes.instanceOf(Object),
};
const mapStateToProps = (state) => ({
  signup: state.signup,
  device: state.device,
});
const mapDispatchToProps = (dispatch) => ({
  doSignUpSms: (values) => {
    dispatch(signUpSms(values));
  },
  doSetSignupStep: (step) => dispatch(setSignUpStep(step)),
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpApp);
