import { gql } from '@apollo/client';

export default gql`
  mutation submitNewMembershipPayment($membershipPaymentIn: MembershipPaymentIn!) {
    submitNewMembershipPayment(membershipPaymentIn: $membershipPaymentIn) {
      startDate
      coupon
      membershipOption {
        id
        billingFrequency
        pricePerMonth
        nameLong
        nameShort
        fullPrice
        tagline
        featured
        callout
      }
      newTotal
      membershipId
    }
  }
`;
