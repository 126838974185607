import numeral from 'numeral';

export const formatCurrency = (input, showZero) => {
  let transformedInput = '';
  let number = '';
  if (input !== undefined) {
    number = input.toString().replace(/[^0-9]+/g, '');
  }
  if (number.length) {
    if (number.length > 2) {
      transformedInput += `${number.substring(0, number.length - 2)}.${number.substring(
        number.length - 2,
        number.length
      )}`;
    } else if (number.length === 2) {
      transformedInput += `0.${number.substring(0, 2)}`;
    } else {
      transformedInput += `0.0${number}`;
    }
    transformedInput = numeral(transformedInput).format('$0,0.00');
  } else {
    transformedInput = '';
  }

  if (showZero) {
    // keep as-is
  } else if (transformedInput === '$0.00') {
    transformedInput = '';
  }
  return transformedInput;
};

export const formatCentsAsCurrency = (cents) => numeral(cents / 100).format('$0,000.00');
export const currencyFormatter = (input) => formatCentsAsCurrency(input).replace('$', '');
export const currencyParser = (input) => input.toString().replace(/[^0-9]+/g, '');

export const roundNumber = (input) => input ? numeral(input).format('0') : '';
export const oneDecimal = (input) => input ? numeral(input).format('0.0').replace('.0', '') : '';

export const kgsToLbs = (val) => 2.205 * parseFloat(val);
export const lbsToKgs = (val) => parseFloat(val) / 2.205;

export const cmToIn = (val) => parseFloat(val) / 2.54;
export const inToCm = (val) => 2.54 * parseFloat(val);

export const calculateInches = (feet, inch) => {
  if (inch === "" || inch === undefined) {
    inch = "0";
  }
  return (parseFloat(feet) * 12) + parseFloat(inch);
};