import ENV_VARS from '../utils/envVars';

const init = () => {
  !(function (e) {
    if (!window.pintrk) {
      window.pintrk = function () {
        window.pintrk.queue.push(Array.prototype.slice.call(arguments));
      };
      var n = window.pintrk;
      (n.queue = []), (n.version = '3.0');
      var t = document.createElement('script');
      (t.async = !0), (t.src = e);
      var r = document.getElementsByTagName('script')[0];
      r.parentNode.insertBefore(t, r);
    }
  })('https://s.pinimg.com/ct/core.js');
  window.pintrk('load', `${ENV_VARS.PINTEREST_APP_ID}`);
  window.pintrk('page');
};

const checkout = (signup) => {
  let price = signup.orderConfirmation.newTotal || '0.00';
  price = parseFloat(price);
  
  if (window.pintrk) {
    pintrk('track', 'checkout', {
      currency: 'USD',
      order_quantity: 1,
      value: price
    });
  }
}

export default {
  init,
  checkout,
};
