import ENV_VARS from '../utils/envVars';

const checkout = (signup) => {
  let price = signup.orderConfirmation.newTotal || '0.00';
  price = parseFloat(price);

  window.gtag('event', 'conversion', {
    send_to: `${ENV_VARS.GOOGLE_ADWORDS_CONVERSION_ID}/${ENV_VARS.GOOGLE_ADWORDS_CONVERSION_LABEL}`,
    value: price,
    currency: 'USD',
  });
};

export default {
  checkout,
};
