import React from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import SignUpForm from '../../forms/SignUpForm';
import { submissionError } from '../../actions/formActions';
import { signUp, setSignUpStep, trackFacebookEvent } from '../../actions/signupActions';
import { authLogout } from '../../actions/authActions';
import trackers from '../../trackers';
import ENV_VARS from '../../utils/envVars';

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    const { signup, doTrackFacebookEvent } = this.props;
    trackers.updateTracking('account', signup);
    doTrackFacebookEvent('PageView', '/signup');
    doTrackFacebookEvent('ViewContent', '/signup');
  }

  componentDidMount() {
    const { doLogout, doSetSignupStep } = this.props;
    // doLogout();
    doSetSignupStep(1);
  }

  render() {
    const { showSubmissionError, doSignUp, signup } = this.props;
    const steps = [
      { number: 1, label: 'Get a custom plan.' },
      { number: 2, label: 'Pick a recipe.' },
      { number: 3, label: 'See results.' },
    ];
    return (
      <ContentWrapper>
        <Row>
          <Col
            md={12}
            className="d-flex flex-column justify-content-center align-items-center mb-5-md text-center"
          >
            <h1>Ready to change your life?</h1>
            <h3>
              Macrostax's proven formula will create your perfect custom nutrition plan! Let's set up your account and get started.
            </h3>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="d-flex flex-column align-items-center mb-3">
            <div className="signup-form-wrapper">
              <SignUpForm
                showSubmissionError={showSubmissionError}
                onSubmit={(values) => doSignUp(values)}
                initialValues={{
                  first_name: '',
                  last_name: '',
                  email: '',
                  confirm_email: '',
                  password: '',
                  ...signup,
                }}
              />
              <p className="mt-4 text-center caption">
                Already have an account? <a href={ENV_VARS.LOG_IN_URL}>Log In</a>
              </p>
            </div>
          </Col>
          <Col className="d-flex flex-column align-items-center second-column">
            <div className="signup-callout-container">
              <div className="signup-callout-image">
                <img src="/img/signup/sign-up-step-1.jpg" />
              </div>
              <div className="signup-steps">
                {steps.map((s) => (
                  <div className="signup-step d-flex" key={s.number}>
                    <div className="signup-step-number mr-3">{s.number}</div>
                    <div className="signup-step-label">{s.label}</div>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </ContentWrapper>
    );
  }
}

SignUp.propTypes = {
  showSubmissionError: PropTypes.func,
  doSignUp: PropTypes.func,
  doLogout: PropTypes.func,
  doSetSignupStep: PropTypes.func,
  signup: PropTypes.instanceOf(Object),
  doTrackFacebookEvent: PropTypes.func,
};
const mapStateToProps = (state) => ({
  signup: state.signup,
});
const mapDispatchToProps = (dispatch) => ({
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
  doSignUp: (values) => {
    // values.businessType = makeSimpleValue(values.businessType);
    dispatch(signUp(values));
  },
  doLogout: () => dispatch(authLogout(false)),
  doSetSignupStep: (step) => dispatch(setSignUpStep(step)),
  doTrackFacebookEvent: (type, path) => dispatch(trackFacebookEvent(type, path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
