import ENV_VARS from '../utils/envVars';
import googleAnalytics from './googleAnalytics';

const init = () => {
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  window.gtag = gtag;
  window.gtag('js', new Date());
  window.gtag('config', `${ENV_VARS.GA_TRACKING_ID}`);
  window.gtag('config', `${ENV_VARS.GOOGLE_ADWORDS_CONVERSION_ID}`, { allow_enhanced_conversions: true });
  googleAnalytics.update('account');
};

export default {
  init,
};
