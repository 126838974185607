import ENV_VARS from '../utils/envVars';

const init = (w, d, t) => {
  w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
    )ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
        
  ttq.load(`${ENV_VARS.TIKTOK_APP_ID}`);
  ttq.page();
};

const identify = (email) => {
  ttq.identify({
    email: email,
  });
};

const checkout = (signup) => {
  let price = signup.orderConfirmation.newTotal || '0.00';
  price = parseFloat(price);

  identify(signup.email);

  ttq.track('CompletePayment', { 
    content_id: signup.orderConfirmation.membershipOption.id,
    quantity: 1,
    content_type: 'product',
    price: price,
    value: price,
    currency: 'USD',
  });

};

const addToCart = (signup) => {
  let price = signup.membership.fullPrice || '0.00';
  price = parseFloat(price);

  identify(signup.email);

  ttq.track('AddToCart', {
    content_id: signup.membership.id,
    quantity: 1,
    content_type: 'product',
    price: price,
    value: price,
    currency: 'USD'
  });
};

const viewContent = () => {
  ttq.track('ViewContent', {
    description: 'Signup Flow',
    currency: 'USD'
  });
};

const submitForm = () => {
  ttq.track('SubmitForm');
};

const addToWishlist = () => {
  ttq.track('AddToWishlist');
};

const pageView = () => {
  ttq.page();
}

export default {
  init,
  checkout,
  identify,
  addToCart,
  viewContent,
  submitForm,
  addToWishlist,
  pageView,
};
