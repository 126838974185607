const update = (page) => {
  if (window.gtag) {
    window.gtag('event', 'checkout_progress', {
      event_label: page,
    });
  }
};

const checkout = (signup) => {
  let price = signup.orderConfirmation.newTotal || '0.00';
  price = parseFloat(price);
  if (window.gtag) {
    window.gtag('event', 'purchase', {
      event_label: signup.orderConfirmation.membershipOption.id,
      transaction_id: signup.orderConfirmation.membershipId,
      value: price,
      currency: 'USD',
      tax: 0,
      shipping: 0,
    });
  }
};

export default {
  update,
  checkout,
};
