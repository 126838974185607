import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Testimonial extends Component {
  render() {
    const { testimonial } = this.props;
    return (
      <div className="testimonial-container">
        <div className="testimonial-quote-container">
          <h2 className="mb-3">{testimonial.author}</h2>
          <p>
            <img src="/img/signup/quotation-mark.svg" className="mr-2" />
            {testimonial.quote}
          </p>
        </div>
        <div
          className="testimonial-photo-container"><img src={testimonial.photoUrl} alt={testimonial.author} /></div>
        {/* <div
          className="testimonial-photo-container"
          style={{ backgroundImage: `url(${testimonial.photoUrl})` }}
        ></div> */}
      </div>
    );
  }
}

Testimonial.propTypes = {
  testimonial: PropTypes.instanceOf(Object),
};

export default Testimonial;
