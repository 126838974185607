export default {
  // Local
  BASE_URL: 'https://app-prod.macrostax.com/api/v1',
  STRIPE_PUBLISHABLE_KEY: 'pk_live_ECPNwbD6MiUc3jqz4abqSWjR',
  ENVIRONMENT: 'production',

  // Tracking
  GA_TRACKING_ID: 'G-M00FRR92K8',
  GOOGLE_ADWORDS_CONVERSION_ID: 'AW-831992228',
  ACTIVE_CAMPAIGN_APP_ID: '1000077468',
  FACEBOOK_APP_ID: '1709302249290523',
  SNAPCHAT_APP_ID: '9341541b-1c29-4cb5-a08c-c49ee5a49925',
  PINTEREST_APP_ID: '2616487731752',
  NORTHBEAM_APP_ID: 'd23a80cc-5ecd-4f59-9269-d56a3efd1d42',
  GOOGLE_ADWORDS_CONVERSION_LABEL: 'V7o7CM-wuXcQpOPcjAM',
  TIKTOK_APP_ID: 'CBQL1RJC77U606K89D6G',

  // Misc
  LOG_IN_URL: 'https://macrost.ax/app',
  STAXCHAT_DEEP_LINK: 'https://www.macrostax.com/?staxchat=true',
};
