import { VALIDATE_COUPON_FAILURE } from '../apiActions/signupApiActions';

export const SIGN_UP = 'SIGN_UP';
export const SIGN_UP_INPUTS = 'SIGN_UP_INPUTS';
export const SIGN_UP_GOAL = 'SIGN_UP_GOAL';
export const SIGN_UP_DIET = 'SIGN_UP_DIET';
export const SIGN_UP_WORKOUTS = 'SIGN_UP_WORKOUTS';
export const SIGN_UP_LIFESTYLE = 'SIGN_UP_LIFESTYLE';
export const SIGN_UP_ACTIVITY_LEVEL = 'SIGN_UP_ACTIVITY_LEVEL';
export const SIGN_UP_PLAN = 'SIGN_UP_PLAN';
export const SIGN_UP_VALUE = 'SIGN_UP_VALUE';
export const SIGN_UP_MEMBERSHIP = 'SIGN_UP_MEMBERSHIP';
export const SIGN_UP_BILLING = 'SIGN_UP_BILLING';
export const SIGN_UP_TEAM = 'SIGN_UP_TEAM';
export const SIGN_UP_SMS = 'SIGN_UP_SMS';
export const SET_SIGN_UP_STEP = 'SET_SIGN_UP_STEP';
export const CLEAR_USER_PROFILE_STATE = 'CLEAR_USER_PROFILE_STATE';
export const CLEAR_TESTIMONIAL = 'CLEAR_TESTIMONIAL';
export const CLEAR_ACTIVITY_LEVEL = 'CLEAR_ACTIVITY_LEVEL';
export const VALIDATE_COUPON = 'VALIDATE_COUPON';
export const CLEAR_COUPON = 'CLEAR_COUPON';
export const INVALID_COUPON = 'INVALID_COUPON';
export const TRACK_FACEBOOK_EVENT = 'TRACK_FACEBOOK_EVENT';

export function trackFacebookEvent(eventName, path) {
  return {
    type: TRACK_FACEBOOK_EVENT,
    eventName,
    path,
  };
}

export function clearUserProfileState() {
  return {
    type: CLEAR_USER_PROFILE_STATE,
  };
}

export function clearTestimonial() {
  return {
    type: CLEAR_TESTIMONIAL,
  };
}

export function clearActivityLevel() {
  return {
    type: CLEAR_ACTIVITY_LEVEL,
  };
}

export function signUp(values) {
  return {
    type: SIGN_UP,
    values,
  };
}

export function signUpInputs(values) {
  return {
    type: SIGN_UP_INPUTS,
    values,
  };
}

export function signUpGoal(values) {
  return {
    type: SIGN_UP_GOAL,
    values,
  };
}

export function signUpDiet(values) {
  return {
    type: SIGN_UP_DIET,
    values,
  };
}

export function signUpWorkouts(hours, email) {
  return {
    type: SIGN_UP_WORKOUTS,
    hours,
    email,
  };
}

export function signUpLifestyle(values) {
  return {
    type: SIGN_UP_LIFESTYLE,
    values,
  };
}

export function signUpActivityLevel(activityLevelId) {
  return {
    type: SIGN_UP_ACTIVITY_LEVEL,
    activityLevelId,
  };
}

export function signUpPlan(planId) {
  return {
    type: SIGN_UP_PLAN,
    planId,
  };
}

export function signUpValue(skip) {
  return {
    type: SIGN_UP_VALUE,
    skip,
  };
}

export function signUpMembership(membership) {
  return {
    type: SIGN_UP_MEMBERSHIP,
    membership,
  };
}

export function signUpBilling(values, coupon) {
  return {
    type: SIGN_UP_BILLING,
    values,
    coupon,
  };
}

export function signUpSms(values) {
  return {
    type: SIGN_UP_SMS,
    values,
  };
}

export function signUpTeam() {
  return {
    type: SIGN_UP_TEAM,
  };
}

export function setSignUpStep(step) {
  return {
    type: SET_SIGN_UP_STEP,
    step,
  };
}

export function validateCoupon(membership, coupon) {
  return {
    type: VALIDATE_COUPON,
    membership,
    coupon,
  };
}

export function clearCoupon() {
  return {
    type: CLEAR_COUPON,
  };
}

export function invalidCoupon(membershipOptionId, paymentMethod) {
  return {
    type: INVALID_COUPON,
    membershipOptionId,
    paymentMethod
  };
}
