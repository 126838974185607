import { take, all, put, select, race, call, delay } from 'redux-saga/effects';
import moment from 'moment-timezone';
import swal from 'sweetalert';
import {
  SIGN_UP,
  SIGN_UP_INPUTS,
  SIGN_UP_GOAL,
  SIGN_UP_DIET,
  SIGN_UP_WORKOUTS,
  SIGN_UP_LIFESTYLE,
  SIGN_UP_ACTIVITY_LEVEL,
  SIGN_UP_PLAN,
  SIGN_UP_VALUE,
  SIGN_UP_MEMBERSHIP,
  SIGN_UP_TEAM,
  SIGN_UP_BILLING,
  SIGN_UP_SMS,
  VALIDATE_COUPON,
  INVALID_COUPON,
  TRACK_FACEBOOK_EVENT,
  signUpMembership,
  clearActivityLevel,
  clearTestimonial,
  clearUserProfileState,
  clearCoupon,
  signUpBilling,
  invalidCoupon,
} from '../actions/signupActions';
import {
  authUserIdRetrieved,
  authTokenStored,
  authLoginFailure,
  authLogout,
  AUTH_LOGOUT,
  AUTH_REFRESH_TOKEN,
  AUTH_LOGIN_FAILURE,
} from '../actions/authActions';
import {
  authUserRefresh,
  AUTH_REFRESH_SUCCESS,
  AUTH_REFRESH_FAILURE,
} from '../apiActions/authApiActions';
import {
  createAccount,
  createUserSettings,
  setInitialPhaseForUser,
  activityLevelSuggestion,
  setInitialActivityLevelForUser,
  userProfileState,
  testimonial,
  membershipOptions,
  validateCoupon,
  sendSms,
  submitNewMembershipPayment,
  subscriptionPurchased,
  trackFacebookEventRPC,
  trackFacebookEventGQL,
  CREATE_ACCOUNT_SUCCESS,
  CREATE_ACCOUNT_FAILURE,
  CREATE_USER_SETTINGS_SUCCESS,
  CREATE_USER_SETTINGS_FAILURE,
  SET_INITIAL_PHASE_FOR_USER_SUCCESS,
  SET_INITIAL_PHASE_FOR_USER_FAILURE,
  ACTIVITY_LEVEL_SUGGESTION_SUCCESS,
  ACTIVITY_LEVEL_SUGGESTION_FAILURE,
  SET_INITIAL_ACTIVITY_LEVEL_FOR_USER_SUCCESS,
  SET_INITIAL_ACTIVITY_LEVEL_FOR_USER_FAILURE,
  USER_PROFILE_STATE_SUCCESS,
  USER_PROFILE_STATE_FAILURE,
  VALIDATE_COUPON_SUCCESS,
  VALIDATE_COUPON_FAILURE,
  SUBMIT_NEW_MEMBERSHIP_PAYMENT_SUCCESS,
  SUBMIT_NEW_MEMBERSHIP_PAYMENT_FAILURE,
  SEND_SMS_SUCCESS,
  SEND_SMS_FAILURE,
  SUBSCRIPTION_PURCHASED_SUCCESS,
  SUBSCRIPTION_PURCHASED_FAILURE,
} from '../apiActions/signupApiActions';
import {
  setLocalStoreValue,
  getLocalStoreValue,
  removeLocalStoreValues,
  JWT_TOKEN_KEY,
  JWT_REFRESH_KEY,
} from '../utils/localStorage';
import { showAlert } from '../actions/globalActions';
import browserHistory from '../history';
import { formatPhone } from '../utils/stringUtils';
import { calculateInches } from '../utils/numberUtils';
import { getTokenData, getTimeUntilTokenExpired, getTokenFromResponse } from '../utils/token';
import { buildErrorMessage } from '../utils/apiCaller';
import { reverseDieting } from '../enums/goals';
import trackers from '../trackers';
import ENV_VARS from '../utils/envVars';

export default function* authSagas() {
  yield all([
    signUpSaga(),
    signUpInputsSaga(),
    signUpGoalSaga(),
    signUpDietSaga(),
    signUpWorkoutsSaga(),
    signUpLifestyleSaga(),
    signUpActivityLevelSaga(),
    signUpPlanSaga(),
    signUpValueSaga(),
    signUpMembershipSaga(),
    signUpCouponSaga(),
    signUpBillingSaga(),
    signUpTeamSaga(),
    invalidCouponSaga(),
    signUpSmsSaga(),
    authFailureSaga(),
    authLogoutSaga(),
    refreshTokenSaga(),
    trackFacebookEventRPCSaga(),
  ]);
}

function* trackFacebookEventRPCSaga() {
  while (true) {
    const { eventName, path } = yield take(TRACK_FACEBOOK_EVENT);
    yield put(trackFacebookEventRPC(eventName, path));
  }
}

function* signUpSaga() {
  while (true) {
    const { values } = yield take(SIGN_UP);
    values.timezone = moment.tz.guess();
    if (values.timezone.toLowerCase() === 'america/indianapolis') {
      values.timezone = 'America/Indiana/Indianapolis';
    }
    values.email = values.email.toLowerCase();
    yield put(createAccount(values));
    const { success, failure } = yield race({
      success: take(CREATE_ACCOUNT_SUCCESS),
      failure: take(CREATE_ACCOUNT_FAILURE),
    });

    if (success) {
      const { token, refresh } = getTokenFromResponse(success.data);

      // get the sub (user_id) out of the token
      const decoded = yield call(getTokenData, token);
      let user_id;
      if (decoded && decoded.sub) {
        user_id = decoded.sub;
        yield put(authUserIdRetrieved(user_id));
        yield put(authTokenStored(token));
        values.is_team_user = decoded.is_team_user;
        values.team_name = decoded.team_name;
        values.user_id = user_id;
      }

      //  Set the two tokens in local storage
      yield call(setLocalStoreValue, JWT_TOKEN_KEY, token);
      yield call(setLocalStoreValue, JWT_REFRESH_KEY, refresh);

      // prefetch some data for later
      yield put(membershipOptions());

      trackers.updateTracking('inputs', values);
      browserHistory.push('/signup/inputs');

      // const { logOutAction } = yield race({
      //   logOutAction: take(AUTH_LOGOUT),
      //   refreshLoop: call(refreshTokenSaga),
      // });

      // // If logged out - kill the tokens in local store
      // // TODO: clear GQL cache
      // if (logOutAction) {
      //   tokenForRefresh = null;
      //   yield call(removeLocalStoreValues);
      // }
    } else {
      if (
        failure.error &&
        failure.error.response &&
        failure.error.response.data &&
        failure.error.response.data.error &&
        (failure.error.response.data.error === 'existing_user' ||
          failure.error.response.data.error === 'existing_account' ||
          failure.error.response.data.error === 'unauthorized')
      ) {
        browserHistory.push('/signup/app');
      } else {
        yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
      }
    }
  }
}

function* signUpInputsSaga() {
  while (true) {
    const { values } = yield take(SIGN_UP_INPUTS);
    const { heightCm, heightFt, gender, birthdate, weight, goal_weight, unitsOfMeasure } = values;
    let { heightIn } = values;
    if (!heightIn || !heightIn.length) {
      heightIn = '0';
    }
    const height = unitsOfMeasure === 'imperial' ? calculateInches(heightFt, heightIn) : heightCm;
    const variables = {
      userSettings: {
        height: height.toString(),
        gender,
        birthdate: moment(birthdate).format('YYYY-MM-DD'),
        currentWeight: weight.toString(),
        goalWeight: goal_weight.toString(),
        preferredMeasurementUnit: unitsOfMeasure,
      },
    };

    yield put(createUserSettings(variables));
    const { success, failure } = yield race({
      success: take(CREATE_USER_SETTINGS_SUCCESS),
      failure: take(CREATE_USER_SETTINGS_FAILURE),
    });

    if (success) {
      trackers.updateTracking('goal', values);
      browserHistory.push('/signup/goal');
    } else {
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* signUpGoalSaga() {
  while (true) {
    const { values } = yield take(SIGN_UP_GOAL);
    const { goal } = values;
    const variables = {
      phaseUuid: goal,
    };

    yield put(setInitialPhaseForUser(variables));
    const { success, failure } = yield race({
      success: take(SET_INITIAL_PHASE_FOR_USER_SUCCESS),
      failure: take(SET_INITIAL_PHASE_FOR_USER_FAILURE),
    });

    if (success) {
      trackers.updateTracking('diet', values);
      browserHistory.push('/signup/diet');
    } else {
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* signUpDietSaga() {
  while (true) {
    const { values } = yield take(SIGN_UP_DIET);
    const { diet, goal } = values;
    // while this is a boolean question, the values are coming through as strings.
    // I don't remember exactly why, but something to do with rendering custom radio buttons.
    // We want to call this mutation even if they answer "no" because they may have previously
    // answered "yes" and then backtracked to change their mind
    const variables = {
      phaseUuid: diet === 'true' ? reverseDieting : goal,
    };

    yield put(setInitialPhaseForUser(variables));
    const { success, failure } = yield race({
      success: take(SET_INITIAL_PHASE_FOR_USER_SUCCESS),
      failure: take(SET_INITIAL_PHASE_FOR_USER_FAILURE),
    });

    if (success) {
      trackers.updateTracking('workouts', values);
      browserHistory.push('/signup/workouts');
    } else {
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* signUpWorkoutsSaga() {
  while (true) {
    const { hours, email } = yield take(SIGN_UP_WORKOUTS);
    let state = yield select();
    yield put(
      trackFacebookEventGQL({
        eventName: 'PageView',
        params: {
          path: '/signup/workouts',
          first_name: state.signup.first_name,
          last_name: state.signup.last_name,
          email: state.signup.email,
          gender: state.signup.gender,
          birthdate: moment(state.signup.birthdate).format('YYYY-MM-DD')
        },
      })
    );
    yield put(
      trackFacebookEventGQL({
        eventName: 'CustomizeProduct',
        params: {
          path: '/signup/workouts',
          first_name: state.signup.first_name,
          last_name: state.signup.last_name,
          email: state.signup.email,
          gender: state.signup.gender,
          birthdate: moment(state.signup.birthdate).format('YYYY-MM-DD')
        },
      })
    );
    state = yield select();
    trackers.updateTracking('lifestyle', state.signup);
    browserHistory.push('/signup/lifestyle');
  }
}

function* signUpLifestyleSaga() {
  while (true) {
    const { values } = yield take(SIGN_UP_LIFESTYLE);
    const { lifestyle, hours } = values;
    const variables = {
      quizAnswer: lifestyle,
      workoutHours: hours.toString(),
    };
    yield put(clearActivityLevel());
    yield put(activityLevelSuggestion(variables));
    const { success, failure } = yield race({
      success: take(ACTIVITY_LEVEL_SUGGESTION_SUCCESS),
      failure: take(ACTIVITY_LEVEL_SUGGESTION_FAILURE),
    });

    if (success) {
      const state = yield select();
      yield put(
        trackFacebookEventGQL({
          eventName: 'PageView',
          params: {
            path: '/signup/activitylevel',
            first_name: state.signup.first_name,
            last_name: state.signup.last_name,
            email: state.signup.email,
            gender: state.signup.gender,
            birthdate: moment(state.signup.birthdate).format('YYYY-MM-DD')
          },
        })
      );
      trackers.updateTracking('activitylevel', state.signup);
      browserHistory.push('/signup/activitylevel');
    } else {
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* signUpActivityLevelSaga() {
  while (true) {
    const { activityLevelId } = yield take(SIGN_UP_ACTIVITY_LEVEL);
    const variables = {
      activityLevelUuid: activityLevelId,
    };
    yield put(setInitialActivityLevelForUser(variables));
    const { success, failure } = yield race({
      success: take(SET_INITIAL_ACTIVITY_LEVEL_FOR_USER_SUCCESS),
      failure: take(SET_INITIAL_ACTIVITY_LEVEL_FOR_USER_FAILURE),
    });

    if (success) {
      yield put(clearUserProfileState());
      yield put(clearTestimonial());

      const state = yield select();
      const {
        signup: { birthdate, gender, email },
      } = state;
      const variables = {
        birthdate: moment(birthdate).format('YYYY-MM-DD'),
        gender,
      };
      yield put(userProfileState());
      const { success, failure } = yield race({
        success: take(USER_PROFILE_STATE_SUCCESS),
        failure: take(USER_PROFILE_STATE_FAILURE),
      });
      if (success) {
        variables.goalUuid = success.data.userProfileState.goal.uuid;
        yield put(testimonial(variables));
      }
      yield put(
        trackFacebookEventGQL({
          eventName: 'PageView',
          params: {
            path: '/signup/plan',
            first_name: state.signup.first_name,
            last_name: state.signup.last_name,
            email: state.signup.email,
            gender: state.signup.gender,
            birthdate: moment(state.signup.birthdate).format('YYYY-MM-DD')
          },
        })
      );
      trackers.updateTracking('plan', state.signup);
      browserHistory.push('/signup/plan');
    } else {
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* signUpPlanSaga() {
  while (true) {
    const { planId } = yield take(SIGN_UP_PLAN);
    const state = yield select();
    const { signup } = state;
    yield put(
      trackFacebookEventGQL({
        eventName: 'PageView',
        params: {
          path: '/signup/yearly',
          first_name: state.signup.first_name,
          last_name: state.signup.last_name,
          email: state.signup.email,
          gender: state.signup.gender,
          birthdate: moment(state.signup.birthdate).format('YYYY-MM-DD')
        },
      })
    );
    trackers.updateTracking('yearly', signup);
    browserHistory.push('/signup/yearly');
  }
}

function* signUpValueSaga() {
  while (true) {
    const { skip } = yield take(SIGN_UP_VALUE);
    const state = yield select();
    const {
      signup: { membershipOptions, email },
    } = state;
    if (skip) {
      yield put(signUpMembership(membershipOptions.find((s) => s.featured === 'true')));
    } else {
      yield put(
        trackFacebookEventGQL({
          eventName: 'PageView',
          params: {
            path: '/signup/memberships',
            first_name: state.signup.first_name,
            last_name: state.signup.last_name,
            email: state.signup.email,
            gender: state.signup.gender,
            birthdate: moment(state.signup.birthdate).format('YYYY-MM-DD')
          },
        })
      );
      trackers.updateTracking('memberships', state.signup);
      browserHistory.push('/signup/memberships');
    }
  }
}

function* signUpMembershipSaga() {
  while (true) {
    const { membership } = yield take(SIGN_UP_MEMBERSHIP);
    // TODO: we won't want to clear the coupon here if the user came from an embedded coupon link
    yield put(clearCoupon());
    const state = yield select();
    const { signup } = state;
    const params = {
      content_id: signup.membership.id,
      content_name: signup.membership.nameLong,
      value: signup.membership.fullPrice,
      path: '/signup/memberships',
      first_name: state.signup.first_name,
      last_name: state.signup.last_name,
      email: state.signup.email,
      gender: state.signup.gender,
      birthdate: moment(state.signup.birthdate).format('YYYY-MM-DD')
    };
    yield put(trackFacebookEventGQL({ eventName: 'AddToCart', params }));
    yield put(
      trackFacebookEventGQL({
        eventName: 'PageView',
        params: {
          path: '/signup/memberships',
          first_name: state.signup.first_name,
          last_name: state.signup.last_name,
          email: state.signup.email,
          gender: state.signup.gender,
          birthdate: moment(state.signup.birthdate).format('YYYY-MM-DD')
        },
      })
    );
    trackers.updateTracking('billing', signup);
    browserHistory.push('/signup/billing');
  }
}

function* signUpCouponSaga() {
  while (true) {
    const { membership, coupon } = yield take(VALIDATE_COUPON);
    const variables = {
      enteredCoupon: coupon.toUpperCase().trim(),
      membershipId: membership.id,
    };
    yield put(validateCoupon(variables));
    const { success, failure } = yield race({
      success: take(VALIDATE_COUPON_SUCCESS),
      failure: take(VALIDATE_COUPON_FAILURE),
    });

    if (success) {
      const validatedCoupon = success.data.validateCoupon;
      if (!validatedCoupon.valid) {
        yield put(
          showAlert('Oops!', 'Sorry, that is not a valid coupon. Please try again.', 'error')
        );
      }
    } else {
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* signUpBillingSaga() {
  while (true) {
    const { values } = yield take(SIGN_UP_BILLING);
    const { membershipOptionId, paymentMethod, coupon } = values;
    const variables = {
      membershipPaymentIn: {
        membershipOptionUuid: membershipOptionId,
        enteredCoupon: coupon ? coupon.toUpperCase().trim() : undefined,
        paymentMethodId: paymentMethod.id,
      },
    };

    yield put(submitNewMembershipPayment(variables));
    const { success, failure } = yield race({
      success: take(SUBMIT_NEW_MEMBERSHIP_PAYMENT_SUCCESS),
      failure: take(SUBMIT_NEW_MEMBERSHIP_PAYMENT_FAILURE),
    });

    if (success) {
      const state = yield select();
      const { signup } = state;
      trackers.updateTracking('complete', signup);
      trackers.checkout(signup);
      yield call(removeLocalStoreValues);
      browserHistory.push('/signup/complete');
    } else {
      if (failure.error && failure.error.message && failure.error.message === 'Invalid coupon') {
        yield put(invalidCoupon(membershipOptionId, paymentMethod));
      } else if (
        failure.error &&
        failure.error.message &&
        failure.error.message === 'active_subscription'
      ) {
        const d = document.createElement('div');
        d.innerHTML = `It looks like you already have an active subscription!<br /><br />Please reach out to us so we can help. You can email us at <a href="mailto:support@macrostax.com">support@macrostax.com</a> or chat directly with our team via the <a href="${ENV_VARS.STAXCHAT_DEEP_LINK}" target="_blank">StaxChat messenger</a> on our website.`;
        yield put(showAlert('Oops!', '', 'error', undefined, undefined, true, d));
      } else {
        yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
      }
    }
  }
}

function* signUpTeamSaga() {
  while (true) {
    yield take(SIGN_UP_TEAM);

    const variables = {
      transactionId: '',
    };

    yield put(subscriptionPurchased(variables));
    const { success, failure } = yield race({
      success: take(SUBSCRIPTION_PURCHASED_SUCCESS),
      failure: take(SUBSCRIPTION_PURCHASED_FAILURE),
    });

    if (success) {
      const state = yield select();
      const { signup } = state;
      trackers.updateTracking('complete', signup);
      yield call(removeLocalStoreValues);
      browserHistory.push('/signup/complete');
    } else {
      if (failure.error && failure.error.message && failure.error.message === 'Invalid coupon') {
        yield put(invalidCoupon(membershipOptionId, paymentMethod));
      } else if (
        failure.error &&
        failure.error.message &&
        failure.error.message === 'active_subscription'
      ) {
        const d = document.createElement('div');
        d.innerHTML = `It looks like you already have an active subscription!<br /><br />Please reach out to us so we can help. You can email us at <a href="mailto:support@macrostax.com">support@macrostax.com</a> or chat directly with our team via the <a href="${ENV_VARS.STAXCHAT_DEEP_LINK}" target="_blank">StaxChat messenger</a> on our website.`;
        yield put(showAlert('Oops!', '', 'error', undefined, undefined, true, d));
      } else {
        yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
      }
    }
  }
}

function* invalidCouponSaga() {
  while (true) {
    const { membershipOptionId, paymentMethod } = yield take('INVALID_COUPON');
    const value = yield call(
      displayMultiAlert,
      'Oops',
      'The coupon you entered was invalid. Press Cancel to try another coupon code or to remove this one. Press Pay Anyway to proceed with your purchase.',
      'error',
      {
        cancel: 'Cancel',
        catch: {
          text: 'Pay Anyway',
          value: 'pay',
        },
      }
    );
    if (value === 'pay') {
      yield put(signUpBilling({ membershipOptionId, paymentMethod, coupon: undefined }));
    }
  }
}

function displayMultiAlert(title, text, icon = 'error', buttons) {
  return swal({
    title,
    text,
    icon,
    buttons,
  }).then((value) => value);
}

function* signUpSmsSaga() {
  while (true) {
    const { values } = yield take(SIGN_UP_SMS);
    const { phone } = values;
    yield put(sendSms(phone));
    const { success, failure } = yield race({
      success: take(SEND_SMS_SUCCESS),
      failure: take(SEND_SMS_FAILURE),
    });

    if (success) {
      yield put(showAlert('Done!', `A link has been sent to ${formatPhone(phone)}`, 'success'));
    } else {
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

/****
 *
 *
 * BELOW ARE HELPER FUNCTIONS/SAGAS FOR TOKEN MANAGEMENT
 *
 *
 * ****/

function* refreshTokenSaga() {
  // When entered first time, get time left until existing token expires
  const jwtToken = yield call(getLocalStoreValue, JWT_TOKEN_KEY);
  const timeLeft = getTimeUntilTokenExpired(jwtToken);
  if (timeLeft > 60) {
    // if token is more than a minute til expired
    yield put(authTokenStored(jwtToken));
    // set timer to wake up at token expire
    yield race({
      delayed: delay(timeLeft),
      forced: take(AUTH_REFRESH_TOKEN),
    });
  }

  while (true) {
    yield call(fetchNewTokenSaga);
    const newToken = yield call(getLocalStoreValue, JWT_TOKEN_KEY);
    // get time to wake up at token expire
    let delayTime = 60;
    if (newToken) {
      delayTime = getTimeUntilTokenExpired(newToken);
      yield put(authTokenStored(newToken));
    }

    // take first action - timer fires, or manual refresh cmd
    yield race({
      delay: delay(delayTime),
      manual: take(AUTH_REFRESH_TOKEN),
    });
  }
}

function* fetchNewTokenSaga() {
  // retrieve refresh token - exit if absent
  const tokenRefresh = yield call(getLocalStoreValue, JWT_REFRESH_KEY);
  if (!tokenRefresh) {
    return;
  }

  // make refresh API call get new token - exit if error
  const newToken = yield call(authorizeRefreshLoginSaga, tokenRefresh);
  if (newToken == null) {
    console.log('IN FETCH NEW TOKEN ABOUT TO CLEAR');
    // we couldn't refresh. So clear the tokens.
    yield call(removeLocalStoreValues);
    return;
  }

  // put new token in local storage
  yield call(setLocalStoreValue, JWT_TOKEN_KEY, newToken);
}

function* authorizeRefreshLoginSaga(tokenRefresh) {
  try {
    // api call to refresh token
    yield put(authUserRefresh(tokenRefresh));
    const { success, failure } = yield race({
      success: take(AUTH_REFRESH_SUCCESS),
      failure: take(AUTH_REFRESH_FAILURE),
    });
    if (success) {
      const { data } = success;
      if (data.resetPassword) {
        console.log('NEED TO RESET PASSWORD');
        // yield put(passwordUpdateRequired());
      }
      // store token if returned
      const { token, refresh } = getTokenFromResponse(data);

      //  Set the two tokens in local storage
      yield call(setLocalStoreValue, JWT_TOKEN_KEY, token);
      yield call(setLocalStoreValue, JWT_REFRESH_KEY, refresh);
      return token;
    }
    yield put(authLoginFailure(failure, true));
  } catch (e) {
    yield put(authLoginFailure(e));
  }
  return null;
}

function* authFailureSaga() {
  while (true) {
    const { error, fromRefresh } = yield take(AUTH_LOGIN_FAILURE);
    if (!fromRefresh) {
      yield put(showAlert('Oops!', buildErrorMessage(error), 'error'));
    } else {
      yield put(authLogout());
    }
  }
}

function* authLogoutSaga() {
  while (true) {
    const { location } = browserHistory;
    const { redirect = true } = yield take(AUTH_LOGOUT);
    // yield call(removeLocalStoreValues);
    if (redirect) {
      browserHistory.push('/signup');
    }
  }
}
