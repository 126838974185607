import ENV_VARS from '../utils/envVars';

const predictedLTV = {
  Yearly: 192.46,
  Monthly: 104.07,
};

const init = (signup) => {
  let device;
  let userAgent = '';
  if (signup) {
    ({ device } = signup);
    if (device) {
      ({ userAgent } = device);
    }
  }
  
  !(function (f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
  window.fbq = fbq;
  window.fbq('init', `${ENV_VARS.FACEBOOK_APP_ID}`, { client_user_agent: userAgent });
  window.fbq('track', 'PageView', {}, { client_user_agent: userAgent });
};

const pageView = (signup) => {
  const { user_id, device } = signup;
  const { userAgent } = device;
  if (user_id) {
    window.fbq('track', 'PageView', {}, { eventID: user_id, event_id: user_id, client_user_agent: userAgent });
  } else {
    window.fbq('track', 'PageView', {}, { client_user_agent: userAgent });
  }
};

const lead = (signup) => {
  const { user_id, device } = signup;
  const { userAgent } = device;
  window.fbq('track', 'Lead', {}, { eventID: user_id, client_user_agent: userAgent });
};

const customizeProduct = (signup) => {
  const { user_id, device } = signup;
  const { userAgent } = device;
  window.fbq('track', 'CustomizeProduct', {}, { eventID: user_id, client_user_agent: userAgent });
};

const viewContent = (signup) => {
  const { device } = signup;
  const { userAgent } = device;
  window.fbq('track', 'ViewContent', {}, { client_user_agent: userAgent });
};

const addToCart = (signup) => {
  const { user_id, membership, device } = signup;
  const { userAgent } = device;
  const { id, nameLong, pricePerMonth, fullPrice } = membership; // membershipOption.id
  const params = {
    content_ids: [id],
    content_name: nameLong,
    content_type: 'product',
    contents: [{ id: id, quantity: 1, item_price: parseFloat(fullPrice) }],
    currency: 'USD',
    value: parseFloat(fullPrice),
    // value: parseFloat(pricePerMonth) * 12,
  };
  window.fbq('track', 'AddToCart', params, { eventID: user_id, client_user_agent: userAgent });
};

const checkout = (signup) => {
  const { orderConfirmation, user_id } = signup;
  const { newTotal, membershipOption, coupon, membershipId } = orderConfirmation;
  const { id, nameLong, fullPrice, nameShort } = membershipOption;
  let price = newTotal || '0.00';
  price = parseFloat(price);

  const params = {
    content_ids: [id],
    content_name: nameLong,
    content_type: 'product',
    contents: [{ id: id, quantity: 1, item_price: parseFloat(price) }],
    currency: 'USD',
    num_items: '1',
    value: price,
    coupon,
  };
  window.fbq('track', 'Purchase', params, { eventID: membershipId });

  const subscribeParams = {
    value: price,
    currency: "USD",
    predicted_ltv: predictedLTV[nameShort]
  }
  window.fbq('track', 'Subscribe', subscribeParams, { eventID: membershipId });
};

export default {
  init,
  pageView,
  lead,
  customizeProduct,
  viewContent,
  addToCart,
  checkout,
};
