import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'loaders.css/loaders.css';

class Loading extends Component {
  render() {
    const { showText = true } = this.props;
    return (
      <div className="flex-row">
        {showText ? <div className="margin-right-10">Loading</div> : null}

        <div className="loader-demo d-flex align-items-center justify-content-center">
          <div className="ball-pulse">
            <div />
            <div />
            <div />
          </div>
        </div>
      </div>
    );
  }
}

Loading.propTypes = {
  showText: PropTypes.bool,
};

export default Loading;
