import Qs from 'qs';

import ENV_VARS from '../utils/envVars';

export const GET_TEAM_SUCCESS = 'GET_TEAM_SUCCESS';
export const GET_TEAM_FAILURE = 'GET_TEAM_FAILURE';
export const UPDATE_TEAM_SUCCESS = 'UPDATE_TEAM_SUCCESS';
export const UPDATE_TEAM_FAILURE = 'UPDATE_TEAM_FAILURE';
export const TEAM_PROFILE_SUCCESS = 'TEAM_PROFILE_SUCCESS';
export const TEAM_PROFILE_FAILURE = 'TEAM_PROFILE_FAILURE';
export const TEAM_SEARCH_SUCCESS = 'TEAM_SEARCH_SUCCESS';
export const TEAM_SEARCH_FAILURE = 'TEAM_SEARCH_FAILURE';
export const ADD_CLIENTS_SUCCESS = 'ADD_CLIENTS_SUCCESS';
export const ADD_CLIENTS_FAILURE = 'ADD_CLIENTS_FAILURE';
export const SEND_INVITATIONS_SUCCESS = 'SEND_INVITATIONS_SUCCESS';
export const SEND_INVITATIONS_FAILURE = 'SEND_INVITATIONS_FAILURE';
export const REMOVE_MEMBERSHIPS_SUCCESS = 'REMOVE_MEMBERSHIPS_SUCCESS';
export const REMOVE_MEMBERSHIPS_FAILURE = 'REMOVE_MEMBERSHIPS_FAILURE';

export function getTeamApi(team_id) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/team',
    method: 'GET',
    params: {
      team_id,
    },
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: GET_TEAM_SUCCESS,
    failureAction: GET_TEAM_FAILURE,
  };
}

export function updateTeamApi(team) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/edit-team',
    method: 'PUT',
    data: team,
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: UPDATE_TEAM_SUCCESS,
    failureAction: UPDATE_TEAM_FAILURE,
  };
}

export function getTeamProfile() {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/team-profile',
    method: 'GET',
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: TEAM_PROFILE_SUCCESS,
    failureAction: TEAM_PROFILE_FAILURE,
  };
}

export function teams() {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/team-search',
    method: 'GET',
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: TEAM_SEARCH_SUCCESS,
    failureAction: TEAM_SEARCH_FAILURE,
  };
}

export function teamSearch(values) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/team-search',
    method: 'GET',
    params: values,
    paramsSerializer: function (params) {
      return Qs.stringify(params, { arrayFormat: 'comma', encode: false });
    },
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: TEAM_SEARCH_SUCCESS,
    failureAction: TEAM_SEARCH_FAILURE,
  };
}

export function addClientsApi(payload) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/add-clients',
    method: 'POST',
    data: payload,
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: ADD_CLIENTS_SUCCESS,
    failureAction: ADD_CLIENTS_FAILURE,
  };
}

export function sendInvitationsApi(payload) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/invite-clients',
    method: 'POST',
    data: payload,
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: SEND_INVITATIONS_SUCCESS,
    failureAction: SEND_INVITATIONS_FAILURE,
  };
}

export function removeMembershipsApi(payload) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/revoke-clients',
    method: 'DELETE',
    data: payload,
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: REMOVE_MEMBERSHIPS_SUCCESS,
    failureAction: REMOVE_MEMBERSHIPS_FAILURE,
  };
}
