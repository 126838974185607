import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Row, Col, Button } from 'reactstrap';
import renderRadioButtonsForField from './helpers/renderRadioButtonsForField';
import { goals } from '../enums/goals';

const validate = (values) => {
  const errors = {};
  return errors;
};

class SignUpGoalForm extends Component {
  render() {
    const { initialValues, onSubmit, showSubmissionError } = this.props;
    
    return (
      <div>
        <Form
          onSubmit={(values) => {
            const errors = validate(values);
            if (Object.keys(errors).length === 0) {
              onSubmit(values);
            } else {
              showSubmissionError(errors);
            }
          }}
          initialValues={initialValues}
          keepDirtyOnReinitialize
          render={({ handleSubmit, form, values }) => (
            <form onSubmit={handleSubmit} className="inline-form">
              <Row>
                <Col className="d-flex">
                  <Field
                    name="goal"
                    id="goal"
                    component={renderRadioButtonsForField}
                    options={goals}
                    optionstype="button"
                    {...{
                      itemProps: {},
                      inputProps: {},
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col className="d-flex">
                  <Button color="primary" size="lg" type="submit" block>
                    Next
                  </Button>
                </Col>
              </Row>
            </form>
          )}
        />
      </div>
    );
  }
}

SignUpGoalForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object),
  showSubmissionError: PropTypes.func,
};

export default SignUpGoalForm;
