import { getTokenData } from '../utils/token';

export const AUTH_TOKEN_STORED = 'AUTH_TOKEN_STORED';
export const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST';
export const AUTH_LOGIN_FAILURE = 'AUTH_LOGIN_FAILURE';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_REFRESH_TOKEN = 'AUTH_REFRESH_TOKEN';
export const AUTH_USER_ID_RETRIEVED = 'AUTH_USER_ID_RETRIEVED';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const SEND_RESET_PASSWORD = 'SEND_RESET_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const BEGIN_AUTHENTICATION = 'BEGIN_AUTHENTICATION';
export const USER_IS_LOGGED_IN = 'USER_IS_LOGGED_IN';

export function authTokenStored(token) {
  const decoded = getTokenData(token);
  return {
    type: AUTH_TOKEN_STORED,
    username: decoded.username || 'Guest',
    userId: decoded.sub,
    team_id: decoded.team_id,
    is_team_user: decoded.is_team_user,
    team_name: decoded.team_name,
  };
}

export function authLoginRequest(values) {
  return {
    type: AUTH_LOGIN_REQUEST,
    values,
  };
}

export function authLoginFailure(err, fromRefresh) {
  return {
    type: AUTH_LOGIN_FAILURE,
    error: err,
    fromRefresh,
  };
}

export function authUserIdRetrieved(user_id, role, team_id) {
  return {
    type: AUTH_USER_ID_RETRIEVED,
    user_id,
    role,
    team_id,
  };
}

export function authLoginSuccess(token) {
  const decoded = getTokenData(token);
  return {
    type: AUTH_LOGIN_SUCCESS,
    user_id: decoded.sub,
    exp: decoded.exp,
  };
}

export function authLogout(redirect) {
  return {
    type: AUTH_LOGOUT,
    redirect,
  };
}

export function authRefreshToken() {
  return {
    type: AUTH_REFRESH_TOKEN,
  };
}

export function changePassword(values) {
  return {
    type: CHANGE_PASSWORD,
    values,
  };
}

export function resetPassword(id, values) {
  return {
    type: RESET_PASSWORD,
    id,
    values,
  };
}

export function sendResetPassword(values) {
  return {
    type: SEND_RESET_PASSWORD,
    values,
  };
}

export function beginAuthentication(username) {
  return {
    type: BEGIN_AUTHENTICATION,
    username,
  };
}

export function userIsLoggedIn() {
  return {
    type: USER_IS_LOGGED_IN,
  };
}
