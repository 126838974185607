import { all } from 'redux-saga/effects';
import apiCallerSagas from './apiCallerSaga';
import gqlSagas from './gqlSaga';
// import authSagas from './authSaga';
import signUpSagas from './signUpSaga';
import initSagas from './initSaga';
import layoutSagas from './layoutSaga';
import globalSagas from './globalSaga';
import userSagas from './userSaga';

export default function* rootSaga() {
  yield all([
    apiCallerSagas(),
    gqlSagas(),
    initSagas(),
    // authSagas(),
    signUpSagas(),
    layoutSagas(),
    globalSagas(),
    userSagas(),
  ]);
}
