import moment from 'moment-timezone';

import {
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT,
  AUTH_TOKEN_STORED,
  AUTH_USER_ID_RETRIEVED,
} from '../actions/authActions';
import { UPDATE_TARGETS, RESET_TARGETS, GET_USER_PROFILE } from '../actions/userActions';
import {
  USER_PROFILE_SUCCESS,
  UPDATE_USER_SUCCESS,
  GET_TARGETS_SUCCESS,
  WEIGH_INS_SUCCESS,
  MEASUREMENTS_SUCCESS,
} from '../apiActions/userApiActions';
import { updateObjectAndSortArray } from '../utils/arrayUtils';

const initialUserState = {};

export default (state = initialUserState, action) => {
  switch (action.type) {
    case AUTH_LOGIN_SUCCESS:
    case AUTH_TOKEN_STORED:
    case AUTH_USER_ID_RETRIEVED:
      return {
        ...state,
        user_id: action.user_id,
      };
    case USER_PROFILE_SUCCESS: {
      return { ...state, ...action.data };
    }
    case GET_USER_PROFILE: {
      // we're calling the API to get a new user so clear the one we may have in context
      return null;
    }
    case GET_TARGETS_SUCCESS: {
      return { ...state, targets: action.data };
    }
    case UPDATE_TARGETS: {
      const { id, payload } = action;
      // process payload into user.customTargets object
      const customTargets = [];
      Object.keys(payload).forEach((key) => {
        const keyArr = key.split('_');
        const dayType = keyArr[0];
        const macro = keyArr[1];
        const val = payload[key];
        const dayTypeTarget = customTargets.find((t) => t.dayType === dayType);
        if (!dayTypeTarget) {
          customTargets.push({
            dayType,
            [macro]: val,
          });
        } else {
          dayTypeTarget[macro] = val;
        }
      });
      return {
        ...state,
        customTargets,
        isCustomMacros: true,
      };
    }
    case RESET_TARGETS: {
      const { id } = action;
      return {
        ...state,
        isCustomMacros: false,
      };
    }
    case WEIGH_INS_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        weigh_ins: data,
      };
    }
    case MEASUREMENTS_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        measurements: data,
      };
    }
    case AUTH_LOGOUT: {
      return {
        ...initialUserState,
      };
    }
    default:
      return state;
  }
};
