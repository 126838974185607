import { AsYouType } from 'libphonenumber-js';
import { tzOptions } from '../enums/timezones';

export const formatPhone = (input = '') => {
  if (!input) return '';
  const newVal = input.toString().replace('+1', '');
  if (newVal.includes('(') && !newVal.includes(')')) {
    return newVal.replace('(', '');
  }
  return new AsYouType('US').input(newVal);
};

export const parseBool = (value) => (value === undefined ? false : JSON.parse(value));
export const formatBool = (value) => (value === undefined ? false : JSON.parse(value));

export const capitalizeFirst = (value) =>
  value && value.length ? value.charAt(0).toUpperCase() + value.substring(1, value.length) : value;
export const formatLowercase = (value) => (value && value.length ? value.toLowerCase() : value);
export const formatUppercase = (value) => (value && value.length ? value.toUpperCase() : value);

export const convertBoolToYesNo = (b) => (b === true ? 'Yes' : 'No');

export const upperCaseToSentenceCase = (str) =>
  str && str.length ? str.charAt(0).toUpperCase() + str.substring(1, str.length).toLowerCase() : '';

export const camelCaseToSentenceCase = (str) => {
  if (!str) {
    return '';
  }
  const result = str.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const lowerCaseToCapitalizeEachWord = (str) => {
  if (!str) {
    return '';
  }
  const arrStr = str.split(' ');
  for (let i = 0; i < arrStr.length; i += 1) {
    const str = arrStr[i];
    arrStr[i] = str.charAt(0).toUpperCase() + str.slice(1);
  }
  return arrStr.join(' ');
};

export const enumToSentenceCase = (str) => {
  const ret = [];
  const parts = str.split('_');
  parts.forEach((p) => ret.push(upperCaseToSentenceCase(p)));
  return ret.join(' ');
};

export const formatHeightFromInches = (height_inches) => {
  const remainder = height_inches % 12;
  const feet = Math.floor(height_inches / 12);
  return `${feet}' ${remainder}"`;
};

export const arrayToString = (arr) => arr.join(', ');

export const getLabelForValue = (value, data) => {
  const match = data.find((elem) => elem.value === value);
  if (match) {
    return match.label;
  }
  return '';
};

export const arrayOfLabelsToString = (arr, labels) =>
  arrayToString(arr.map((a) => getLabelForValue(a, labels)));

export const getTimezoneLabel = (tzValue) => {
  const tz = tzOptions.find((t) => t.value === tzValue);
  return tz ? tz.label : 'Unknown';
};

export const formatAddress = (obj) => {
  const { address1, address2, city, state, zip } = obj;
  let add = address1;
  if (address2 && address2.length) {
    add += ' ';
    if (!Number.isNaN(address2.charAt(0))) {
      add += '#';
    }
    add += address2;
  }
  add += '<br />';
  add += `${city}, ${state} ${zip}`;
  return add;
};

export const addLineBreaksForDisplay = (string) =>
  string && string.length
    ? string.split('\n').map((item, key) => (
        <span key={key}>
          {item}
          <br />
        </span>
      ))
    : '';

export const validateEmail = (email) => {
  const validRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return email.match(validRegex);
};
