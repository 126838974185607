import { gql } from '@apollo/client';

export default gql`
  query testimonial($birthdate: String!, $gender: String!, $goalUuid: String!) {
    testimonial(birthdate: $birthdate, gender: $gender, goalUuid: $goalUuid) {
      author
      quote
      photoUrl
    }
  }
`;
