import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, ModalBody, Row, Col } from 'reactstrap';
import { signUpActivityLevel } from '../actions/signupActions';

let animationTimer;
let redirectTimer;
const ANIMATION_DURATION = 3000;

class InterstitialModal extends Component {
  constructor(props) {
    super(props);
    const initialState = {
      submitted: false,
      showFinished: false,
      title: 'Calculating the perfect plan for you...',
    };
    this.state = {
      initialState,
      ...initialState,
    };
  }

  componentWillUnmount() {
    this.resetState();
  }

  updateState = () => {
    const { activityLevelId, doSignUpActivityLevel, toggle } = this.props;
    this.setState({
      finished: true,
      title: 'Your custom macro plan is ready!',
    });
    redirectTimer = setTimeout(() => {
      doSignUpActivityLevel(activityLevelId, toggle);
    }, ANIMATION_DURATION);
  };

  startTimer = () => {
    animationTimer = setTimeout(() => {
      this.updateState();
    }, ANIMATION_DURATION);
  };

  resetState = () => {
    this.clearTimers();
    const { initialState } = this.state;
    this.setState({
      ...initialState,
      initialState,
    });
    document.body.classList.remove('modal-open');
  };

  clearTimers = () => {
    if (animationTimer) {
      clearTimeout(animationTimer);
    }
    if (redirectTimer) {
      clearTimeout(redirectTimer);
    }
  };

  render() {
    const { isOpen, toggle } = this.props;
    const { finished, title } = this.state;
    if (isOpen) {
      this.startTimer();
    }

    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className="modal-small modal-centered interstitial-modal"
        centered
        // onClosed={this.resetState}
        keyboard={false}
        backdrop="static"
      >
        <ModalBody>
          <Row>
            <Col xs={12} className="text-center">
              {title}
              <div className="d-flex align-items-center justify-content-center mt-4">
                <div className={`circle-loader ${finished ? 'load-complete' : ''}`}>
                  <img
                    src="/img/signup/green-thumb.svg"
                    className={`green-thumb ${
                      finished ? 'animate__animated animate__rubberBand' : 'hidden'
                    }`}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

InterstitialModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  activityLevelId: PropTypes.string,
  doSignUpActivityLevel: PropTypes.func,
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  doSignUpActivityLevel: (activityLevelId, toggle) => {
    dispatch(signUpActivityLevel(activityLevelId));
    toggle();
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InterstitialModal);
