import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { helperCaptions } from '../../enums/helpText';
import HelperTextModal from '../../modals/HelperTextModal';

class HelperTextIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      helperTextModalOpen: false,
    };
  }

  componentWillUnmount() {
    this.setState({
      helperTextModalOpen: false,
    });
  }

  toggleHelperTextModal = () => {
    const { helperTextModalOpen } = this.state;
    this.setState({
      helperTextModalOpen: !helperTextModalOpen,
    });
  };

  render() {
    const { helperText } = this.props;
    const { helperTextModalOpen } = this.state;
    
    return (
      <>
        <div
          className="card-tool helper-text float-right"
          tabIndex={-1}
          onClick={(e) => {
            e.preventDefault();
            this.toggleHelperTextModal();
          }}
        >
          <em className="far fa-question-circle" />
        </div>
        <HelperTextModal
          isOpen={helperTextModalOpen}
          toggle={this.toggleHelperTextModal}
          helperText={helperCaptions[helperText]}
        />
      </>
    );
  }
}

HelperTextIcon.propTypes = {
  helpText: PropTypes.string,
  customText: PropTypes.string,
};

export default HelperTextIcon;
