import React from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import SignUpGoalForm from '../../forms/SignUpGoalForm';
import { submissionError } from '../../actions/formActions';
import { signUpGoal, setSignUpStep } from '../../actions/signupActions';
import { oneDecimal } from '../../utils/numberUtils';
import browserHistory from '../../history';
import { goals } from '../../enums/goals';

class SignUpGoal extends React.Component {
  componentDidMount() {
    const { signup, doSetSignupStep } = this.props;
    if (!signup || !signup.first_name) {
      browserHistory.push('/signup');
    } else {
      doSetSignupStep(3);
    }
  }

  render() {
    const { showSubmissionError, doSignUpGoal, signup } = this.props;
    const { first_name, unitsOfMeasure, weight, goal_weight } = signup;
    const displayUnit = unitsOfMeasure === 'imperial' ? 'lb' : 'kg';

    const goal = parseFloat(oneDecimal(weight)) > parseFloat(oneDecimal(goal_weight)) ? 'Lose' : parseFloat(oneDecimal(weight)) < parseFloat(oneDecimal(goal_weight)) ? 'Gain' : 'Maintain';
    const goalId = goals.find((g) => g.title === goal).value;

    const steps = [
      { number: 1, label: 'Lose weight' },
      { number: 2, label: 'Or gain weight' },
      { number: 3, label: 'Or do some body re-composition' },
      { number: 4, label: 'You do you!' },
    ];
    return (
      <ContentWrapper>
        <Row>
          <Col
            md={12}
            className="d-flex flex-column justify-content-center align-items-center mb-5-md text-center"
          >
            <h1>Your Goal</h1>
            <div className="fake-h3">
              <p>Alright, {first_name}, we know you're a goal setter!</p>

              <p>
                You said your current weight is <strong>{oneDecimal(weight)} {displayUnit}</strong> and your goal weight is{' '}
                <strong>{oneDecimal(goal_weight)} {displayUnit}</strong>. Let's talk about where you're headed:
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={3} />
          <Col md={6} className="d-flex flex-column align-items-center mb-3">
            <div className="signup-form-wrapper">
              <SignUpGoalForm
                showSubmissionError={showSubmissionError}
                onSubmit={(values) => doSignUpGoal(values)}
                initialValues={{
                  goal: goalId,
                  ...signup,
                }}
              />
            </div>
          </Col>
          {/* <Col className="d-flex flex-column align-items-center">
            <div className="signup-form-wrapper">
              <div className="icon-headline-container d-flex align-items-center border-bottom pb-4">
                <img src="/img/signup/icon-step-2.svg" className="mr-4" />
                <h2>What does this mean?</h2>
              </div>

              <div className="signup-steps benefits mt-4 pl-3">
                {steps.map((s) => (
                  <div className="signup-step benefit d-flex" key={s.number}>
                    <div className="signup-step-check mr-3">
                      <em className="fas fa-check" />
                    </div>
                    <div className="signup-step-label">{s.label}</div>
                  </div>
                ))}
              </div>
            </div>
          </Col> */}
        </Row>
      </ContentWrapper>
    );
  }
}

SignUpGoal.propTypes = {
  showSubmissionError: PropTypes.func,
  doSignUp: PropTypes.func,
  doLogout: PropTypes.func,
  signup: PropTypes.instanceOf(Object),
  doSetSignupStep: PropTypes.func,
};
const mapStateToProps = (state) => ({
  signup: state.signup,
});
const mapDispatchToProps = (dispatch) => ({
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
  doSignUpGoal: (values) => dispatch(signUpGoal(values)),
  doSetSignupStep: (step) => dispatch(setSignUpStep(step)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpGoal);
