const moment = require('moment-timezone');

export const calculateAge = (birthdate) => {
  return moment().diff(birthdate, 'years', false);
};

export const getTimeUntil = (date, unit) => {
  const time = moment(date).diff(moment(), unit, false);
  if (time < 0) {
    return 0;
  }
  return time;
};

export const localizeDateToTimezone = (date, timezone, asMoment) => {
  const tz = timezone || moment.tz.guess();
  const momentDate = moment(date).format('YYYY-MM-DD HH:mm');
  const newDate = moment.tz(momentDate, tz);
  return asMoment ? newDate : newDate.toDate();
};

export const createChartLabelFromDate = (date) => {
  const dt = localizeDateToTimezone(date);
  return moment(dt).format('M/D/YY');
};

export const formatDateWithTimezone = (date, timezone, format) => {
  if (!date) {
    return '';
  }
  const tz = timezone || moment.tz.guess();
  const mom = moment(date).tz(tz);
  return mom.format(format);
};

export const formatDateToIso8601 = (date) => {
  return moment(date).format('YYYY-MM-DD');
};
