const init = () => {
  EF.click({
    offer_id: EF.urlParameter('oid'),
    affiliate_id: EF.urlParameter('affid'),
    sub1: EF.urlParameter('sub1'),
    sub2: EF.urlParameter('sub2'),
    sub3: EF.urlParameter('sub3'),
    sub4: EF.urlParameter('sub4'),
    sub5: EF.urlParameter('sub5'),
    uid: EF.urlParameter('uid'),
    source_id: EF.urlParameter('source_id'),
    transaction_id: EF.urlParameter('_ef_transaction_id'),
  });
};

const checkout = () => {
  EF.conversion({
    offer_id: 75,
  });
}

export default {
  init,
  checkout,
};
