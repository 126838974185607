import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { Row, Col, Button } from 'reactstrap';
import PhoneField from './fields/PhoneField';

const validate = (values) => {
  const errors = {};
  if (!values.phone) {
    errors.phone = 'Phone is required';
  }
  return errors;
};

class SmsForm extends Component {
  render() {
    const {
      initialValues,
      onSubmit,
      showSubmissionError,
      buttonText = 'Text a Link',
    } = this.props;
    return (
      <div>
        <Form
          onSubmit={(values) => {
            const errors = validate(values);
            if (Object.keys(errors).length === 0) {
              onSubmit(values);
            } else {
              showSubmissionError(errors);
            }
          }}
          initialValues={initialValues}
          keepDirtyOnReinitialize
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} className="inline-form">
              <Row>
                <Col>
                  <PhoneField label="Phone Number" />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col className="d-flex ">
                  <Button color="primary" size="lg" type="submit" block>
                    {buttonText}
                  </Button>
                </Col>
              </Row>
            </form>
          )}
        />
      </div>
    );
  }
}

SmsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object),
  showSubmissionError: PropTypes.func,
  buttonText: PropTypes.string,
};

export default SmsForm;
