import {
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT,
  AUTH_TOKEN_STORED,
  AUTH_LOGIN_REQUEST,
  AUTH_USER_ID_RETRIEVED,
} from '../actions/authActions';
import { AUTH_BASIC_SUCCESS } from '../apiActions/authApiActions';
import { AUTH_USER_PROFILE_SUCCESS } from '../apiActions/userApiActions';
import { MARK_NOTIFICATION_AS_READ, REMOVE_NOTIFICATION } from '../actions/notificationActions';
import { Roles } from '../enums/roles';
import envVars from '../utils/envVars';

const initialAuthState = {
  isLoggedIn: false,
};

export default (state = initialAuthState, action) => {
  switch (action.type) {
    case AUTH_LOGIN_SUCCESS:
    case AUTH_TOKEN_STORED: {
      const { user_id } = action;
      return {
        ...state,
        isLoggedIn: true,
        user_id,
      };
    }
    case AUTH_BASIC_SUCCESS: {
      const { data } = action;
      const { userId, jwtToken, refreshToken } = data;
      return {
        ...state,
        userId,
        jwtToken,
        refreshToken,
      };
    }
    case AUTH_LOGOUT: {
      return {
        ...initialAuthState,
      };
    }
    case AUTH_LOGIN_REQUEST: {
      if (action.username) {
        state.username = action.username;
      } else if (action.payload && action.payload.username) {
        state.username = action.payload.username;
      }
      return {
        ...state,
      };
    }
    case MARK_NOTIFICATION_AS_READ: {
      const { id } = action;
      const { user = {} } = state;
      const { notifications = [] } = user;
      const notification = notifications.find((n) => n.id === id);
      if (notification) {
        notification.read = true;
      }
      return {
        ...state,
        user: {
          ...user,
          notifications,
        },
      };
    }
    case REMOVE_NOTIFICATION: {
      const { id } = action;
      const { user = {} } = state;
      const { notifications = [] } = user;
      const idx = notifications.findIndex((n) => n.id === id);
      if (idx !== -1) {
        notifications.splice(idx, 1);
      }
      return {
        ...state,
        user: {
          ...user,
          notifications,
        },
      };
    }
    case AUTH_USER_PROFILE_SUCCESS: {
      const { user_id, first_name, last_name, profile_photo, email, phone } = action.data;
      return {
        ...state,
        user: {
          ...state.user,
          user_id,
          first_name,
          last_name,
          profile_photo,
          email,
          phone,
        },
      };
    }
    case AUTH_USER_ID_RETRIEVED: {
      const { role, team_id } = action;
      return {
        ...state,
        user: {
          ...state.user,
          roles: [
            (role === 'team_owner' || role === 'team_admin') &&
            team_id === envVars.MACROSTAX_TEAM_ID
              ? Roles.INTERNAL_ADMIN
              : role,
          ],
          team_id,
        },
      };
    }
    default:
      return state;
  }
};
